import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Button, InputGroup, Form, Card, ListGroup, ListGroupItem, Badge, Alert, Spinner } from 'react-bootstrap';
import styles from './style.module.scss';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { format, setDefaultOptions, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import Calendar from 'react-calendar';
import { FaAngleRight, FaCalendarAlt } from 'react-icons/fa';
import { usePaymentReportPartnerData } from '../../api/PartnerAPI/partnerClient';
import { useAuthContext } from '../../contexts/authContext';

setDefaultOptions({ locale: ptBR });

export default function FinancialPage() {
  const { partnerSlug } = useParams();
  const { token } = useAuthContext();
  const navigate = useNavigate();
  
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [year, setYear] = useState(format(new Date(), 'yyyy'));
  const [month, setMonth] = useState(format(new Date(), 'MM'));
  const [isDateManuallySelected, setIsDateManuallySelected] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);

  const { data, isLoading, isError, refetch } = usePaymentReportPartnerData(partnerSlug, token, year, month);
  const [showAlert, setShowAlert] = useState(false);

  const handleMonthClick = (newDate) => {
    setSelectedDate(newDate);
    setYear(format(newDate, 'yyyy'));
    setMonth(format(newDate, 'MM'));
    refetch();
  };

  const formatPrice = (value) => {
    if (!value) return 'R$ 0,00';
    const number = parseFloat(value);
    if (isNaN(number)) return 'R$ 0,00';
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(number);
  };

  useEffect(() => {
    if (data?.reports?.length === 0) {
        setShowAlert(true);
    } else {
        setShowAlert(false);
    }
  }, [data]);

  if (isLoading) {
    return (
      <section id={styles.FinancialPage}>
        <Container>
          <Row>
            <Col className="text-center">
              <Spinner animation="border" role="status">
              </Spinner>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }

  if (isError) {
    return (
      <section id={styles.FinancialPage}>
        <Container>
          <Row>
            <Col>
              <Alert variant="danger">
                Erro ao carregar os dados. Por favor, tente novamente mais tarde.
              </Alert>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }

  return (
    <HelmetProvider>
      <section id={styles.FinancialPage}>
        <Helmet>
          <title>Financeiro - GoinGuru</title>
        </Helmet>
        <Container>
          <Row>
            <Col>
              <p className={styles.title}>Financeiro</p>
            </Col>
            <Col className={styles.colButton}>
              <Button className={styles.buttonHistorico} onClick={() => navigate(`/${partnerSlug}/financeiro/historico`)}>
                Histórico
              </Button>
              <Button className={styles.buttonPost} onClick={() => navigate(`/${partnerSlug}/financeiro/criar`)}>
                Nova Cobrança
              </Button>
            </Col>
          </Row>
          <Row>
            <Col className={styles.colWithCalendar} xs={12} sm={6} md={6} lg={4} xl={4}>
              <InputGroup className={styles.buttonCalendarToggle}>
                  <InputGroup.Text onClick={() => setShowCalendar(!showCalendar)}>
                      <FaCalendarAlt className={styles.icon}/>
                  </InputGroup.Text>
                  <Form.Control
                      type="text"
                      value={selectedDate ? format(selectedDate, 'MMMM / yyyy') : ''}
                      className={styles.buttonDate}
                      disabled
                  />
              </InputGroup>
              {showCalendar && (
                <div className={styles.calendarWrapper}>
                  <Calendar
                    onChange={setSelectedDate}
                    value={selectedDate}
                    view="year"
                    onClickMonth={handleMonthClick}
                    minDetail="year"
                    locale="pt-BR"
                    className={styles.datePicker}
                  />
                </div>
              )}
            </Col>
          </Row>
          <Row className={styles.rowCard}>
            <p className={styles.title}>Faturamento do mês</p>
            <Card className={styles.card}>
              <Row>
                <Col xs={12} sm={4} md={4} lg={4} xl={4}>
                  <p className={styles.text}>Valor Bruto</p>
                  <p className={styles.subText}>{formatPrice(data?.totals?.total_bruto)}</p>
                </Col>
                <Col xs={12} sm={4} md={4} lg={4} xl={4}>
                  <p className={styles.text}>Descontos ou acréscimos</p>
                  <p className={styles.subText}>{formatPrice(data?.totals?.total_taxas_e_descontos)}</p>
                </Col>
                <Col xs={12} sm={4} md={4} lg={4} xl={4}>
                  <p className={styles.text}>Valor liquido total</p>
                  <p className={styles.subText}>{formatPrice(data?.totals?.value_to_repass)}</p>
                </Col>
              </Row>
            </Card>
          </Row>

          <Row className={styles.rowRepasses}>
            <p className={styles.title}>Repasses do mês</p>
            <Row className={styles.rowDescription}>
              <Col lg={3} md={3} sm={3} className={styles.text}>
                Previsão de pagamento
              </Col>
              <Col lg={3} md={3} sm={3} className={styles.subText}>
                Período de apuração
              </Col>
              <Col lg={2} md={2} sm={2} className={styles.subText}>
                Situação
              </Col>
              <Col lg={3} md={3} sm={3} className={styles.subText}>
                Valor
              </Col>
              <Col lg={1} md={1} sm={1}>
                </Col>
            </Row>
            {showAlert ? (
                <Alert variant="warning">
                    Nenhum relatório encontrado para o período informado!.
                </Alert>
            ) : (
              <ListGroup className={styles.listGroup}>
                {data?.reports?.map((item) => (
                  <ListGroupItem key={item.id} className={styles.listGroupItem}>
                    <Row className={styles.rowItem}>
                      <Col lg={3} md={3} sm={3} className={styles.textTitle}>
                        {format(parseISO(item.expected_payment_date), 'dd/MM/yyyy')}
                      </Col>
                      <Col lg={3} md={3} sm={3} className={styles.subText}>
                        {format(parseISO(item.report_start_date), 'dd/MM/yyyy')} - {format(parseISO(item.report_end_date), 'dd/MM/yyyy')}
                      </Col>
                      <Col lg={2} md={2} sm={2} className={styles.colBadge}>
                        <Badge
                          bg={
                            item.status === "paid" ? "success" :
                            item.status === "pending" ? "warning" :
                            item.status === "failed" ? "danger" :
                            item.status === "refunded" ? "info" :
                            "secondary"
                          }
                          className={styles.badge}
                        >
                          {item.status === "paid" ? "Pago" :
                            item.status === "pending" ? "Em Andamento" :
                            item.status === "failed" ? "Falhou" :
                            item.status === "refunded" ? "Reembolsado" :
                            "Desconhecido"
                          }
                        </Badge>
                      </Col>
                      <Col lg={3} md={3} sm={3} className={styles.subText}>
                        {formatPrice(item.value_to_repass)}
                      </Col>
                      <Col xs={12} sm={1} md={1} lg={1} xl={1} className={styles.colIcon}>
                        <FaAngleRight
                          className={styles.icon}
                          onClick={() => navigate(`/${partnerSlug}/financeiro/detalhesPagamento/${item.orderPayment}`)}
                        />
                      </Col>
                    </Row>
                  </ListGroupItem>
                ))}
              </ListGroup>
            )}
          </Row>
        </Container>
      </section>
    </HelmetProvider>
  );
}

import React from 'react';
import { Container, Row, Col, Button, Card, Image, Carousel, CarouselItem } from 'react-bootstrap';
import styles from "./style.module.scss"
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Banner1 from "../../assets/banner1.png";
import Banner2 from "../../assets/banner2.png";
import Card1 from "../../assets/cardsHome/card1.png";
import Card2 from "../../assets/cardsHome/card2.png";
import Card3 from "../../assets/cardsHome/card3.png";
import Card4 from "../../assets/cardsHome/card4.png";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { useNavigate } from 'react-router-dom';

export default function HomePage() {
  const navigate = useNavigate();

  return (
    <HelmetProvider>
      <section id={styles.HomePage}>
        <Helmet>
          <title>Home - GoinGuru</title>
          <meta name="description" content="GoinGuru" />
        </Helmet>
        <Container className={styles.container}>
          <Row className={styles.carousel}>
            <Carousel>
              <CarouselItem>
                <Image className={styles.image} src={Banner1}/>
              </CarouselItem>
              <CarouselItem >
                <Image className={styles.image} src={Banner2}/>
              </CarouselItem>
            </Carousel>
          </Row>
        </Container>

        <div className={styles.rowCard}>
          <Container>
            <Row className={styles.title}>
              <p>Cadastre-se Gratuitamente</p>
              <p className={styles.subtitle}>Veja as Vantagens de ser um Parceiros Guru</p>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                <Card className={styles.card}>
                  <Row className={styles.rowImage}>
                    <Image className={styles.image} src={Card1} />
                  </Row>
                  <Row className={styles.cardBody}>
                    <p className={styles.text}>Divulge seu Serviço</p>
                  </Row>
                </Card>
              </Col>
              <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                  <Card className={styles.card}>
                    <Row className={styles.rowImage}>
                      <Image className={styles.image} src={Card2} />
                    </Row>
                    <Row className={styles.cardBody}>
                      <p className={styles.text}>Ganhe Visibilidade</p>
                    </Row>
                  </Card>
              </Col>
              <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                <Card className={styles.card}>
                  <Row className={styles.rowImage}>
                    <Image className={styles.image} src={Card3} />
                  </Row>
                  <Row className={styles.cardBody}>
                    <p className={styles.text}>Pagamento Garantindo</p>
                    {/* <Button onClick={() => navigate('/topsDaCidade')}  className={styles.button} variant='primary'>Saiba Mais</Button> */}
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Container>
          <Row className={styles.rowPay}>
            <Card className={styles.cardPay}> 
              <Row className={styles.pay}>
                <Col xs={12} sm={12} md={12} lg={4} xl={4} className={styles.colImage}>
                  <Image className={styles.image} src={Card4}/>
                </Col>
                <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                  <p className={styles.title}>Emitir pagamentos pela Goinguru</p>
                  <p className={styles.text}>Ao emitir um pagamento pela Goinguru, o seu cliente receberá uma notificação e finalizará o pagamento. Assim, você poderá iniciar o seu serviço tranquilamente, sabendo que o seu pagamento está garantido e seu cliente coberto pela segurança de pagamento.</p>
                </Col>
              </Row>
            </Card>
          </Row>
        </Container>

        <div className={styles.rowCust}>
          <Container>
            <Row className={styles.title}>
              <p>Vantagens para Você e seu Cliente</p>
            </Row>
            <Row className={styles.subtitle}>
              <p>Um ambiente seguro para negociações</p>
            </Row>
            <Row>
              <Col className={styles.colIcon}>
                <IoMdCheckmarkCircleOutline className={styles.icon}/>
              </Col>
              <Col className={styles.colIcon}>
                <IoMdCheckmarkCircleOutline className={styles.icon}/>
              </Col>
              <Col className={styles.colIcon}>
                <IoMdCheckmarkCircleOutline className={styles.icon}/>
              </Col>
              <Col className={styles.colIcon}>
                <IoMdCheckmarkCircleOutline className={styles.icon}/>
              </Col>
            </Row>
            <div className={styles.dashedLine}>
              <div className={styles.specialDot}></div>
              <div className={styles.specialDot}></div>
              <div className={styles.specialDot}></div>
              <div className={styles.specialDot}></div>
            </div>
            <Row>
              <Col className={styles.cust} xs={12} sm={6} md={6} lg={3} xl={3}>
                <Row className={styles.cardBody}>
                  <div className={styles.divIcon}>
                    <IoMdCheckmarkCircleOutline className={styles.icon}/>
                  </div>
                  <p className={styles.cTitle}>Segurança nas Transações</p>
                  <p className={styles.subtitle}>Reduza o risco de inadimplência e tenha mais tranquilidade financeira.</p>
                </Row>
              </Col>
              <Col className={styles.cust} xs={12} sm={6} md={6} lg={3} xl={3}>
                <Row className={styles.cardBody}>
                  <div className={styles.divIcon}>
                    <IoMdCheckmarkCircleOutline className={styles.icon}/>
                  </div>
                  <p className={styles.cTitle}>Maior Visibilidade e Alcance</p>
                  <p className={styles.subtitle}>Atraia mais clientes e expanda sua base de clientes potenciais.</p>
                </Row>
              </Col>
              <Col className={styles.cust} xs={12} sm={6} md={6} lg={3} xl={3}>
                <Row className={styles.cardBody}>
                  <div className={styles.divIcon}>
                    <IoMdCheckmarkCircleOutline className={styles.icon}/>
                  </div>
                  <p className={styles.cTitle}>Sistema de Agendamento</p>
                  <p className={styles.subtitle}>Sistema de agendamento com pagamento integrado para profissionais que trabalham com agendas e horário marcado para a realização dos seus serviços.</p>
                </Row>
              </Col>
              <Col className={styles.cust} xs={12} sm={6} md={6} lg={3} xl={3}>
                <Row className={styles.cardBody}>
                  <div className={styles.divIcon}>
                    <IoMdCheckmarkCircleOutline className={styles.icon}/>
                  </div>
                  <p className={styles.cTitle} >Possui um novo cliente?</p>
                  <p className={styles.subtitle2}>Explique sobre o Goinguru, peça-o para nos seguir em nossas redes sociais: @goinguru. Assim, ele terá mais segurança em fechar novos negicios com você, prestador de serviço.</p>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>

        <div className={styles.rowVcard}>
          <Container>
            <Row className={styles.rowButton}>
              <Button href="https://parceiro.goinguru.com/signup" target="_blank" rel="noopener noreferrer" className={styles.Vbutton}>Quero fazer parte!</Button>
            </Row>
          </Container>
        </div>   
      </section>
    </HelmetProvider>
  );
}

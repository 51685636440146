import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Container, Row, Col, Form, Button, Image, Modal, Spinner, Alert } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './style.module.scss';
import { PartnerAPI, useEmployeePartnerData, useOneEmployeePartnerData, usePatchOneEmployeePartner, useResumeServicesProvidedData } from '../../../../api/PartnerAPI/partnerClient';
import { useAuthContext } from '../../../../contexts/authContext';
import { toast } from 'react-toastify';
import Select from 'react-select';

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        borderColor: '#EE880C',
        boxShadow: state.isFocused ? '0 0 5px 2px #EE880C' : provided.boxShadow,
        '&:hover': {
            borderColor: '#EE880C'
        }
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        '&:hover': {
            color: '#EE880C'
        }
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#390040' : state.isFocused ? '#EE880C' : provided.backgroundColor,
        color: state.isSelected ? '#fff' : state.isFocused ? '#fff' : provided.color,
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: '#EE880C',
            color: '#fff'
        }
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#390040',
    }),
};

const daysOfWeek = [
    'Segunda',
    'Terça',
    'Quarta',
    'Quinta',
    'Sexta',
    'Sábado',
    'Domingo'
];

const dayKeys = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday'
];

const generateTimeOptions = () => {
    const times = [];
    for (let hour = 0; hour < 24; hour++) {
        for (let minute = 0; minute < 60; minute += 30) {
            const formattedHour = hour.toString().padStart(2, '0');
            const formattedMinute = minute.toString().padStart(2, '0');
            times.push(`${formattedHour}:${formattedMinute}`);
        }
    }
    return times;
};

const timeOptions = generateTimeOptions();

const defaultWorkingHours = dayKeys.reduce((acc, day) => {
    acc[day] = {
        is_active: true,
        open_time: '09:00',
        close_time: '18:00',
        has_break: false,
        break_start_time: null,
        break_end_time: null
    };
    return acc;
}, {});


export default function EmployeesEditPage() {
    const navigate = useNavigate();
    const { partnerSlug, employeeSlug } = useParams();
    const { token, user } = useAuthContext();
    const [isLoadingButton, setIsLoadingButton] = useState(false);
    const { data, isLoading, isError: isErrorData } = useOneEmployeePartnerData(employeeSlug, token);
    const { servicesData } = useResumeServicesProvidedData(partnerSlug, token);
    const { mutate: patchOneEmployeePartner, isSuccess, isError } = usePatchOneEmployeePartner(partnerSlug);
    const { refetch } = useEmployeePartnerData(partnerSlug, token, 1);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    
    const [openCloseState, setOpenCloseState] = useState(Array(daysOfWeek.length).fill(true));
    const [workingHours, setWorkingHours] = useState(defaultWorkingHours);

    const [formValues, setFormValues] = useState({
        photo: null,
        name: '',
        role: '',
        services: [],
        commission: 0,
        allServices: [],
        is_published: true
    });
    const [originalValues, setOriginalValues] = useState({});
    const [errors, setErrors] = useState({});

    const validateForm = () => {
        const newErrors = {};
        if (!formValues.photo) newErrors.photo = 'A imagem do colaborador é obrigatória!';
        if (!formValues.name) newErrors.name = 'O nome do colaborador é obrigatório!';
        if (!formValues.role) newErrors.role = 'O cargo do colaborador é obrigatório!';
        if (!formValues.commission) newErrors.commission = 'A Comissão do colaborador é obrigatória!';
        if (!formValues.services.length) newErrors.services = 'O serviço é obrigatório!';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!validateForm()) {
            toast.error('Preencha os campos obrigatórios!');
            return;
        }
        setIsLoadingButton(true);

        const changedValues = {};
        Object.keys(formValues).forEach(key => {
            if (JSON.stringify(formValues[key]) !== JSON.stringify(originalValues[key])) {
                changedValues[key] = formValues[key];
            }
        });

        const updatedWorkingHours = { ...workingHours };
        dayKeys.forEach(day => {
            if (!updatedWorkingHours[day].is_active) {
                updatedWorkingHours[day].open_time = null;
                updatedWorkingHours[day].close_time = null;
                updatedWorkingHours[day].has_break = false;
                updatedWorkingHours[day].break_start_time = null;
                updatedWorkingHours[day].break_end_time = null;
            }
            if (!updatedWorkingHours[day].has_break) {
                updatedWorkingHours[day].break_start_time = null;
                updatedWorkingHours[day].break_end_time = null;
            }
        });

        const workingHoursJson = JSON.stringify(updatedWorkingHours);
        const formData = new FormData();
        
        // formData.append('partner', partnerSlug);
        formData.append('user', user.id);
        if (changedValues.photo) formData.append('photo', formValues.photo);
        if (changedValues.name) formData.append('name', formValues.name);
        if (changedValues.role)formData.append('role', formValues.role);
        if (changedValues.services) formValues.services.forEach(service => formData.append('services_provided', service));
        if (changedValues.commission) formData.append('commission', formValues.commission);
        formData.append('working_hours', workingHoursJson);
        if (changedValues.is_published) formData.append('is_published', formValues.is_published);

        // Submit the form data
        // console.log('Form submitted:', Object.fromEntries(formData.entries()));
        patchOneEmployeePartner({ formData, token, employeeSlug });
    };

    useEffect(() => {
        if (data) {
            const initialValues = {
                photo: data.photo,
                name: data.name,
                role: data.role,
                services: data.services_provided,
                commission: data.commission,
                is_published: data.is_published,
            };
            setFormValues(prev => ({ ...prev, ...initialValues }));
            setOriginalValues(initialValues);
            setWorkingHours(data.working_hours || defaultWorkingHours);
            setOpenCloseState(dayKeys.map(day => data.working_hours ? data.working_hours[day].is_active : true));
        }
    }, [data]);

    const servicesOptions = useMemo(() => 
        servicesData?.map(categories => ({
            value: categories.id, 
            label: categories.name
        })) || [], 
        [servicesData]
    );


    const handleBack = useCallback(() => {
        navigate(`/${partnerSlug}/colaboradoresGuru/`);
    }, [navigate, partnerSlug]);

    useEffect(() => {
        if (isSuccess) {
            toast.success('Colaborador criado com sucesso!');
            setIsLoadingButton(false);
            handleBack();
        } 
        if (isError) {
            toast.error('Erro ao criar Colaborador, tente novamente!');
            setIsLoadingButton(false);
        }
    }, [isSuccess, isError, handleBack]);

    const handleServiceRemove = (serviceId) => {
        setFormValues(prevValues => ({
            ...prevValues,
            services: prevValues.services.filter(id => id !== serviceId)
        }));
    };

    const handleChange = (e) => {
        const { name, value, type, checked, files } = e.target;
        if (type === 'file') {
            setFormValues((prev) => ({
                ...prev,
                [name]: files[0]
            }));
        } else if (type === 'checkbox') {
            setFormValues((prev) => ({
                ...prev,
                [name]: checked
            }));
        } else {
            setFormValues((prev) => ({
                ...prev,
                [name]: value
            }));
        }
    };

    const handleOpenCloseChange = (index) => {
        const newOpenCloseState = [...openCloseState];
        const newWorkingHours = { ...workingHours };

        newOpenCloseState[index] = !newOpenCloseState[index];
        newWorkingHours[dayKeys[index]].is_active = newOpenCloseState[index];

        if (!newOpenCloseState[index]) {
            newWorkingHours[dayKeys[index]].open_time = null;
            newWorkingHours[dayKeys[index]].close_time = null;
            newWorkingHours[dayKeys[index]].has_break = false;
            newWorkingHours[dayKeys[index]].break_start_time = null;
            newWorkingHours[dayKeys[index]].break_end_time = null;
        }

        setOpenCloseState(newOpenCloseState);
        setWorkingHours(newWorkingHours);
    };

    const handleTimeChange = (index, field, value) => {
        const newWorkingHours = { ...workingHours };
    
        if (field === 'open_time') {
            if (value >= newWorkingHours[dayKeys[index]].close_time) {
                alert("O horário de abertura não pode ser maior ou igual ao horário de fechamento.");
                return;
            }
        } else if (field === 'close_time') {
            if (value <= newWorkingHours[dayKeys[index]].open_time) {
                alert("O horário de fechamento não pode ser menor ou igual ao horário de abertura.");
                return;
            }
        }

        newWorkingHours[dayKeys[index]][field] = value;
    
        if (field === 'break_start_time' || field === 'break_end_time') {
            const breakStart = newWorkingHours[dayKeys[index]].break_start_time;
            const breakEnd = newWorkingHours[dayKeys[index]].break_end_time;
    
            if (breakStart && breakEnd) {
                if (breakStart >= breakEnd) {
                    alert("O horário de início da pausa não pode ser maior ou igual ao horário de término da pausa.");
                    return;
                }
                if (breakStart < newWorkingHours[dayKeys[index]].open_time || breakEnd > newWorkingHours[dayKeys[index]].close_time) {
                    alert("Os horários de pausa devem estar dentro do horário de funcionamento.");
                    return;
                }
            }
        }
    
        setWorkingHours(newWorkingHours);
    };

    const handleBreakChange = (index, value) => {
        const newWorkingHours = { ...workingHours };
        newWorkingHours[dayKeys[index]].has_break = value;
    
        if (!value) {
            newWorkingHours[dayKeys[index]].break_start_time = null;
            newWorkingHours[dayKeys[index]].break_end_time = null;
        }
    
        setWorkingHours(newWorkingHours);
    };

    const filterTimeOptions = (start, end) => {
        return timeOptions.filter(time => time >= start && time <= end);
    };

    const handleDeleteModalOpen = () => {
        setDeleteModalOpen(true);
    };

    const handleDeleteModalClose = () => {
        setDeleteModalOpen(false);
    };

    const handleDelete = async () => {
        try {
            const response = await PartnerAPI.deleteOneEmployee(employeeSlug, token);
            if (response.status === 200) {
                refetch(user.id, token, 1);
                handleDeleteModalClose();
                toast.success('Colaborador excluído com sucesso!');
                handleBack();
            } else {
                toast.error('Erro ao excluir colaborador, tente novamente!');
            }
        } catch (error) {
            toast.error('Erro ao excluir colaborador, tente novamente!');
        }
    };

    if (isLoading) {
        return (
            <section id={styles.SectionCompanyGuru}>
                <Container>
                    <Row>
                        <Col className="text-center">
                            <Spinner animation="border" role="status">
                            </Spinner>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }

    if (isErrorData) {
        return (
            <section id={styles.SectionCompanyGuru}>
                <Container>
                    <Row>
                        <Col>
                            <Alert variant="danger">
                                Erro ao carregar os dados. Por favor, tente novamente mais tarde.
                            </Alert>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }

    return (
        <section id={styles.EmployeesEditPage}>
            <Container>
                <Row>
                    <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                        <p className={styles.title}>Editar Colaborador</p>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6} xl={6} className={styles.colButton}>
                        <Button className={styles.buttonDelete} onClick={handleDeleteModalOpen}>
                            Excluir
                        </Button>
                    </Col>
                </Row>
                <Row className={styles.rowForm}>
                    <Form onSubmit={handleSubmit} className={styles.form}>
                        <Row>
                            <Col xs={12} md={6} className={styles.colPhoto}>
                                {formValues.photo && 
                                    <Image
                                        src={formValues.photo instanceof Blob ? URL.createObjectURL(formValues.photo) : formValues.photo}
                                        className={styles.photo}
                                    />
                                }
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group controlId="photo">
                                    <Form.Label className={styles.formTitle}>Foto</Form.Label>
                                    <Form.Control
                                        type="file"
                                        name="photo"
                                        className={styles.formControl}
                                        onChange={handleChange}
                                        accept=".jpg,.jpeg,.png,.webp"
                                        isInvalid={!!errors.photo}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.photo}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Form.Group controlId="name">
                                <Form.Label className={styles.formTitle}>Nome Completo</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    className={styles.formControl}
                                    value={formValues.name}
                                    onChange={handleChange}
                                    maxLength={100}
                                    isInvalid={!!errors.name}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.name}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Col xs={12} md={4}>
                                <Form.Group controlId="role">
                                    <Form.Label className={styles.formTitle}>Cargo</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="role"
                                        className={styles.formControl}
                                        value={formValues.role}
                                        onChange={handleChange}
                                        maxLength={100}
                                        isInvalid={!!errors.role}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.role}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={4}>
                                <Form.Group controlId="commission">
                                    <Form.Label className={styles.formTitle}>Comissão (%)</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="commission"
                                        className={styles.formControl}
                                        value={formValues.commission}
                                        onChange={handleChange}
                                        maxLength={100}
                                        isInvalid={!!errors.commission}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.commission}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={4}>
                                <Form.Group controlId="services">
                                    <Form.Label className={styles.formTitle}>Serviços Prestados</Form.Label>
                                    <Select 
                                        options={servicesOptions}
                                        placeholder="Selecione o serviço"
                                        isClearable
                                        styles={customStyles}
                                        onChange={(selectedOption) => {
                                            const selectedId = selectedOption ? selectedOption.value : null;
                                            if (selectedId && !formValues.services.includes(selectedId)) {
                                                setFormValues((prev) => ({
                                                    ...prev,
                                                    services: [...prev.services, selectedId]
                                                }));
                                            }
                                        }}
                                    />
                                    <div className={styles.selectedDrop}>
                                        {formValues.services.map((serviceId) => {
                                            const service = servicesData?.find(t => t.id === serviceId);
                                            return (
                                                <Button
                                                    key={serviceId}
                                                    onClick={() => handleServiceRemove(serviceId)}
                                                    className={styles.selectedDrop}
                                                >
                                                    {service?.name} &times;
                                                </Button>
                                            );
                                        })}
                                    </div>
                                    {errors.services && (
                                        <Alert variant="danger">
                                            {errors.services}
                                        </Alert>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Form.Label className={styles.formTitle}>Horário de Atendimento</Form.Label>
                        </Row>
                        <Row>
                            {daysOfWeek.map((day, index) => (
                                <Col xs={12} sm={12} md={6} lg={4} xl={3} key={index} className={styles.colForm}>
                                    <Row className={`${styles.rowCard} ${!openCloseState[index] ? styles.noBorder : ''}`}>
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className={styles.colDay}>
                                            <Col xs={12}>
                                                <p className={styles.dayLabel}>{day}</p>
                                            </Col>
                                            <Col xs={12} className={styles.colDay}>
                                                <Form.Switch
                                                    type='checkbox'
                                                    id={`open-close-${index}`}
                                                    label={openCloseState[index] ? 'Aberto' : 'Fechado'}
                                                    checked={openCloseState[index]}
                                                    onChange={() => handleOpenCloseChange(index)}
                                                    
                                                />
                                            </Col>
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                            {openCloseState[index] && (
                                                <Row>
                                                    <Col xs={6}>
                                                        <Form.Group controlId={`open-${index}`}>
                                                            <Form.Label className={styles.formTitle}>Aberto às</Form.Label>
                                                            <Form.Select
                                                                className={styles.formControl}
                                                                value={workingHours[dayKeys[index]].open_time}
                                                                onChange={(e) => handleTimeChange(index, 'open_time', e.target.value)}
                                                                
                                                            >
                                                                {timeOptions.map((time, i) => (
                                                                    <option key={i} value={time}>{time}</option>
                                                                ))}
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={6}>
                                                        <Form.Group controlId={`close-${index}`}>
                                                            <Form.Label className={styles.formTitle}>Fechado às</Form.Label>
                                                            <Form.Select
                                                                className={styles.formControl}
                                                                value={workingHours[dayKeys[index]].close_time}
                                                                onChange={(e) => handleTimeChange(index, 'close_time', e.target.value)}
                                                                
                                                            >
                                                                {timeOptions.map((time, i) => (
                                                                    <option key={i} value={time}>{time}</option>
                                                                ))}
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} className={styles.colCheck}>
                                                        <Form.Check
                                                            type='checkbox'
                                                            id={`lunch-break-${index}`}
                                                            label='Fechado para almoço?'
                                                            className={styles.formSubTitle}
                                                            checked={workingHours[dayKeys[index]].has_break}
                                                            onChange={(e) => handleBreakChange(index, e.target.checked)}
                                                            
                                                        />
                                                    </Col>
                                                    {workingHours[dayKeys[index]].has_break && (
                                                        <>
                                                            <Col xs={6} className={styles.colFormEnd}>
                                                                <Form.Group controlId={`lunch-start-${index}`}>
                                                                    <Form.Label className={styles.formTitle}>Pausa das</Form.Label>
                                                                    <Form.Select
                                                                        className={styles.formControl}
                                                                        value={workingHours[dayKeys[index]].break_start_time}
                                                                        onChange={(e) => handleTimeChange(index, 'break_start_time', e.target.value)}
                                                                        
                                                                    >
                                                                        {filterTimeOptions(workingHours[dayKeys[index]].open_time, workingHours[dayKeys[index]].close_time).map((time, i) => (
                                                                            <option key={i} value={time}>{time}</option>
                                                                        ))}
                                                                    </Form.Select>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col xs={6} className={styles.colFormEnd}>
                                                                <Form.Group controlId={`lunch-end-${index}`}>
                                                                    <Form.Label className={styles.formTitle}>até</Form.Label>
                                                                    <Form.Select
                                                                        className={styles.formControl}
                                                                        value={workingHours[dayKeys[index]].break_end_time}
                                                                        onChange={(e) => handleTimeChange(index, 'break_end_time', e.target.value)}
                                                                        
                                                                    >
                                                                        {filterTimeOptions(workingHours[dayKeys[index]].open_time, workingHours[dayKeys[index]].close_time).map((time, i) => (
                                                                            <option key={i} value={time}>{time}</option>
                                                                        ))}
                                                                    </Form.Select>
                                                                </Form.Group>
                                                            </Col>
                                                        </>
                                                    )}
                                                </Row>
                                            )}
                                        </Col>
                                    </Row>
                                </Col>
                            ))}
                        </Row>
                        <Row>
                            <Form.Group controlId="is_published">
                                <Form.Label className={styles.formTitle}>Funcionário ativo?</Form.Label>
                                <Form.Check
                                    type="switch"
                                    id="isPublishedSwitch"
                                    name="is_published"
                                    label={formValues.is_published ? "Sim" : "Não, ocultar funcionário!"}
                                    checked={formValues.is_published}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Row>
                        <Row className={styles.rowButton}>
                            <Button className={styles.buttonCancelar} onClick={handleBack}>Cancelar</Button>
                            <Button className={styles.button} type="submit" disabled={isLoadingButton}>
                                {isLoadingButton ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Salvar"}
                            </Button>
                        </Row>
                    </Form>
                </Row>
            </Container>
            <Modal show={deleteModalOpen} onHide={handleDeleteModalClose} centered className={styles.modal}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmar Exclusão</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Tem certeza de que deseja excluir este serviço?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className={styles.buttonCancel} onClick={handleDeleteModalClose}>
                        Cancelar
                    </Button>
                    <Button className={styles.buttonDelete} onClick={handleDelete}>
                        Excluir
                    </Button>
                </Modal.Footer>
            </Modal>
        </section>
    );
}

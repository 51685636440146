import React, { useState } from 'react';
import { Col, Container, Row,  Card, Badge, Alert, Spinner } from 'react-bootstrap';
import styles from './style.module.scss';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { FaAngleDown, FaAngleRight} from 'react-icons/fa';
import { usePaymentReportDetailPartnerPartnerData } from '../../api/PartnerAPI/partnerClient';
import { useAuthContext } from '../../contexts/authContext';
import { format, setDefaultOptions, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';

setDefaultOptions({ locale: ptBR });

export default function FinancialPayoutPage() {
  const { partnerSlug, requestSlug } = useParams();
  const { token } = useAuthContext();
  const navigate = useNavigate();
  
  const { data, isLoading, isError, refetch } = usePaymentReportDetailPartnerPartnerData(token, requestSlug);
  const [status, setStatus] = useState(true);
  
  const [isExpandedOrder, setIsExpandedOrder] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  
  const toggleExpandOrder = () => {
    setIsExpandedOrder(!isExpandedOrder);
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const formatPrice = (value) => {
    if (!value) return 'R$ 0,00';
    const number = parseFloat(value);
    if (isNaN(number)) return 'R$ 0,00';
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(number);
  };

  if (isLoading) {
    return (
      <section id={styles.FinancialPayoutPage}>
        <Container>
          <Row>
            <Col className="text-center">
              <Spinner animation="border" role="status">
              </Spinner>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }

  if (isError) {
    return (
      <section id={styles.FinancialPayoutPage}>
        <Container>
          <Row>
            <Col>
              <Alert variant="danger">
                Erro ao carregar os dados. Por favor, tente novamente mais tarde.
              </Alert>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }

  return (
    <HelmetProvider>
      <section id={styles.FinancialPayoutPage}>
        <Helmet>
          <title>Repasse - GoinGuru</title>
        </Helmet>
        <Container>
          <Row>
            <Col>
              <p className={styles.title}>Repasse de {format(parseISO(data?.expected_payment_date), 'dd/MM/yyyy')}</p>
            </Col>
          </Row>
          <Row className={styles.rowCard}>
            <p className={styles.title}>Faturamento do mês {
              <Badge
                bg={
                  data?.status === "paid" ? "success" :
                  data?.status === "pending" ? "warning" :
                  data?.status === "failed" ? "danger" :
                  data?.status === "refunded" ? "info" :
                  "secondary"
                }
                className={styles.badge}
              >
                {data?.status === "paid" ? "Pago" :
                  data?.status === "pending" ? "Em Andamento" :
                  data?.status === "failed" ? "Falhou" :
                  data?.status === "refunded" ? "Reembolsado" :
                  "Desconhecido"
                }
              </Badge>}
            </p>
            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <Card className={styles.card}>
                <Row className={styles.rowItem}>
                  <p className={styles.text}>Valor de repasse</p>
                  <p className={styles.subText}>{formatPrice(data?.value_to_repass)}</p> 
                </Row>
              </Card>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <Card className={styles.card}>
                <Row className={styles.rowItem}>
                  <p className={styles.text}>Período de apuração</p>
                  <p className={styles.subText}>{format(parseISO(data?.report_start_date), 'dd/MM/yyyy')} a {format(parseISO(data?.report_end_date), 'dd/MM/yyyy')}</p>
                </Row>
              </Card>
            </Col>
          </Row>

          <Row className={styles.rowCard}>
            <Card className={styles.card}>
              <Row className={styles.rowItem}>
                <Col xs={12} sm={8} md={8} lg={8} xl={8} >
                  <p className={styles.textValue}>Valor Bruto das vendas</p>
                  <p className={styles.subTextValue}>Valor bruto total dos pedidos</p>
                </Col>
                <Col xs={12} sm={3} md={3} lg={3} xl={3} className={styles.colSubText}>
                  <p className={styles.subText}>{formatPrice(data?.total_value)}</p>
                </Col>
                <Col xs={12} sm={1} md={1} lg={1} xl={1} className={styles.colIcon}>
                  <div onClick={toggleExpandOrder}>
                    {isExpanded ? <FaAngleDown className={styles.icon}/> : <FaAngleRight className={styles.icon}/>}
                  </div>
                </Col>
              </Row>

              {isExpandedOrder && data?.client_orders && data.client_orders.map((item, index) => (
                <Row 
                    key={index} 
                    className={styles.rowSubItem} 
                    onClick={() => navigate(`/${partnerSlug}/financeiro/historico/${item.orderNumber}`)} 
                    style={{ cursor: 'pointer' }}
                >
                    <Col xs={12} sm={8} md={8} lg={8} xl={8}>
                        <p className={styles.info}>{item.name} - {item.orderNumber}</p>
                    </Col>
                    <Col xs={12} sm={3} md={3} lg={3}>
                        <p className={styles.infoValue}>{formatPrice(item.price)}</p>
                    </Col>
                    <Col xs={12} sm={1} md={1} lg={1} className={styles.colIcon}>
                        <FaAngleRight className={styles.icon} />
                    </Col>
                </Row>
            ))}
            </Card>
          </Row>

          <Row className={styles.rowCard}>
            <Card className={styles.card}>
              <Row className={styles.rowItem}>
                <Col xs={12} sm={8} md={8} lg={8} xl={8}>
                  <p className={styles.textValue}>Taxas e comissões</p>
                  <p className={styles.subTextValue}>Valor bruto total das taxas e comissões da GoinGuru</p>
                </Col>
                <Col xs={12} sm={3} md={3} lg={3} xl={3} className={styles.colSubText}>
                  <p className={styles.subText}>- {formatPrice(data?.total_taxas_e_descontos)}</p>
                </Col>
                <Col xs={12} sm={1} md={1} lg={1} xl={1} className={styles.colIcon}>
                  <div onClick={toggleExpand} className={styles.iconWrapper}>
                    {isExpanded ? <FaAngleDown className={styles.icon}/> : <FaAngleRight className={styles.icon}/>}
                  </div>
                </Col>
              </Row>

              {isExpanded && (
                <>
                  <Row className={styles.rowSubItem}>
                    <Col xs={12} sm={8} md={8} lg={8} xl={8}>
                      <p className={styles.info}>Comissão pela transação do pagamento</p>
                    </Col>
                    <Col xs={12} sm={3} md={3} lg={3} xl={3}>
                      <p className={styles.infoValue}>- {formatPrice(data?.card_fee_total)}</p>
                    </Col>
                  </Row>
                  <Row className={styles.rowSubItem}>
                    <Col xs={12} sm={8} md={8} lg={8} xl={8}>
                      <p className={styles.info}>Comissão da Goinguru</p>
                    </Col>
                    <Col xs={12} sm={3} md={3} lg={3} xl={3}>
                      <p className={styles.infoValue}>- {formatPrice(data?.service_fee_total)}</p>
                    </Col>
                  </Row>
                </>
              )}
            </Card>
          </Row>

          <Row className={styles.rowCard}>
            <Card className={styles.card}>
              <Row className={styles.rowItem}>
                <Col xs={12} sm={8} md={8} lg={8} xl={8}>
                  <p className={styles.textValue}>Cancelamentos e reembolsos</p>
                  <p className={styles.subTextValue}>Valor bruto total dos pedidos cancelados e seus devidos reembolsos e descontos</p>
                </Col>
                <Col xs={12} sm={3} md={3} lg={3} xl={3} className={styles.colSubText}>
                  <p className={styles.subText}>- {formatPrice(data?.total_value_refunded)}</p>
                </Col>
                <Col xs={12} sm={1} md={1} lg={1} xl={1} className={styles.colIcon}>
                  <FaAngleRight className={styles.icon}/>
                </Col>
              </Row>
            </Card>
          </Row>

          <Row className={styles.rowCard}>
            <Card className={styles.card}>
              <Row className={styles.rowItem}>
                <Col>
                  <p className={styles.textFinalValue}>Valor Bruto</p>
                  <p className={styles.subText}>{formatPrice(data?.total_value)}</p>
                </Col>
                <Col>
                  <p className={styles.textFinalValue}>Descontos ou acréscimos</p>
                  <p className={styles.subText}>- {formatPrice(data?.total_taxas_e_descontos)}</p>
                </Col>
                <Col>
                  <p className={styles.textFinalValue}>Valor liquido Total</p>
                  <p className={styles.subText}>{formatPrice(data?.value_to_repass)}</p>
                </Col>
              </Row>
            </Card>
          </Row>
        </Container>
      </section>
    </HelmetProvider>
  );
}

import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Button, InputGroup, Form, Card, Image, Badge } from 'react-bootstrap';
import styles from './style.module.scss';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { format, setDefaultOptions } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import Calendar from 'react-calendar';
import { FaAngleRight, FaCalendarAlt } from 'react-icons/fa';
import { useListOrderPaymentPartner } from '../../../api/PartnerAPI/partnerClient';
import { useAuthContext } from '../../../contexts/authContext';
import Pagination from '../../../components/pagination';
import NoImage from "../../../assets/no-image.jpg";

setDefaultOptions({ locale: ptBR });

const formatPrice = (value) => {
    if (!value) return '-';
    const number = parseFloat(value);
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(number);
};

export default function HistoryFinancialPage() {
    const { partnerSlug } = useParams();
    const { token, user } = useAuthContext();
    const navigate = useNavigate();
    const [page, setPage] = useState(1);

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };

    const [selectedDate, setSelectedDate] = useState(new Date());
    const [showCalendar, setShowCalendar] = useState(false);
    const [status, setStatus] = useState(true);

    const { data, isLoading, isError, refetch } = useListOrderPaymentPartner(partnerSlug, token, page);
    
    useEffect(() => {
        refetch(partnerSlug, token, page);
    }, [page, token, partnerSlug, refetch]);

    const handleDateChange = (newDate) => {
        if (newDate && !isNaN(newDate)) {
            setSelectedDate(newDate);
        }
    };

    return (
        <HelmetProvider>
            <section id={styles.HistoryFinancialPage}>
                <Helmet>
                <title>Histórico de Cobranças - GoinGuru</title>
                </Helmet>
                <Container>
                    <Row>
                        <p className={styles.title}>Histórico de Cobranças</p>
                    </Row>
                    <Row>
                        <Col className={styles.colWithCalendar} xs={12} sm={6} md={6} lg={4} xl={4}>
                            <InputGroup className={styles.buttonCalendarToggle}>
                                <InputGroup.Text onClick={() => setShowCalendar(!showCalendar)}>
                                    <FaCalendarAlt className={styles.icon}/>
                                </InputGroup.Text>
                                <Form.Control
                                    type="text"
                                    value={selectedDate ? format(selectedDate, 'MMMM / yyyy') : ''}
                                    onChange={handleDateChange}
                                    className={styles.buttonDate}
                                    disabled
                                />
                            </InputGroup>
                            {showCalendar && (
                                <div className={styles.calendarWrapper}>
                                    <Calendar
                                        onChange={setSelectedDate}
                                        value={selectedDate}
                                        view="year"
                                        onClickMonth={(value) => setSelectedDate(value)}
                                        minDetail="year"
                                        locale="pt-BR"
                                        className={styles.datePicker}
                                    />
                                </div>
                            )}
                        </Col>
                    </Row>

                    <Row className={styles.rowPayment}>
                        {!isLoading && !isError && data && data?.results.map(orderClient => (
                            <Card key={orderClient.id} className={styles.cardClientPayment} onClick={() => navigate(`/${partnerSlug}/financeiro/historico/${orderClient.orderNumber}`)}>
                                <Row className={styles.rowClientPayment}>
                                    <Col xs={3} sm={4} md={3} lg={2} xl={2} className={styles.colPartnerPayment}>
                                        <Image
                                            src={orderClient.serviceProvided.photo || NoImage}
                                            className={styles.image}
                                        />
                                    </Col>
                                    <Col xs={9} sm={8} md={7} lg={8} xl={8} className={styles.colServicePayment}>
                                        <p><strong>Serviço:</strong> {orderClient.serviceProvided.name}</p>
                                        <Row>
                                            <p className={styles.subText}>
                                                <strong>Preço:</strong> {(orderClient.price ? formatPrice(orderClient.price) : '-')}
                                            </p>
                                        </Row>
                                        <p><strong>Observações:</strong> {orderClient.observations}</p>
                                        <p className={styles.subText}>
                                            <strong>Tipo do serviço: </strong> 
                                            {orderClient.serviceProvided.is_home_service
                                                ? 'Domicílio'
                                                : orderClient.serviceProvided.is_online_service
                                                ? 'Online'
                                                : 'No Local'}
                                        </p>
                                    </Col>
                                    <Col xs={12} sm={12} md={2} lg={2} xl={2} className={styles.colEmployeePayment}>
                                        <p><strong>Status:</strong></p>
                                        <Badge
                                            bg={
                                                orderClient.status === "paid"
                                                    ? "success"
                                                    : orderClient.status === "pending"
                                                    ? "warning"
                                                    : orderClient.status === "failed" || orderClient.status === "rejected"
                                                    ? "danger"
                                                    : orderClient.status === "refunded"
                                                    ? "info"
                                                    : orderClient.status === "cancelled_by_c"
                                                    ? "dark"
                                                    : orderClient.status === "cancelled_by_p"
                                                    ? "secondary"
                                                    : orderClient.status === "finished"
                                                    ? "dark"
                                                    : "secondary"
                                            }
                                            className={styles.badge}
                                        >
                                            {orderClient.status === "paid"
                                                ? "Pago"
                                                : orderClient.status === "pending"
                                                ? "Pendente"
                                                : orderClient.status === "failed" || orderClient.status === "rejected"
                                                ? "Falha no Pagamento"
                                                : orderClient.status === "refunded"
                                                ? "Devolvido"
                                                : orderClient.status === "cancelled_by_c"
                                                ? "Cancelamento Cliente"
                                                : orderClient.status === "cancelled_by_p"
                                                ? "Cancelamento Parceiro"
                                                : orderClient.status === "finished"
                                                ? "Concluído"
                                                : "Status Desconhecido"}
                                        </Badge>
                                    </Col>
                                </Row>
                            </Card>
                        ))}
                    </Row>

                    <Row>
                        <Pagination totalPages={data ? Math.ceil(data.count / 10) : 1} onPageChange={handlePageChange}/>
                    </Row>
                </Container>
            </section>
        </HelmetProvider>
    );
}

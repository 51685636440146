import React from 'react';
import { Container, Row, Alert, Spinner } from 'react-bootstrap';
import styles from "./style.module.scss";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useOneDocumentGuruData } from '../../api/documentsAPI/documents';

export default function TermosCondicoesPage() {
  const { docData, isLoading, isError } = useOneDocumentGuruData('termos-e-condicoes');

  return (
    <HelmetProvider>
      <section id={styles.TermosCondicoesPage}>
        <Helmet>
          <title>Termos e Condições - GoinGuru</title>
          <meta name="description" content="GoinGuru" />
        </Helmet>

        <div className={styles.rowCard}>
          <Container>
            <p className={styles.Ptext}>Termos e Condições</p>
          </Container>
        </div>

        <div className={styles.rowpdf}>
          <Container>
            <Row>
              {isLoading && (
                <Spinner animation="border" role="status">
                </Spinner>
              )}

              {isError && (
                <Alert variant="danger">
                  Ocorreu um erro ao carregar os Termos e Condições. Tente novamente mais tarde.
                </Alert>
              )}

              {!isLoading && !isError && docData?.file && (
                <iframe
                  title='Termos e Condições'
                  src={docData.file}
                  className={styles.iframePdf}
                />
              )}

              {!isLoading && !isError && !docData?.file && (
                <Alert variant="warning">
                  Não foi possível encontrar o documento de Termos e Condições.
                </Alert>
              )}
            </Row>
          </Container>
        </div>
      </section>
    </HelmetProvider>
  );
}

import React from 'react';
import { Container, Row, Alert, Spinner } from 'react-bootstrap';
import styles from "./style.module.scss";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useOneDocumentGuruData } from '../../api/documentsAPI/documents';

export default function PoliticasCookiesPage() {
  const { docData, isLoading, isError } = useOneDocumentGuruData('politica-de-privacidade-e-cookies');

  return (
    <HelmetProvider>
      <section id={styles.PoliticasCookiesPage}>
        <Helmet>
          <title>Política de Privacidade e Cookies - GoinGuru</title>
          <meta name="description" content="GoinGuru" />
        </Helmet>

        <div className={styles.rowCard}>
          <Container>
            <p className={styles.Ptext}>Política de Privacidade e Cookies</p>
          </Container>
        </div>

        <div className={styles.rowpdf}>
          <Container>
            <Row>
              {isLoading && (
                <Spinner animation="border" role="status">
                </Spinner>
              )}

              {isError && (
                <Alert variant="danger">
                  Ocorreu um erro ao carregar as Política de Privacidade e Cookies. Tente novamente mais tarde.
                </Alert>
              )}

              {!isLoading && !isError && docData?.file && (
                <iframe
                  title='Termos e Condições'
                  src={docData.file}
                  className={styles.iframePdf}
                />
              )}

              {!isLoading && !isError && !docData?.file && (
                <Alert variant="warning">
                  Não foi possível encontrar o documento de Política de Privacidade e Cookies.
                </Alert>
              )}
            </Row>
          </Container>
        </div>
      </section>
    </HelmetProvider>
  );
}

import React from 'react';
import { ProgressBar, Step } from 'react-step-progress-bar';
import "react-step-progress-bar/styles.css";
import styles from "./style.module.scss";

const CustomProgressBar = ({ steps, currentStep }) => {
  const progressPercentage = ((currentStep - 1) / (steps.length - 1)) * 100;

  return (
    <div className={styles.progressBarWrapper}>
      <ProgressBar
        percent={progressPercentage}
        filledBackground="linear-gradient(to right, #EE880C, #390040)"
        width="80%"
      >
        {steps.map((step, index) => (
          <Step key={index} transition="scale">
            {({ accomplished }) => (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div
                  style={{
                    width: '50px',
                    height: '50px',
                    borderRadius: '50%',
                    marginTop: '40px',
                    backgroundColor: accomplished ? '#EE880C' : '#ccc',
                    color: accomplished ? '#fff' : '#3c3c3c',
                    fontWeight: 'bold',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {index + 1}
                </div>
                <p style={{ textAlign: 'center', marginTop: '10px', fontWeight: 'bold', color: '#EE880C', }}>{step}</p>
              </div>
            )}
          </Step>
        ))}
      </ProgressBar>
    </div>
  );
};

export default CustomProgressBar;

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Container, Row, Col, Form, Button, Image, Alert, Spinner, ToggleButton, ToggleButtonGroup, Card } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './style.module.scss';
import { useAuthContext } from '../../../../contexts/authContext';
import { useOneCategoriesServicesPartnerData, usePostOneServicesProvided } from '../../../../api/PartnerAPI/partnerClient';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { NumericFormat } from 'react-number-format';

export default function ServicesCreatePage() {
    const { partnerSlug } = useParams();
    const { token, user } = useAuthContext();
    const { categoriesData } = useOneCategoriesServicesPartnerData(partnerSlug, token);
    const { mutate: postOneServicesProvided, isSuccess, isError } = usePostOneServicesProvided(partnerSlug);
    const [isLoadingButton, setIsLoadingButton] = useState(false);
    
    const [calculationType, setCalculationType] = useState('Cobrar');
    const [result, setResult] = useState('');
    const [taxaResult, setTaxaResult] = useState('');
    const feePercentage = 9.98;

    const navigate = useNavigate();

    const [formValues, setFormValues] = useState({
        photo: null,
        service: '',
        description: '',
        price: '',
        duration: '',
        customquote: false,
        homeService: false,
        is_onlineService: false,
        is_published: false,
        categorias: [],
        tags: [],
    });

    const [errors, setErrors] = useState({});

    const validateForm = () => {
        const newErrors = {};
        if (!formValues.photo) newErrors.photo = 'A imagem do serviço é obrigatória.';
        if (!formValues.service) newErrors.service = 'O nome do serviço é obrigatório.';
        if (!formValues.description) newErrors.description = 'A descrição do serviço é obrigatória.';
        if (!formValues.customquote && !formValues.price) newErrors.price = 'O preço é obrigatório.';
        if (!formValues.duration || formValues.duration === '00:00:00') newErrors.duration = 'A duração deve ser maior.';
        if (!formValues.categorias.length) newErrors.categorias = 'Pelo menos uma categoria é obrigatória.';
        if (!formValues.tags.length) newErrors.tags = 'Pelo menos uma tag é obrigatória.';
        
        const flagCount = [formValues.homeService, formValues.is_onlineService].filter(Boolean).length;
        if (flagCount > 1) {
            newErrors.flags = 'Apenas uma das opções "Atendimento a Domicílio" ou "Serviço Online" pode estar ativada.';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!validateForm()) {
            toast.error('Preencha os campos obrigatórios!');
            return;
        }
        setIsLoadingButton(true);
        
        let priceValue;
        if (calculationType === 'Receber') {
            priceValue = result 
            ? result.replace(/[^0-9,.]/g, '').replace(',', '.')
            : '';
        } else {
            priceValue = formValues.customquote ? '' : formValues.price;
        }
        console.log(priceValue)
        const formData = new FormData();
        formData.append('user', user.id);
        formData.append('photo', formValues.photo);
        formData.append('name', formValues.service);
        formData.append('description', formValues.description);
        formData.append('price', priceValue);
        formData.append('duration', formValues.duration + ':00');
        formData.append('is_home_service', formValues.homeService);
        formData.append('is_published', formValues.is_published);
        formData.append('is_custom_quote', formValues.customquote);
        formData.append('is_online_service', formValues.is_onlineService);
        formValues.categorias.forEach(categoria => formData.append('categories_service', categoria));
        formValues.tags.forEach(tag => formData.append('services_service', tag));

        postOneServicesProvided({ formData, token, partnerSlug });
    };

    const categoriesOptions = useMemo(() => 
        categoriesData?.categories?.map(categories => ({
            value: categories.id, 
            label: categories.name
        })) || [], 
        [categoriesData]
    );

    const tagsOptions = useMemo(() => 
        categoriesData?.services?.map(tags => ({
            value: tags.id, 
            label: tags.name
        })) || [], 
        [categoriesData]
    );

    const handleCategoryRemove = (id) => {
        setFormValues((prev) => ({
            ...prev,
            categorias: prev.categorias.filter(categoriaId => categoriaId !== id)
        }));
    };

    const handleTagRemove = (id) => {
        setFormValues((prev) => ({
            ...prev,
            tags: prev.tags.filter(tagId => tagId !== id)
        }));
    };

    const generateTimeOptions = () => {
        const times = [];
        for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute += 30) {
                const formattedHour = hour.toString().padStart(2, '0');
                const formattedMinute = minute.toString().padStart(2, '0');
                times.push(`${formattedHour}:${formattedMinute}`);
            }
        }
        return times;
    };
    
    const timeOptions = generateTimeOptions();

    const handleBack = useCallback(() => {
        navigate(`/${partnerSlug}/servicosGuru/`);
    }, [navigate, partnerSlug]);

    useEffect(() => {
        if (isSuccess) {
            toast.success('Serviço criado com sucesso!');
            setIsLoadingButton(false);
            handleBack();
        } 
        if (isError) {
            toast.error('Erro ao criar serviço, tente novamente!');
            setIsLoadingButton(false);
        }
    }, [isSuccess, isError, handleBack]);

    const handleChange = (e) => {
        const { name, value, type, checked, files } = e.target;
    
        if (type === 'file') {
            setFormValues((prev) => ({
                ...prev,
                [name]: files[0]
            }));
        } else if (type === 'checkbox') {
            if (name === 'homeService' && checked) {
                setFormValues((prev) => ({
                    ...prev,
                    homeService: true,
                    is_onlineService: false,
                }));
            } else if (name === 'is_onlineService' && checked) {
                setFormValues((prev) => ({
                    ...prev,
                    homeService: false,
                    is_onlineService: true,
                }));
            } else {
                setFormValues((prev) => ({
                    ...prev,
                    [name]: checked
                }));
            }
        } else {
            setFormValues((prev) => ({
                ...prev,
                [name]: value
            }));
        }
    };

    const handleCalculationTypeChange = (val) => {
        setCalculationType(val);
        setResult('');
    };

    useEffect(() => {
        if (calculationType && formValues.price) {
            const cleanedPrice = formValues.price.replace(/[R$\s]/g, '').replace(',', '.');
            const price = parseFloat(cleanedPrice);
    
            if (!isNaN(price)) {
                if (calculationType === 'Cobrar') {
                    const valueToCharge = price - (price * feePercentage) / 100;
                    const valueTaxa = (price * feePercentage) / 100;
                    setResult(valueToCharge.toFixed(2).replace('.', ','));
                    setTaxaResult(valueTaxa.toFixed(2).replace('.', ','));
                } else if (calculationType === 'Receber') {
                    const valueToReceive = price;
                    const valueToCharge = valueToReceive / (1 - feePercentage / 100);
                    const valueTaxa = valueToCharge - valueToReceive;
                    setResult(valueToCharge.toFixed(2).replace('.', ','));
                    setTaxaResult(valueTaxa.toFixed(2).replace('.', ','));
                }
            } else {
                setResult('');
                setTaxaResult('');
            }
        }
    }, [calculationType, formValues.price]);

    return (
        <section id={styles.ServicesCreatePage}>
            <Container>
                <Row>
                    <p className={styles.title}>Novo Serviço</p>
                </Row>
                <Row className={styles.rowForm}>
                    <Form onSubmit={handleSubmit} className={styles.form}>
                        <Row>
                            <Col xs={12} md={6} className={styles.colPhoto}>
                                {formValues.photo && <Image src={URL.createObjectURL(formValues.photo)} className={styles.photo}/>}
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group controlId="photo">
                                    <Form.Label className={styles.formTitle}>Foto</Form.Label>
                                    <Form.Control
                                        type="file"
                                        className={styles.formControl}
                                        name="photo"
                                        onChange={handleChange}
                                        accept=".jpg,.jpeg,.png,.webp"
                                        isInvalid={!!errors.photo}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.photo}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group controlId="service">
                            <Form.Label className={styles.formTitle}>Nome do Serviço</Form.Label>
                            <Form.Control
                                type="text"
                                className={styles.formControl}
                                name="service"
                                value={formValues.service}
                                onChange={handleChange}
                                maxLength={100}
                                isInvalid={!!errors.service}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.service}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="description">
                            <Form.Label className={styles.formTitle}>Descrição</Form.Label>
                            <Form.Control
                                as="textarea"
                                className={styles.formControl}
                                name="description"
                                value={formValues.description}
                                onChange={handleChange}
                                maxLength={300}
                                isInvalid={!!errors.description}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.description}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Row>
                            <Col xs={12} sm={6} md={6} lg={3} xl={3}>
                                <Form.Group controlId="homeService">
                                    <Form.Label className={styles.formTitle}>Atendimento a Domicílio?</Form.Label>
                                    <Form.Check
                                        type="switch"
                                        id="homeServiceSwitch"
                                        name="homeService"
                                        label={formValues.homeService ? "Sim" : "Não"}
                                        checked={formValues.homeService}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={3} xl={3}>
                                <Form.Group controlId="is_onlineService">
                                    <Form.Label className={styles.formTitle}>Serviço Online?</Form.Label>
                                    <Form.Check
                                        type="switch"
                                        id="isOnlineServiceSwitch"
                                        name="is_onlineService"
                                        label={formValues.is_onlineService ? "Sim" : "Não"}
                                        checked={formValues.is_onlineService}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={3} xl={3}>
                                <Form.Group controlId="customquote">
                                    <Form.Label className={styles.formTitle}>Orçamento Personalizado?</Form.Label>
                                    <Form.Check
                                        type="switch"
                                        id="customQuoteSwitch"
                                        name="customquote"
                                        label={formValues.customquote ? "Sim" : "Não"}
                                        checked={formValues.customquote}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={3} xl={3}>
                            <Form.Group controlId="is_published">
                                    <Form.Label className={styles.formTitle}>Publicar?</Form.Label>
                                    <Form.Check
                                        type="switch"
                                        id="isPublishedSwitch"
                                        name="is_published"
                                        label={formValues.is_published ? "Publicado" : "Não Publicado"}
                                        checked={formValues.is_published}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        {errors.flags && (
                            <Alert variant="danger">
                                {errors.flags}
                            </Alert>
                        )}
                        <Row>
                            <Col xs={12} md={6}>
                                <Form.Group controlId="duration">
                                    <Form.Label className={styles.formTitle}>Duração</Form.Label>
                                    <Form.Select
                                        className={styles.formControl}
                                        name="duration"
                                        value={formValues.duration}
                                        onChange={handleChange}
                                        isInvalid={!!errors.duration}
                                    >
                                        {timeOptions.map((time, i) => (
                                            <option key={i} value={time}>{time}</option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.duration}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                {!formValues.customquote && (
                                    <Form.Group controlId="price">
                                        <Form.Label className={styles.formTitle}>Preço</Form.Label>
                                        <NumericFormat 
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            prefix="R$ "
                                            className={styles.formControl + " form-control"}
                                            value={formValues.price}
                                            onValueChange={(values) => {
                                                const cleanedValue = values.value
                                                    .replace(/[^0-9,.]/g, '')
                                                    .replace(/(\..*)\./g, '$1');
                                                handleChange({ target: { name: 'price', value: cleanedValue } });
                                            }}
                                            decimalScale={2}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.price}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            </Col>
                        </Row>
                        {!formValues.customquote && (
                            <Row className={styles.rowToggle}>
                                <p className={styles.textSimulator}>Simulador de cobrança</p>
                                <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <ToggleButtonGroup
                                        type="radio"
                                        name="calculationType"
                                        value={calculationType}
                                        onChange={handleCalculationTypeChange}
                                        className={styles.buttonToggleGroup}
                                    >
                                        <ToggleButton
                                            className={`${styles.buttonToggle} ${calculationType === "Cobrar" ? styles.active : ""}`}
                                            id="tbg-btn-1"
                                            value="Cobrar"
                                        >
                                            Quero cobrar
                                        </ToggleButton>
                                        <ToggleButton
                                            className={`${styles.buttonToggleDown} ${calculationType === "Receber" ? styles.active : ""}`}
                                            id="tbg-btn-2"
                                            value="Receber"
                                        >
                                            Quero receber
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Col>
                                <Col>
                                    <Row className={styles.rowCard}>
                                        <Card className={styles.cardValues}>
                                            <Row>
                                                <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                                                    <p className={styles.valuesTitle}>
                                                        {calculationType === 'Cobrar'
                                                            ? `Se o preço for:`
                                                            : `Para receber:`}
                                                    </p>
                                                </Col>
                                                <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                                    <p className={styles.values}>
                                                        R$ {formValues.price}
                                                    </p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                                                    <p>
                                                        Tarifa de processamento - {feePercentage} %
                                                    </p>
                                                </Col>
                                                <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                                    <p className={styles.taxaValues}>
                                                        - R$ {taxaResult}
                                                    </p>
                                                </Col>
                                            </Row>
                                            
                                            <Row>
                                                <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                                                    <p className={styles.valuesTitle}>
                                                        {calculationType === 'Cobrar'
                                                            ? `Você recebe:`
                                                            : `Você deveria cobrar: `}
                                                    </p>
                                                </Col>
                                                <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                                    <p className={styles.values}> R$ {result}</p>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Row>
                                </Col>
                            </Row>
                        )}
                        <Row>
                            <Col xs={12} md={6}>
                                <Form.Group controlId="categorias">
                                    <Form.Label className={styles.formTitle}>Categorias</Form.Label>
                                    <Select 
                                        options={categoriesOptions}
                                        placeholder="Selecione as Categorias"
                                        isClearable
                                        styles={customStyles}
                                        onChange={(selectedOption) => {
                                            const selectedId = selectedOption ? selectedOption.value : null;
                                            if (selectedId && !formValues.categorias.includes(selectedId)) {
                                                if (formValues.categorias.length >= 10) {
                                                    toast.error('Você pode selecionar no máximo 10 categorias.');
                                                    return;
                                                }
                                                setFormValues((prev) => ({
                                                    ...prev,
                                                    categorias: [...prev.categorias, selectedId]
                                                }));
                                            }
                                        }}
                                    />
                                    <div className={styles.selectedDrop}>
                                        {formValues.categorias.map((categoriaId) => {
                                            const categoria = categoriesData?.categories.find(c => c.id === categoriaId);
                                            return (
                                                <Button
                                                    key={categoriaId}
                                                    onClick={() => handleCategoryRemove(categoriaId)}
                                                    className={styles.selectedDrop}
                                                >
                                                    {categoria ? categoria.name : "Categoria Desconhecida"} &times;
                                                </Button>
                                            );
                                        })}
                                    </div>
                                    {errors.categorias && (
                                        <Alert variant="danger">
                                            {errors.categorias}
                                        </Alert>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group controlId="tags">
                                    <Form.Label className={styles.formTitle}>Tags</Form.Label>
                                    <Select 
                                        options={tagsOptions}
                                        placeholder="Selecione as Tags"
                                        isClearable
                                        styles={customStyles}
                                        onChange={(selectedOption) => {
                                            const selectedId = selectedOption ? selectedOption.value : null;
                                            if (selectedId && !formValues.tags.includes(selectedId)) {
                                                if (formValues.tags.length >= 10) {
                                                    toast.error('Você pode selecionar no máximo 10 categorias.');
                                                    return;
                                                }
                                                setFormValues((prev) => ({
                                                    ...prev,
                                                    tags: [...prev.tags, selectedId]
                                                }));
                                            }
                                        }}
                                    />
                                    <div className={styles.selectedDrop}>
                                        {formValues.tags.map((tagId) => {
                                            const tag = categoriesData?.services.find(t => t.id === tagId);
                                            return (
                                                <Button
                                                    key={tagId}
                                                    onClick={() => handleTagRemove(tagId)}
                                                    className={styles.selectedDrop}
                                                >
                                                    {tag?.name} &times;
                                                </Button>
                                            );
                                        })}
                                    </div>
                                    {errors.tags && (
                                        <Alert variant="danger">
                                            {errors.tags}
                                        </Alert>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className={styles.rowButton}>
                            <Col className={styles.colButtonCancelar}>
                                <Button className={styles.buttonCancelar} onClick={handleBack}>Cancelar</Button>
                            </Col>
                            <Col className={styles.colButtonSend}>
                                <Button className={styles.button} type="submit" disabled={isLoadingButton}>
                                    {isLoadingButton ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Salvar"}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Row>
            </Container>
        </section>
    );
}

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        marginTop: '5px',
        borderColor: '#EE880C',
        boxShadow: state.isFocused ? '0 0 5px 2px #EE880C' : provided.boxShadow,
        '&:hover': {
            borderColor: '#EE880C'
        }
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        '&:hover': {
            color: '#EE880C'
        }
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#390040' : state.isFocused ? '#EE880C' : provided.backgroundColor,
        color: state.isSelected ? '#fff' : state.isFocused ? '#fff' : provided.color,
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: '#EE880C',
            color: '#fff'
        }
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#390040',
    }),
};
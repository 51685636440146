import React, { useState, useEffect, useMemo } from 'react';
import { Container, Row, Col, Button, Form, InputGroup, Alert, Spinner, Modal } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { FaFilter, FaWhatsapp } from 'react-icons/fa';
import { format, setDefaultOptions, addMinutes, addDays, isBefore, isAfter, subDays, getDay } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import styles from "./style.module.scss";
import DatePagination from '../../components/datePagination';
import { useDaysSchedulesOneEmployeePartnerData, useEmployeeListPartnerData } from '../../api/PartnerAPI/partnerClient';
import { useAuthContext } from '../../contexts/authContext';

setDefaultOptions({ locale: ptBR });

const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

const generateTimeSlotsWithOccupied = (startTime, endTime, intervalMinutes, occupiedSlots) => {
    const timeSlots = [];
    let currentTime = new Date(startTime);

    const isOverlapping = (slotStart, slotEnd, occupiedSlot) => {
        return (
            (isBefore(slotStart, occupiedSlot.end) && isAfter(slotEnd, occupiedSlot.start)) ||
            (isBefore(slotStart, occupiedSlot.start) && isAfter(slotStart, occupiedSlot.start))
        );
    };

    while (currentTime < endTime) {
        const nextTime = addMinutes(currentTime, intervalMinutes);

        const overlappingOccupiedSlot = occupiedSlots.find((occupiedSlot) => {
            return isOverlapping(currentTime, nextTime, occupiedSlot);
        });

        if (overlappingOccupiedSlot) {
            const slotStart = new Date(overlappingOccupiedSlot.start);
            const slotEnd = new Date(overlappingOccupiedSlot.end);

            timeSlots.push({
                start: slotStart,
                end: slotEnd,
                occupied: true,
                serviceName: overlappingOccupiedSlot.service_name,
                serviceDescription: overlappingOccupiedSlot.service_description,
                servicePrice: overlappingOccupiedSlot.service_price,
                serviceIsCustomQuote: overlappingOccupiedSlot.service_is_custom_quote,
                serviceIsHome: overlappingOccupiedSlot.service_is_home,
                serviceIsOnline: overlappingOccupiedSlot.service_is_online,
                clientName: overlappingOccupiedSlot.client_name,
                clientCPF: overlappingOccupiedSlot.client_cpf,
                clientWhatsapp: overlappingOccupiedSlot.client_whatsapp,
                clientListName: overlappingOccupiedSlot.client_list_name,
                clientListCPF: overlappingOccupiedSlot.client_list_cpf,
                clientListWhatsapp: overlappingOccupiedSlot.client_list_whatsapp
            });

            currentTime = slotEnd;
        } else {
            const slotStart = new Date(currentTime);
            const slotEnd = new Date(nextTime);

            timeSlots.push({
                start: slotStart,
                end: slotEnd,
                occupied: false
            });

            currentTime = nextTime;
        }
    }

    return timeSlots;
};

const whatsappMessageText = (clientName, serviceName, startTime) => 
    `Olá ${clientName}, só para lembrar que você tem um agendamento para ${serviceName} no dia ${format(startTime, 'dd/MM/yyyy')} às ${format(startTime, 'HH:mm')}.`;

export default function SectionSchedulesGuru() {
    const { partnerSlug } = useParams();
    const { token } = useAuthContext();
    const [selectDate, setSelectDate] = useState(new Date());
    const [showCalendar, setShowCalendar] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const navigate = useNavigate();
    
    const [formattedDate, setFormattedDate] = useState(format(subDays(new Date(), 3), 'yyyy-MM-dd'));
    const [formattedEndDate, setFormattedEndDate] = useState(format(addDays(new Date(), 3), 'yyyy-MM-dd'));
    const [shouldFetchSchedules, setShouldFetchSchedules] = useState(false);

    const { data: employeeData, isLoading, isError } = useEmployeeListPartnerData(partnerSlug, token);
    const { data: schedulesData, refetch: refetchSchedules, isLoading: isLoadingSchedules, isError: isErrorSchedules } = useDaysSchedulesOneEmployeePartnerData(partnerSlug, formattedDate, formattedEndDate, selectedOption, token, shouldFetchSchedules);
    
    const handleDateChange = (date) => {
        setSelectDate(date);
        setShowCalendar(false);
    };

    useEffect(() => {
        if (selectedOption) {
            setShouldFetchSchedules(true);
        }
    }, [selectedOption]);

    useEffect(() => {
        if (!isLoading && !isError && employeeData?.length > 0) {
            setSelectedOption(employeeData[0].slug);
            setShouldFetchSchedules(true);
        }
    }, [employeeData, isLoading, isError]);

    useEffect(() => {
        const currentFormattedDate = format(subDays(selectDate, 3), 'yyyy-MM-dd');
        const currentFormattedEndDate = format(addDays(selectDate, 3), 'yyyy-MM-dd');

        if (selectDate < new Date(formattedDate) || selectDate > new Date(formattedEndDate)) {
            setFormattedDate(currentFormattedDate);
            setFormattedEndDate(currentFormattedEndDate);
            setShouldFetchSchedules(true);
        }
    }, [selectDate, formattedDate, formattedEndDate]);

    useEffect(() => {
        if (shouldFetchSchedules) {
            refetchSchedules();
            setShouldFetchSchedules(false);
        }
    }, [shouldFetchSchedules, formattedDate, formattedEndDate, refetchSchedules]);

    const getWorkingHoursForDay = (workingHours, dayOfWeek) => {
        const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
        return workingHours[days[dayOfWeek]];
    };

    const selectedEmployee = useMemo(() => {
        if (schedulesData?.employees) {
            return schedulesData.employees.find(employee => employee.id === selectedOption);
        }
        return null;
    }, [schedulesData, selectedOption]);

    const dayOfWeek = getDay(selectDate);
    const workingHours = selectedEmployee ? getWorkingHoursForDay(selectedEmployee.working_hours, dayOfWeek) : null;

    let startTime = new Date(selectDate);
    let endTime = new Date(selectDate);

    if (workingHours && workingHours.is_active) {
        const [startHour, startMinute] = workingHours.open_time.split(':');
        const [endHour, endMinute] = workingHours.close_time.split(':');
        
        startTime.setHours(parseInt(startHour, 10), parseInt(startMinute, 10), 0, 0);
        endTime.setHours(parseInt(endHour, 10), parseInt(endMinute, 10), 0, 0);
    } else {
        startTime.setHours(9, 0, 0, 0);
        endTime.setHours(17, 30, 0, 0);
    }

    const intervalMinutes = 30;
    const occupiedSlots = (schedulesData?.schedules || []).map((schedule) => ({
        service_name: schedule.service_name,
        service_description: schedule.service_description,
        service_price: schedule.service_price,
        service_is_custom_quote: schedule.service_is_custom_quote,
        service_is_home: schedule.service_is_home,
        service_is_online: schedule.service_is_online,
        client_name: schedule.client_name,
        client_cpf: schedule.client_cpf,
        client_whatsapp: schedule.client_whatsapp,
        client_list_name: schedule.client_list_name,
        client_list_cpf: schedule.client_list_cpf,
        client_list_whatsapp: schedule.client_list_whatsapp,
        start: new Date(schedule.start_time),
        end: new Date(schedule.end_time),
    }));

    const timeSlots = generateTimeSlotsWithOccupied(startTime, endTime, intervalMinutes, occupiedSlots);

    const handleWhatsappClick = (clientWhatsapp, clientName, serviceName, startTime) => {
        const cleanedWhatsapp = clientWhatsapp.replace(/\D/g, '').slice(-11);
        const message = whatsappMessageText(clientName, serviceName, startTime);
        const whatsappUrl = `https://wa.me/${cleanedWhatsapp}?text=${encodeURIComponent(message)}`;
        window.open(whatsappUrl, '_blank');
    };

    const filteredSchedulesCount = schedulesData?.schedules
        ? schedulesData.schedules.filter(schedule => {
            const scheduleDate = new Date(schedule.start_time);
            return (
                scheduleDate.toDateString() === selectDate.toDateString()
            );
        }).length
        : 0;
    
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);

    const handleSlotClick = (slot) => {
        if (slot.occupied) {
            setModalData(slot);
            setShowModal(true);
        }
    };

    const handleCloseModal = () => setShowModal(false);
    
    if (isLoadingSchedules) {
        return (
            <section id={styles.SectionCompanyGuru}>
                <Container>
                    <Row>
                        <Col className="text-center">
                            <Spinner animation="border" role="status">
                            </Spinner>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }

    if (isErrorSchedules) {
        return (
            <section id={styles.SectionCompanyGuru}>
                <Container>
                    <Row>
                        <Col>
                            <Alert variant="danger">
                                Erro ao carregar os dados. Por favor, tente novamente mais tarde.
                            </Alert>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }

    return (
        <section id={styles.SectionSchedulesGuru}>
            <Container>
                <Row>
                    <p className={styles.title}>Meus Agendamentos</p>
                </Row>
                {employeeData && employeeData.length > 0 ?(
                    <>
                    
                        <Row className={styles.rowButton}>
                            <Col className={styles.colWithCalendar}>
                                <Button
                                    className={styles.buttonDate}
                                    onClick={() => setShowCalendar(!showCalendar)}
                                >
                                    <FaFilter className={styles.icon}/> Selecionar data
                                </Button>
                                {showCalendar && (
                                    <div className={styles.calendarWrapper}>
                                        <Calendar
                                            onChange={handleDateChange}
                                            value={selectDate}
                                            locale="pt-BR"
                                            className={styles.datePicker}
                                        />
                                    </div>
                                )}
                            </Col>
                            <Col className={styles.colButton}>
                                <Button
                                    className={styles.buttonNew}
                                    onClick={() => navigate(`/${partnerSlug}/agendamentosGuru/criar`)}
                                >
                                    Novo Agendamento
                                </Button>
                            </Col>
                        </Row>
                        <Row className={styles.rowResumeSchedules}>
                            <Col xs={10} sm={10} md={10} lg={11} xl={11} className={styles.colSchedules}>
                                <p className={styles.titleSchedules}>Agendamentos</p>
                                <p className={styles.textSchedules}>{capitalizeFirstLetter(format(selectDate, 'EEEE, d \'de\' MMMM \'de\' yyyy', { locale: ptBR }))}</p>
                            </Col>
                            <Col xs={2} sm={2} md={2} lg={1} xl={1} className={styles.colCounter}>
                                <p className={styles.counter}>
                                    {filteredSchedulesCount}
                                </p>
                            </Col>
                        </Row>
                        <Row className={styles.rowDropdown}>
                            <InputGroup>
                                <InputGroup.Text className={styles.inputIcon}><FaFilter className={styles.icon}/></InputGroup.Text>
                                <Form.Select
                                    value={selectedOption}
                                    onChange={(e) => setSelectedOption(e.target.value)}
                                    aria-label="Selecionar opção"
                                    className={styles.select}
                                >
                                    {!isLoading && !isError && employeeData?.map((employee) => (
                                        <option key={employee.id} value={employee.id} className={styles.selectText}>
                                            {employee.name}
                                        </option>
                                    ))}
                                </Form.Select>
                            </InputGroup>
                        </Row>
                        <Row>
                            <DatePagination startDate={selectDate} onDateChange={handleDateChange}/>
                        </Row>
                        <Row className={styles.rowTable}>
                            <Col xs={2} sm={2} md={2} lg={2} xl={2} className={styles.colTable}>
                                <p className={styles.text}>Horário</p>
                            </Col>
                            <Col xs={3} sm={3} md={3} lg={3} xl={3} className={styles.colTable}>
                                <p className={styles.text}>Serviço</p>
                            </Col>
                            <Col xs={4} sm={4} md={4} lg={4} xl={4} className={styles.colTable}>
                                <p className={styles.text}>Cliente</p>
                            </Col>
                            <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                            </Col>
                        </Row>
                        {timeSlots.map((slot, index) => (
                            <Row key={index} className={`${styles.rowTableSchedule} ${slot.occupied ? styles.rowTableScheduleOccupied : styles.rowTableScheduleAvailable}`}
                                onClick={() => handleSlotClick(slot)}
                            >
                                <Col xs={12} sm={2} md={2} lg={2} xl={2} className={`${styles.colTableSchedule} ${slot.occupied ? styles.colTableScheduleOccupied : styles.colTableScheduleAvailable}`}>
                                    <p className={styles.text}>
                                        {format(slot.start, 'HH:mm')} às {format(slot.end, 'HH:mm')}
                                    </p>
                                </Col>
                                <Col xs={4} sm={3} md={3} lg={3} xl={3} className={styles.colTableScheduleText}>
                                    <p className={styles.text}>{slot.occupied ? slot.serviceName : "Livre"}</p>
                                </Col>
                                <Col xs={4} sm={4} md={4} lg={4} xl={4} className={styles.colTableScheduleText}>
                                    <p className={styles.text}>{slot.occupied ? (slot.clientName || slot.clientListName) : ""}</p>
                                </Col>
                                <Col xs={4} sm={3} md={3} lg={3} xl={3} className={styles.colButtonWhats}>
                                    {slot.occupied && (
                                        <Button 
                                            className={styles.buttonWhats}
                                            onClick={() => handleWhatsappClick((slot.clientWhatsapp || slot.clientListWhatsapp), (slot.clientName || slot.clientListName), slot.serviceName, slot.start)}
                                        >
                                            <FaWhatsapp className={styles.icon}/> Enviar Lembrete
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                        ))}
                    </>
                ):(
                    <>
                        <Col className="text-center">
                            <Alert variant="danger">
                                Sem colaboradores! Por favor, crie um colaborador e tente novamente!
                            </Alert>
                        </Col>
                    </>
                )}
                {modalData && (
                    <Modal show={showModal} onHide={handleCloseModal} centered size="lg" className={styles.modal}>
                        <Modal.Header closeButton className={styles.modalHeader}>
                            <Modal.Title className={styles.title}>Detalhes do Agendamento</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className={styles.modalBody}>
                            <p className={styles.text}><strong>Serviço:</strong> {modalData.serviceName}</p>
                            <p className={styles.text}><strong>Descrição:</strong> {modalData.serviceDescription}</p>
                            {!modalData.serviceIsCustomQuote && (
                                <p className={styles.text}><strong>Preço:</strong> {formatPrice(modalData.servicePrice)}</p>
                            )}
                            {modalData.serviceIsCustomQuote && (
                                <p className={styles.text}><strong>Preço:</strong> Orçamento Personalizado</p>
                            )}
                            {modalData.serviceIsOnline && (
                                <p className={styles.text}><strong>Tipo de Atendimento:</strong> Online</p>
                            )}
                            {modalData.serviceIsHome && (
                                <p className={styles.text}><strong>Tipo de Atendimento:</strong> Domicílio</p>
                            )}
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <p className={styles.text}><strong>Início:</strong> {format(modalData.start, 'HH:mm')}hrs</p>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <p className={styles.text}><strong>Término:</strong> {format(modalData.end, 'HH:mm')}hrs</p>
                                </Col>
                            </Row>
                            <Row className={styles.rowCliente}>
                                <p className={styles.title}>Dados do Cliente:</p>
                                <p className={styles.text}><strong>Nome Completo:</strong> {modalData.clientName || modalData.clientListName}</p>
                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <p className={styles.text}><strong>CPF:</strong> {maskCPF(modalData.clientCPF || modalData.clientListCPF)}</p>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <p className={styles.text}><strong>WhatsApp:</strong> {maskWhatsApp(modalData.clientWhatsapp || modalData.clientListWhatsapp)}</p>

                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer className={styles.modalFooter}>
                            <Button className={styles.buttonExit} onClick={handleCloseModal}>Fechar</Button>
                        </Modal.Footer>
                    </Modal>
                )}
            </Container>
        </section>
    );
}

const maskCPF = (cpf) => {
    if (!cpf) return 'Não informado';
    return cpf.replace(/(\d{3})\.\d{3}\.\d{3}(-\d{2})/, '$1.***.***$2');
};

const maskWhatsApp = (whatsapp) => {
    if (!whatsapp) return 'Não informado';
    const cleaned = whatsapp.replace(/\D/g, '');
    return cleaned.replace(/(\d{2})(\d{2})\d{5}(\d{4})/, '+$1 ($2) *****-$3');
};

const formatPrice = (value) => {
    if (!value) return '-';
    const number = parseFloat(value);
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(number);
};
import { useQuery } from "@tanstack/react-query";
import { BASE_URL, AUTH_DEBUG } from "../default";

const getOneDocumentGuru = async (documentSlug) => {
    const url = `${BASE_URL}/files/documents/pdf/${documentSlug}/guru/`;

    const options = {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    };

    const response = await fetch(url, options);
    if (response.ok) {
        const data = await response.json();
        AUTH_DEBUG && console.log("BlogGuruAPI::getListCategoriesGuru(): ", data);
        return data;
    } else {
        throw new Error("Error on getListCategoriesGuru()");
    }
};

export function useOneDocumentGuruData(documentSlug) {
    const query = useQuery({
        queryKey: ['document', documentSlug],
        queryFn: () => getOneDocumentGuru(documentSlug),
        staleTime: 1000 * 60 * 10,
        cacheTime: 1000 * 60 * 10,
        enabled: !!documentSlug,
    });

    return {
        ...query,
        docData: query.data,
    };
}

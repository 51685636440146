import React, { useMemo, useState, useEffect } from 'react';
import { Col, Container, Row, Button, Form, ToggleButtonGroup, ToggleButton, Card, InputGroup, Tabs, Tab, Alert, Spinner } from 'react-bootstrap';
import styles from './style.module.scss';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { useClientPartnerData, usePostClientPartner, usePostOrderPaymentPartner, useResumeServicesProvidedData } from '../../../api/PartnerAPI/partnerClient';
import { useAuthContext } from '../../../contexts/authContext';
import { NumericFormat } from 'react-number-format';
import MaskedInput from 'react-text-mask';
import { FaSearch } from 'react-icons/fa';
import { isValidCPF } from '../../../utils/utils';
import { toast } from 'react-toastify';

export default function FinancialCreatePage() {
    const { partnerSlug } = useParams();
    const { token, user } = useAuthContext();
    const navigate = useNavigate();

    const [keyTabs, setKeyTabs] = useState('cliente');
    const [showAlert, setShowAlert] = useState(false);
    const [isLoadingButton, setIsLoadingButton] = useState(false);

    const { servicesData } = useResumeServicesProvidedData(partnerSlug, token);
    const [selectedOption, setSelectedOption] = useState('');
    const [formValues, setFormValues] = useState({
        service: '',
        description: '',
        price: '',
        homeService: false,
        is_onlineService: false,
        is_published: false,
    });

    const [calculationType, setCalculationType] = useState('Cobrar');
    const [result, setResult] = useState('');
    const [taxaResult, setTaxaResult] = useState('');
    const feePercentage = 9.98;

    const servicesOptions = useMemo(() => [
        ...servicesData?.map(categories => ({
            value: categories.id,
            label: categories.name
        })) || []
    ], [servicesData]);

    const handleOptionChange = (e) => {
        const selectedId = e.target.value;
        setSelectedOption(selectedId);
    
        if (selectedId === 'new') {
            setFormValues({
                ...formValues,
                service: '',
                description: '',
                price: 0
            });
        } else {
            const selectedService = servicesData.find(service => service.id === selectedId);
            if (selectedService) {
                setFormValues({
                    ...formValues,
                    service: selectedService.name,
                    description: selectedService.description,
                    price: selectedService.price ? selectedService.price : ''
                });
            } else {
                setFormValues({ ...formValues, service: '', description: '', price: '' });
            }
        }
    };

    useEffect(() => {
        if (selectedOption === 'new') {
            setFormValues({
                service: '',
                description: '',
                price: 0,
                homeService: false,
                is_onlineService: false,
                is_published: false,
            });
        }
    }, [selectedOption]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value
        });
    };

    const handleCalculationTypeChange = (val) => {
        setCalculationType(val);
        setResult('');
    };

    useEffect(() => {
        if (calculationType && formValues.price) {
            const cleanedPrice = formValues.price.replace(/[R$\s]/g, '').replace(',', '.');
            const price = parseFloat(cleanedPrice);
    
            if (!isNaN(price)) {
                if (calculationType === 'Cobrar') {
                    const valueToCharge = price - (price * feePercentage) / 100;
                    const valueTaxa = (price * feePercentage) / 100;
                    setResult(valueToCharge.toFixed(2).replace('.', ','));
                    setTaxaResult(valueTaxa.toFixed(2).replace('.', ','));
                } else if (calculationType === 'Receber') {
                    const valueToReceive = price;
                    const valueToCharge = valueToReceive / (1 - feePercentage / 100);
                    const valueTaxa = valueToCharge - valueToReceive;
                    setResult(valueToCharge.toFixed(2).replace('.', ','));
                    setTaxaResult(valueTaxa.toFixed(2).replace('.', ','));
                }
            } else {
                setResult('');
                setTaxaResult('');
            }
        }
    }, [calculationType, formValues.price]);

    const handleNextTab = (currentKey) => {
        const keys = ['cliente', 'servico'];
        const currentIndex = keys.indexOf(currentKey);
        const nextKey = keys[currentIndex + 1];
        
        if (currentKey === 'servico' && !selectedOption) {
            setAlertMessage("Por favor, selecione um serviço antes de continuar.");
            setShowAlert(true);
        } else if (currentIndex < keys.length - 1) {
            setShowAlert(false);
            setKeyTabs(nextKey);
        }
    };

    const handlePrevTab = (currentKey) => {
        const keys = ['cliente', 'servico'];
        const currentIndex = keys.indexOf(currentKey);
        if (currentIndex > 0) {
            setKeyTabs(keys[currentIndex - 1]);
        }
    };
    
    const [clientCPF, setClientCPF] = useState('');
    const [shouldFetchClient, setShouldFetchClient] = useState(false);
    const { ClientData, isError: isErrorClient, isSuccess: isSuccessClient } = useClientPartnerData(partnerSlug,'', clientCPF, token, shouldFetchClient);
    const [errors, setErrors] = useState({});
    
    const [initialValues] = useState({
        id: '',
        name: '',
        email: '',
        cpf: '',
        phone: '',
        address: '',
    });

    const cpfMask = [/[0-9]/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
    const whatsMask = ['+', '5', '5', ' ', '(', /[0-9]/, /\d/, ')', ' ', /[0-9]/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

    const [formValuesClient, setFormValuesClient] = useState(initialValues);
    const [originalFormValues, setOriginalFormValues] = useState(initialValues);
    const [alertMessage, setAlertMessage] = useState('');
    
    const { mutate: postClientPartner, data: postClientData} = usePostClientPartner(partnerSlug, formValuesClient.email);

    useEffect(() => {
        if (ClientData) {
            const newFormValues = {
                id: ClientData.id || '',
                name: ClientData.name || '',
                email: ClientData.email || '',
                cpf: ClientData.cpf || '',
                phone: ClientData.phone || '',
                address: ClientData.address || '',
            };
            setFormValuesClient(newFormValues);
            setOriginalFormValues(newFormValues);
        }
    }, [ClientData]);

    useEffect(() => {
        if (postClientData) {
            const newFormValues = {
                id: postClientData.id || '',
                name: postClientData.name || '',
                email: postClientData.email || '',
                cpf: postClientData.cpf || '',
                phone: postClientData.phone || '',
                address: postClientData.address || '',
            };
            setFormValuesClient(newFormValues);
        }
    }, [postClientData]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValuesClient((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };
    
    const handleEmailSearch = () => {
        setAlertMessage('');
        setShowAlert(false);

        if (!clientCPF || !/^\d{3}\.\d{3}\.\d{3}-\d{2}$/.test(clientCPF)) {
            setAlertMessage("Por favor, insira um CPF válido no formato XXX.XXX.XXX-XX.");
            setShowAlert(true);
            setShouldFetchClient(false);
            return;
        }
        setShouldFetchClient(true);
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formValuesClient.name) newErrors.name = 'O nome do cliente é obrigatório';
        if (!/\S+@\S+\.\S+/.test(formValuesClient.email)) newErrors.email = 'O email é inválido';
        if (!formValuesClient.cpf) {
            newErrors.cpf = "CPF é obrigatório.";
        } else if (!isValidCPF(formValuesClient.cpf.replace(/\D/g, ''))) {
            newErrors.cpf = "CPF inválido.";
        }
        if (!formValuesClient.phone) {
            newErrors.phone = "WhatsApp é obrigatório.";
        } else if (formValuesClient.phone.replace(/\D/g, '').length < 11) {
            newErrors.phone = "WhatsApp deve ter pelo menos 11 dígitos.";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleFormSubmitClient = (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        const formData = new FormData(e.target);
        formData.append('partner', partnerSlug);
        formData.append('name', formValuesClient.name);
        formData.append('email', formValuesClient.email);
        formData.append('cpf', formValuesClient.cpf);
        formData.append('phone', formValuesClient.phone);
        formData.append('address', formValuesClient.address);

        if (JSON.stringify(formValuesClient) !== JSON.stringify(originalFormValues)) {
            postClientPartner({ formData, partnerSlug, token });
            if(postClientPartner){
                toast.success('Dados do cliente atualizados com sucesso!.');
                setOriginalFormValues(formValuesClient);
                handleNextTab('cliente');
            }
            else{
                toast.error('Erro ao atualizar dados do cliente, confira os dados e tente novamente!');
            }
        } else {
            handleNextTab('cliente');
            toast.info('Nenhuma alteração de dados detectada.');
        }
    };

    useEffect(() => {
        if(isSuccessClient || isErrorClient){
            setShowAlert(false);
            setShouldFetchClient(false);
        }
        if(isErrorClient){
            setShouldFetchClient(false);
            setFormValuesClient(initialValues);
        }
    }, [isSuccessClient, isErrorClient, initialValues]);

    const { mutate: postOrderPaymentPartner, isError: isErrorSend, isSuccess: isSuccessSend } = usePostOrderPaymentPartner(partnerSlug, formValuesClient.id);

    const validateFormService = () => {
        const newErrors = {};
        if (!formValues.service) newErrors.service = 'O nome do serviço é obrigatório';
        if (!formValues.description) newErrors.description = 'A descrição do serviço é obrigatório';
        if (!formValues.price) newErrors.price = 'O preço do serviço é obrigatório';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleFormSubmitPaymentOrder = (e) => {
        e.preventDefault();

        if (!validateFormService()) {
            return;
        }

        setIsLoadingButton(true);

        const formData = new FormData();
        // formData.append('partner', partnerSlug);
        formData.append('employee', user.id);
        formData.append('clientList', formValuesClient.id);
        formData.append('serviceProvided', selectedOption);
        formData.append('name', formValues.service);
        formData.append('description', formValues.description);
        formData.append('price', formValues.price);
        formData.append('status', 'pending');

        postOrderPaymentPartner({partnerSlug, formData, token });
    };

    useEffect(() => {
        if(isErrorSend){
            setIsLoadingButton(false);
            toast.error('Erro ao criar cobrança, confira os dados do cliente ou serviço e tente novamente!');
        }
        if(isSuccessSend){
            toast.success('Cobrança realizada com sucesso!.');
            setIsLoadingButton(false);
            navigate(`/${partnerSlug}/financeiro/`);
            setFormValuesClient(initialValues)
        }
    }, [ isErrorSend, isSuccessSend, initialValues, partnerSlug, navigate ]);

    return (
        <HelmetProvider>
            <section id={styles.FinancialCreatePage}>
                <Helmet>
                    <title>Criar Cobrança - GoinGuru</title>
                </Helmet>
                <Container>
                    <Row>
                        <p className={styles.title}>Nova Cobrança</p>
                    </Row>
                    <Tabs activeKey={keyTabs} onSelect={(k) => setKeyTabs(k)} id="controlled-tab-example" className="mb-3">
                        <Tab eventKey="cliente" title="Cliente" disabled>
                            <Row className={styles.rowClientData}>
                                <p className={styles.title}>Utilizar dados da sua Lista de Clientes:</p>
                                <InputGroup>
                                    <MaskedInput
                                        mask={cpfMask}
                                        className={styles.formControl + " form-control"}
                                        value={clientCPF}
                                        placeholder="Buscar cliente pelo CPF"
                                        onChange={(e) => setClientCPF(e.target.value)}
                                        render={(ref, props) => (
                                            <Form.Control
                                                ref={ref}
                                                {...props}
                                                isInvalid={!!errors.cpf}
                                            />
                                        )}
                                    />
                                    <Button variant="outline-secondary" className={styles.inputControl} onClick={handleEmailSearch}>
                                        <FaSearch className={styles.icon} />
                                    </Button>
                                </InputGroup>
                            </Row>
                            {showAlert && (
                                <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible className='mt-4'>
                                    {alertMessage}
                                </Alert>
                            )}
                            <Row className={styles.rowClientData}>
                                <Form className={styles.form} noValidate onSubmit={handleFormSubmitClient}>
                                    <Row>
                                        <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                                            <Form.Group controlId="formFullName">
                                                <Form.Label className={styles.formLabel}>Nome Completo</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="name"
                                                    value={formValuesClient.name}
                                                    onChange={handleInputChange}
                                                    isInvalid={!!errors.name}
                                                    className={styles.formControl}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.name}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                                            <Form.Group controlId="formEmail">
                                                <Form.Label className={styles.formLabel}>E-mail</Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    name="email"
                                                    value={formValuesClient.email}
                                                    onChange={handleInputChange}
                                                    isInvalid={!!errors.email}
                                                    className={styles.formControl}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.email}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                                            <Form.Group controlId="formCPF">
                                                <Form.Label className={styles.formLabel}>CPF</Form.Label>
                                                <MaskedInput
                                                    mask={cpfMask}
                                                    className={styles.formControl + " form-control"}
                                                    value={formValuesClient.cpf}
                                                    onChange={(e) => setFormValuesClient({ ...formValuesClient, cpf: e.target.value })}
                                                    render={(ref, props) => (
                                                        <Form.Control
                                                            ref={ref}
                                                            {...props}
                                                            isInvalid={!!errors.cpf}
                                                        />
                                                    )}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.cpf}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                                            <Form.Group controlId="formphone">
                                                <Form.Label className={styles.formLabel}>WhatsApp</Form.Label>
                                                <MaskedInput
                                                    mask={whatsMask}
                                                    className={styles.formControl + " form-control"}
                                                    value={formValuesClient.phone}
                                                    onChange={(e) => setFormValuesClient({ ...formValuesClient, phone: e.target.value })}
                                                    render={(ref, props) => (
                                                        <Form.Control
                                                            ref={ref}
                                                            {...props}
                                                            isInvalid={!!errors.phone}
                                                        />
                                                    )}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.phone}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12}>
                                            <Form.Group controlId="formAddress">
                                                <Form.Label className={styles.formLabel}>Endereço</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="address"
                                                    value={formValuesClient.address}
                                                    onChange={handleInputChange}
                                                    className={styles.formControl}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className={styles.RowButtonNextBack}>
                                        <Col className={styles.colButtonClient}>
                                            <Button className={styles.buttonClient} type="submit">Próximo</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Row>
                        </Tab>
                        <Tab eventKey="servico" title="Serviço" disabled>
                            <Row className={styles.rowForm}>
                                <Form onSubmit={handleFormSubmitPaymentOrder}>
                                    <Row>
                                        <Form.Group>
                                            <Select
                                                options={servicesOptions}
                                                value={servicesOptions.find(option => option.value === selectedOption)}
                                                onChange={(selected) => handleOptionChange({ target: { value: selected.value } })}
                                                styles={customStyles}
                                                placeholder="Selecione o serviço"
                                            />
                                        </Form.Group>
                                    </Row>
                                    {selectedOption ? (
                                        <>
                                            <Row>
                                                <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                                                    <Form.Group>
                                                        <Form.Label className={styles.titleForm}>Nome do Serviço</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="service"
                                                            value={formValues.service}
                                                            onChange={handleChange}
                                                            placeholder="Digite o nome do serviço"
                                                            className={styles.formControl}
                                                            isInvalid={!!errors.service}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.service}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                                    <Form.Group>
                                                        <Form.Label className={styles.titleForm}>Preço</Form.Label>
                                                        <NumericFormat
                                                            thousandSeparator="."
                                                            decimalSeparator=","
                                                            prefix="R$ "
                                                            className={styles.formControl + " form-control"}
                                                            value={formValues.price}
                                                            onValueChange={(values) => {
                                                                const cleanedValue = values.value
                                                                    .replace(/[^0-9,.]/g, '')
                                                                    .replace(/(\..*)\./g, '$1');
                                                                handleChange({ target: { name: 'price', value: cleanedValue } });
                                                            }}
                                                            decimalScale={2}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                                                    <Form.Group>
                                                        <Form.Label className={styles.titleForm}>Descrição</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            name="description"
                                                            value={formValues.description}
                                                            onChange={handleChange}
                                                            placeholder="Digite a descrição do serviço"
                                                            className={styles.formControl}
                                                            isInvalid={!!errors.description}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.description}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} sm={12} md={7} lg={7} xl={7}>
                                                    <Row className={styles.rowToggle}>
                                                        <p className={styles.textSimulator}>Simulador de cobrança</p>
                                                        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                                            <ToggleButtonGroup
                                                                type="radio"
                                                                name="calculationType"
                                                                value={calculationType}
                                                                onChange={handleCalculationTypeChange}
                                                                className={styles.buttonToggleGroup}
                                                            >
                                                                <ToggleButton
                                                                    className={`${styles.buttonToggle} ${calculationType === "Cobrar" ? styles.active : ""}`}
                                                                    id="tbg-btn-1"
                                                                    value="Cobrar"
                                                                >
                                                                    Quero cobrar
                                                                </ToggleButton>
                                                                <ToggleButton
                                                                    className={`${styles.buttonToggleDown} ${calculationType === "Receber" ? styles.active : ""}`}
                                                                    id="tbg-btn-2"
                                                                    value="Receber"
                                                                >
                                                                    Quero receber
                                                                </ToggleButton>
                                                            </ToggleButtonGroup>
                                                        </Col>
                                                        <Col>
                                                            <Row className={styles.rowCard}>
                                                                <Card className={styles.cardValues}>
                                                                    <Row>
                                                                        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                                                                            <p className={styles.valuesTitle}>
                                                                                {calculationType === 'Cobrar'
                                                                                    ? `Se o preço for:`
                                                                                    : `Para receber:`}
                                                                            </p>
                                                                        </Col>
                                                                        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                                                            <p className={styles.values}>
                                                                                R$ {formValues.price}
                                                                            </p>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                                                                            <p>
                                                                                Tarifa de processamento - {feePercentage} %
                                                                            </p>
                                                                        </Col>
                                                                        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                                                            <p className={styles.taxaValues}>
                                                                                - R$ {taxaResult}
                                                                            </p>
                                                                        </Col>
                                                                    </Row>
                                                                    
                                                                    <Row>
                                                                        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                                                                            <p className={styles.valuesTitle}>
                                                                                {calculationType === 'Cobrar'
                                                                                    ? `Você recebe:`
                                                                                    : `Você deveria cobrar: `}
                                                                            </p>
                                                                        </Col>
                                                                        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                                                            <p className={styles.values}> R$ {result}</p>
                                                                        </Col>
                                                                    </Row>
                                                                </Card>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row className={styles.RowButtonNextBack}>
                                                <Col className={styles.colButtonBack}>
                                                    <Button className={styles.buttonPrev} onClick={() => handlePrevTab(keyTabs)}>Voltar</Button>
                                                </Col>
                                                <Col className={styles.colButtonNext}>
                                                    <Button className={styles.buttonNext} type="submit" disabled={isLoadingButton}>
                                                        {isLoadingButton ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Salvar"}
                                                    </Button>
                                                </Col>
                                            </Row>                   
                                        </>
                                    ) : (
                                        <Alert variant="danger" className='mt-4'>
                                            Selecione um serviço para concluir a cobrança!
                                        </Alert>
                                    )}
                                </Form>
                            </Row>
                        </Tab>
                    </Tabs>
                </Container>
            </section>
        </HelmetProvider>
    );
}

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        borderColor: '#EE880C',
        boxShadow: state.isFocused ? '0 0 5px 2px #EE880C' : provided.boxShadow,
        '&:hover': {
            borderColor: '#EE880C'
        }
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        '&:hover': {
            color: '#EE880C'
        }
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#390040' : state.isFocused ? '#EE880C' : provided.backgroundColor,
        color: state.isSelected ? '#fff' : state.isFocused ? '#fff' : provided.color,
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: '#EE880C',
            color: '#fff'
        }
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#390040',
    }),
};

import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Container, Row, Col, Form, Button, Image, Spinner, Alert } from 'react-bootstrap';
import styles from './style.module.scss';
import Select from 'react-select';
import MaskedInput from 'react-text-mask';
import { useParams } from 'react-router-dom';
import { useAuthContext } from '../../contexts/authContext';
import { useOnePartnerData, useCategoriesAndServicesPartnerData, usePostOnePartner } from '../../api/PartnerAPI/partnerClient';
import { toast } from 'react-toastify';
import SectionCompanyGuruAddress from '../sectionCompanyGuruAddress';
import { isValidCPF } from '../../utils/utils';

export default function SectionCompanyGuru() {
    const { partnerSlug } = useParams();
    const { token } = useAuthContext();
    const { data, isLoading, isError } = useOnePartnerData(partnerSlug, token);
    const { categoriesData, isError: isErrorCategories, isLoading: isCategories } = useCategoriesAndServicesPartnerData(token);
    const { mutate: postOnePartner, isSuccess: isSuccessPost, isError: isErrorPost, error } = usePostOnePartner(partnerSlug);

    const cpfMask = [/[0-9]/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
    const cnpjMask = [/[0-9]/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];
    const phoneMask = ['+', '5', '5', ' ', '(', /[0-9]/, /\d/, ')', ' ', /[0-9]/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    const whatsMask = ['+', '5', '5', ' ', '(', /[0-9]/, /\d/, ')', ' ', /[0-9]/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

    const [formValues, setFormValues] = useState({
        banner: null,
        empresa: '',
        email: '',
        isCNPJ: false,
        cpf: '',
        cnpj: '',
        phone: '',
        whatsapp: '',
        categorias: [],
        tags: [],
        sobre: ''
    });
    const [isLoadingButton, setIsLoadingButton] = useState(false);
    const [originalValues, setOriginalValues] = useState({});
    const [errors, setErrors] = useState({});
    const [isEditing, setIsEditing] = useState(false);

    const validateForm = () => {
        const newErrors = {};
        if (!formValues.empresa) newErrors.empresa = 'O nome da empresa é obrigatório';
        if (!formValues.email) newErrors.email = 'O email é obrigatório';
        if (!/\S+@\S+\.\S+/.test(formValues.email)) newErrors.email = 'O email é inválido';
        if (formValues.isCNPJ) {
            if (!formValues.cnpj) newErrors.cnpj = 'O CNPJ é obrigatório!';
        } else {
            if (!formValues.cpf) {
                newErrors.cpf = "CPF é obrigatório.";
            } else if (!isValidCPF(formValues.cpf.replace(/\D/g, ''))) {
                newErrors.cpf = "CPF inválido.";
            }
        }
        if (!formValues.phone) {
            newErrors.phone = "O telefone é obrigatório!";
        } else if (formValues.phone.replace(/\D/g, '').length < 10) {
            newErrors.phone = "O telefone deve ter pelo menos 10 dígitos.";
        }
        if (!formValues.whatsapp) {
            newErrors.whatsapp = "WhatsApp é obrigatório.";
        } else if (formValues.whatsapp.replace(/\D/g, '').length < 11) {
            newErrors.whatsapp = "WhatsApp deve ter pelo menos 11 dígitos.";
        }
        if (!formValues.sobre) newErrors.sobre = 'O campo sobre é obrigatório';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        
        if (!validateForm()) {
            return;
        }

        const changedValues = {};
        Object.keys(formValues).forEach(key => {
            if (JSON.stringify(formValues[key]) !== JSON.stringify(originalValues[key])) {
                changedValues[key] = formValues[key];
            }
        });
        setIsLoadingButton(true);
        
        const formData = new FormData();
    
        if (changedValues.banner) formData.append('photo', changedValues.banner);
        if (changedValues.empresa) formData.append('name', changedValues.empresa);
        if (changedValues.email) formData.append('email', changedValues.email);
        if (changedValues.isCNPJ !== undefined) formData.append('is_cnpj', changedValues.isCNPJ);
        if (changedValues.cnpj) formData.append('cnpj', changedValues.cnpj);
        if (changedValues.cpf) formData.append('cpf', changedValues.cpf);
        if (changedValues.phone) formData.append('phone', changedValues.phone);
        if (changedValues.whatsapp) formData.append('whatsapp', changedValues.whatsapp);
        if (changedValues.categorias) changedValues.categorias.forEach(categoria => formData.append('categories_partners', categoria));
        if (changedValues.tags) changedValues.tags.forEach(tag => formData.append('services_partners', tag));
        if (changedValues.sobre) formData.append('about', changedValues.sobre);

        if (Object.keys(changedValues).length === 0) {
            handleCancel();
            return;
        }

        postOnePartner({ formData, partnerSlug, token }); 
    };
    
    const handleCancel = useCallback(() => {
        setFormValues(originalValues);
        setIsEditing(false);
    }, [originalValues]);    

    useEffect(() => {
        if (isErrorPost && error) {
            const errorMessage = error.message.toLowerCase();
            if (errorMessage.includes('cpf')) {
                toast.error('CPF inválido ou já cadastrado.');
                setIsLoadingButton(false);
            } 
            if (errorMessage.includes('email')) {
                toast.error('Email inválido ou já cadastrado.');
                setIsLoadingButton(false);
            }
            if (errorMessage.includes('cnpj')) {
                toast.error('CNPJ inválido ou já cadastrado.');
                setIsLoadingButton(false);
            } 
            if (!errorMessage.includes('cpf') && 
                !errorMessage.includes('email') && 
                !errorMessage.includes('cnpj')) {
                toast.error(`Erro ao editar Perfil da Empresa, tente novamente ou entre em contato com o suporte!`);
                setIsLoadingButton(false);
            }
        } else if (isSuccessPost) {
            toast.success('Dados alterados com sucesso!.');
            setIsLoadingButton(false);
            handleCancel();
        }
    }, [isErrorPost, isSuccessPost, error, handleCancel]);

    useEffect(() => {
        if (data) {
            const initialValues = {
                banner: data.photo,
                empresa: data.name,
                email: data.email || '',
                isCNPJ: data.is_cnpj,
                cpf: data.cpf || '',
                cnpj: data.cnpj || '',
                phone: data.phone || '',
                whatsapp: data.whatsapp || '',
                categorias: data.categories_partners,
                tags: data.services_partners,
                sobre: data.about || ''
            };

            setOriginalValues(initialValues);
            setFormValues(initialValues);
        }
    }, [data]);

    const categoriesOptions = useMemo(() => 
        categoriesData?.categories?.map(categories => ({
            value: categories.id, 
            label: categories.name
        })) || [], 
        [categoriesData]
    );

    const tagsOptions = useMemo(() => 
        categoriesData?.services?.map(tags => ({
            value: tags.id, 
            label: tags.name
        })) || [], 
        [categoriesData]
    );

    const handleCategoryRemove = (id) => {
        setFormValues((prev) => ({
            ...prev,
            categorias: prev.categorias.filter(categoriaId => categoriaId !== id)
        }));
    };

    const handleTagRemove = (id) => {
        setFormValues((prev) => ({
            ...prev,
            tags: prev.tags.filter(tagId => tagId !== id)
        }));
    };

    if (isLoading || isCategories) {
        return (
            <section id={styles.SectionCompanyGuru}>
                <Container>
                    <Row>
                        <Col className="text-center">
                            <Spinner animation="border" role="status">
                            </Spinner>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }

    if (isError || isErrorCategories) {
        return (
            <section id={styles.SectionCompanyGuru}>
                <Container>
                    <Row>
                        <Col>
                            <Alert variant="danger">
                                Erro ao carregar os dados. Por favor, tente novamente mais tarde.
                            </Alert>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }

    return (
        <section id={styles.SectionCompanyGuru}>
            <Container>
                <Row>
                    <Col xs={12} md={6}>
                        <p className={styles.title}>Perfil da Empresa</p>
                    </Col>
                    <Col xs={12} md={6} className={styles.colButtonEdit}>
                        {isEditing ? (
                            <Button className={styles.buttonCancel} onClick={handleCancel}>
                                Cancelar
                            </Button>
                        ) : (
                            <Button className={styles.buttonEdit} onClick={() => setIsEditing(true)}>
                                Editar
                            </Button>
                        )}
                    </Col>
                </Row>
                <Row className={styles.rowForm}>
                    <Form onSubmit={handleSubmit} className={styles.form}>
                        <Row className={styles.rowBanner}>
                            <Col xs={12} md={6} className={styles.colBanner}>
                                {formValues.banner && (
                                    <Image
                                        src={formValues.banner instanceof Blob ? URL.createObjectURL(formValues.banner) : formValues.banner}
                                        className={styles.banner}
                                    />
                                )}
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group controlId="banner">
                                    <Form.Label className={styles.formTitle}>Banner</Form.Label>
                                    <Form.Control
                                        type="file"
                                        className={styles.formControl}
                                        onChange={(e) => setFormValues({ ...formValues, banner: e.target.files[0] })}
                                        accept=".jpg,.jpeg,.png,.webp"
                                        isInvalid={!!errors.banner}
                                        disabled={!isEditing}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.banner}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6}>
                                <Form.Group controlId="empresa">
                                    <Form.Label className={styles.formTitle}>Nome da Empresa</Form.Label>
                                    <Form.Control
                                        type="text"
                                        className={styles.formControl}
                                        value={formValues.empresa}
                                        onChange={(e) => setFormValues({ ...formValues, empresa: e.target.value })}
                                        isInvalid={!!errors.empresa}
                                        disabled={!isEditing}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.empresa}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group controlId="email">
                                    <Form.Label className={styles.formTitle}>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        className={styles.formControl}
                                        value={formValues.email}
                                        onChange={(e) => setFormValues({ ...formValues, email: e.target.value })}
                                        isInvalid={!!errors.email}
                                        disabled={!isEditing}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.email}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6}>
                                <Form.Group controlId="isCNPJ">
                                    <Form.Label className={styles.formTitle}>Possui CNPJ?</Form.Label>
                                    <Form.Check
                                        type="switch"
                                        id="isCNPJSwitch"
                                        label={formValues.isCNPJ ? "Sim" : "Não"}
                                        checked={formValues.isCNPJ}
                                        onChange={(e) => setFormValues({ ...formValues, isCNPJ: e.target.checked })}
                                        disabled={!isEditing}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                {!formValues.isCNPJ ? (
                                    <Form.Group controlId="cpf">
                                        <Form.Label className={styles.formTitle}>CPF</Form.Label>
                                        <MaskedInput
                                            mask={cpfMask}
                                            className={styles.formControl + " form-control"}
                                            value={formValues.cpf}
                                            onChange={(e) => setFormValues({ ...formValues, cpf: e.target.value })}
                                            render={(ref, props) => (
                                                <Form.Control
                                                    ref={ref}
                                                    {...props}
                                                    isInvalid={!!errors.cpf}
                                                />
                                            )}
                                            disabled={!isEditing}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.cpf}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                ) : (
                                    <Form.Group controlId="cnpj">
                                        <Form.Label className={styles.formTitle}>CNPJ</Form.Label>
                                        <MaskedInput
                                            mask={cnpjMask}
                                            className={styles.formControl + " form-control"}
                                            value={formValues.cnpj}
                                            onChange={(e) => setFormValues({ ...formValues, cnpj: e.target.value })}
                                            render={(ref, props) => (
                                                <Form.Control
                                                    ref={ref}
                                                    {...props}
                                                    isInvalid={!!errors.cnpj}
                                                />
                                            )}
                                            disabled={!isEditing}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.cnpj}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6}>
                            <Form.Group controlId="phone">
                                    <Form.Label className={styles.formTitle}>Telefone</Form.Label>
                                    <MaskedInput
                                        mask={phoneMask}
                                        className={styles.formControl + " form-control"}
                                        value={formValues.phone}
                                        onChange={(e) => setFormValues({ ...formValues, phone: e.target.value })}
                                        render={(ref, props) => (
                                            <Form.Control
                                                ref={ref}
                                                {...props}
                                                isInvalid={!!errors.phone}
                                            />
                                        )}
                                        disabled={!isEditing}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.phone}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group controlId="whatsapp">
                                    <Form.Label className={styles.formTitle}>WhatsApp</Form.Label>
                                    <MaskedInput
                                        mask={whatsMask}
                                        className={styles.formControl + " form-control"}
                                        value={formValues.whatsapp}
                                        onChange={(e) => setFormValues({ ...formValues, whatsapp: e.target.value })}
                                        render={(ref, props) => (
                                            <Form.Control
                                                ref={ref}
                                                {...props}
                                                isInvalid={!!errors.whatsapp}
                                            />
                                        )}
                                        disabled={!isEditing}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.whatsapp}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6}>
                                <Form.Group controlId="categorias">
                                    <Form.Label className={styles.formTitle}>Categorias</Form.Label>
                                    <Select 
                                        options={categoriesOptions}
                                        placeholder="Selecione as Categorias"
                                        isClearable
                                        styles={customStyles}
                                        isDisabled={!isEditing}
                                        onChange={(selectedOption) => {
                                            const selectedId = selectedOption ? selectedOption.value : null;
                                            if (selectedId && !formValues.categorias.includes(selectedId)) {
                                                if (formValues.categorias.length >= 10) {
                                                    toast.error('Você pode selecionar no máximo 10 categorias.');
                                                    return;
                                                }
                                                setFormValues((prev) => ({
                                                    ...prev,
                                                    categorias: [...prev.categorias, selectedId]
                                                }));
                                            }
                                        }}
                                    />
                                    <div className={styles.selectedDrop}>
                                        {formValues.categorias.map((categoriaId) => {
                                            const categoria = categoriesData?.categories.find(c => c.id === categoriaId);
                                            return (
                                                <Button
                                                    key={categoriaId}
                                                    onClick={() => handleCategoryRemove(categoriaId)}
                                                    className={styles.selectedDrop}
                                                    disabled={!isEditing}
                                                >
                                                    {categoria ? categoria.name : "Categoria Desconhecida"} &times;
                                                </Button>
                                            );
                                        })}
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group controlId="tags">
                                    <Form.Label className={styles.formTitle}>Tags</Form.Label>
                                    <Select 
                                        options={tagsOptions}
                                        placeholder="Selecione as Tags"
                                        isClearable
                                        styles={customStyles}
                                        isDisabled={!isEditing}
                                        onChange={(selectedOption) => {
                                            const selectedId = selectedOption ? selectedOption.value : null;
                                            if (selectedId && !formValues.tags.includes(selectedId)) {
                                                if (formValues.tags.length >= 10) {
                                                    toast.error('Você pode selecionar no máximo 10 categorias.');
                                                    return;
                                                }
                                                setFormValues((prev) => ({
                                                    ...prev,
                                                    tags: [...prev.tags, selectedId]
                                                }));
                                            }
                                        }}
                                    />
                                    <div className={styles.selectedDrop}>
                                        {formValues.tags.map((tagId) => {
                                            const tag = categoriesData?.services.find(t => t.id === tagId);
                                            return (
                                                <Button
                                                    key={tagId}
                                                    onClick={() => handleTagRemove(tagId)}
                                                    className={styles.selectedDrop}
                                                    disabled={!isEditing}
                                                >
                                                    {tag?.name} &times;
                                                </Button>
                                            );
                                        })}
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group controlId="sobre">
                            <Form.Label className={styles.formTitle}>Sobre</Form.Label>
                            <Form.Control
                                as="textarea"
                                className={styles.formControl}
                                value={formValues.sobre}
                                onChange={(e) => setFormValues({ ...formValues, sobre: e.target.value })}
                                maxLength={300}
                                isInvalid={!!errors.sobre}
                                disabled={!isEditing}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.sobre}
                            </Form.Control.Feedback>
                        </Form.Group>
                        {isEditing && (
                            <Row className={styles.rowButton}>
                                <Col xs={6} md={6} className={styles.colButtonCancel}>
                                    <Button className={styles.buttonCancel} onClick={handleCancel}>
                                        Cancelar
                                    </Button>
                                </Col>
                                <Col xs={6} md={6} className={styles.colButtonSend}>
                                    <Button className={styles.button} type="submit" disabled={isLoadingButton}>
                                        {isLoadingButton ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Salvar"}
                                    </Button>
                                </Col>
                            </Row>
                        )}
                    </Form>
                </Row>
                <SectionCompanyGuruAddress data={data}/>
            </Container>
        </section>
    );
}

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        marginTop: '5px',
        borderColor: '#EE880C',
        boxShadow: state.isFocused ? '0 0 5px 2px #EE880C' : provided.boxShadow,
        '&:hover': {
            borderColor: '#EE880C'
        }
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        '&:hover': {
            color: '#EE880C'
        }
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#390040' : state.isFocused ? '#EE880C' : provided.backgroundColor,
        color: state.isSelected ? '#fff' : state.isFocused ? '#fff' : provided.color,
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: '#EE880C',
            color: '#fff'
        }
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#390040',
    }),
};
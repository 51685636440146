import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col, Form, Button, Container, Spinner } from 'react-bootstrap';
import styles from "./style.module.scss";
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthContext } from '../../../../contexts/authContext';
import { usePostOneClientListPartner } from '../../../../api/PartnerAPI/partnerClient';
import { toast } from 'react-toastify';
import { isValidCPF } from '../../../../utils/utils';
import MaskedInput from 'react-text-mask';

export default function ClientListCreatePage() {
    const { partnerSlug } = useParams();
    const { token } = useAuthContext();
    const navigate = useNavigate();
    const [isLoadingButton, setIsLoadingButton] = useState(false);

    const [formValues, setFormValues] = useState({
        name: '',
        email: '',
        cpf: '',
        phone: '',
        address: '',
    });

    const cpfMask = [/[0-9]/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
    const whatsMask = ['+', '5', '5', ' ', '(', /[0-9]/, /\d/, ')', ' ', /[0-9]/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

    const [errors, setErrors] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formValues.name) newErrors.name = "Nome completo é obrigatório.";
        if (!formValues.email) newErrors.email = "E-mail é obrigatório.";
        if (!formValues.cpf) {
            newErrors.cpf = "CPF é obrigatório.";
        } else if (!isValidCPF(formValues.cpf.replace(/\D/g, ''))) {
            newErrors.cpf = "CPF inválido.";
        }
        
        if (!formValues.phone) {
            newErrors.phone = "WhatsApp é obrigatório.";
        } else if (formValues.phone.replace(/\D/g, '').length < 11) {
            newErrors.phone = "WhatsApp deve ter pelo menos 11 dígitos.";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const { mutate: postOneClientListPartner, isError, isSuccess } = usePostOneClientListPartner(partnerSlug, formValues.email);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validateForm()) {
            return;
        }
        setIsLoadingButton(true);

        const formData = new FormData();
        formData.append('name', formValues.name);
        formData.append('cpf', formValues.cpf);
        formData.append('email', formValues.email);
        formData.append('phone', formValues.phone);
        formData.append('address', formValues.address);

        postOneClientListPartner({ formData, partnerSlug, token });
    };
    
    useEffect(() => {
        if (isError) {
            toast.error(`Erro ao cadastrar cliente, tente novamente!`);
            setIsLoadingButton(false);
        } else if (isSuccess) {
            toast.success('Cliente cadastrado na lista com sucesso!');
            setIsLoadingButton(false);
            navigate(`/${partnerSlug}/clientesGuru/`);
        }
    }, [isError, isSuccess, navigate, partnerSlug]);

    const handleBack = useCallback(() => {
        navigate(`/${partnerSlug}/clientesGuru/`);
    }, [navigate, partnerSlug]);

    return (
        <section id={styles.ClientListCreatePage}>
            <Container>
                <Row>
                    <Col lg={6} md={6} sm={12}>
                        <p className={styles.title}>Novo Cliente</p>
                    </Col>
                    <Col lg={6} md={6} sm={12} className={styles.colButton}>
                        <Button className={styles.buttonCancel} onClick={handleBack}>
                            Cancelar
                        </Button>
                    </Col>
                </Row>

                <Row className={styles.rowClientData}>
                    <Form className={styles.form} noValidate onSubmit={handleSubmit}>
                        <Row>
                            <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                                <Form.Group controlId="formFullName">
                                    <Form.Label className={styles.formLabel}>Nome Completo</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        value={formValues.name}
                                        onChange={handleInputChange}
                                        isInvalid={!!errors.name}
                                        className={styles.formControl}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.name}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                                <Form.Group controlId="formEmail">
                                    <Form.Label className={styles.formLabel}>E-mail</Form.Label>
                                    <Form.Control
                                        type="email"
                                        name="email"
                                        value={formValues.email}
                                        onChange={handleInputChange}
                                        isInvalid={!!errors.email}
                                        className={styles.formControl}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.email}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                                <Form.Group controlId="formCPF">
                                    <Form.Label className={styles.formLabel}>CPF</Form.Label>
                                    <MaskedInput
                                        mask={cpfMask}
                                        className={styles.formControl + " form-control"}
                                        value={formValues.cpf}
                                        onChange={(e) => setFormValues({ ...formValues, cpf: e.target.value })}
                                        render={(ref, props) => (
                                            <Form.Control
                                                ref={ref}
                                                {...props}
                                                isInvalid={!!errors.cpf}
                                            />
                                        )}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.cpf}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                                <Form.Group controlId="formphone">
                                    <Form.Label className={styles.formLabel}>WhatsApp</Form.Label>
                                    <MaskedInput
                                        mask={whatsMask}
                                        className={styles.formControl + " form-control"}
                                        value={formValues.phone}
                                        onChange={(e) => setFormValues({ ...formValues, phone: e.target.value })}
                                        render={(ref, props) => (
                                            <Form.Control
                                                ref={ref}
                                                {...props}
                                                isInvalid={!!errors.phone}
                                            />
                                        )}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.phone}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Form.Group controlId="formAddress">
                                    <Form.Label className={styles.formLabel}>Endereço</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="address"
                                        value={formValues.address}
                                        onChange={handleInputChange}
                                        className={styles.formControl}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className={styles.RowButtonPostBack}>
                            <Col className={styles.colButtonBack}>
                                <Button className={styles.buttonPrev} onClick={handleBack}>Voltar</Button>
                            </Col>
                            <Col className={styles.colButtonPost}>
                                <Button className={styles.buttonPost} type="submit" disabled={isLoadingButton}>
                                    {isLoadingButton ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Salvar"}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Row>
            </Container>
        </section>
    );
}

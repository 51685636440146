import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Container, Row, Col, Form, Button, InputGroup, Card, Image, Tabs, Tab, Alert, Spinner } from 'react-bootstrap';
import { FaPlus, FaCalendarAlt, FaSearch } from 'react-icons/fa';
import { format, setDefaultOptions, addDays, addMinutes } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import styles from './style.module.scss';
import NoImage from "../../../../assets/no-image.jpg"
import { useParams, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../../contexts/authContext';
import { useClientPartnerData, useDaysSchedulesPartnerData, useOnePartnerData, usePostClientPartner, usePostScheduleEmployeePartner, useResumeServicesProvidedData } from '../../../../api/PartnerAPI/partnerClient';
import DatePaginationPartner from '../../../../components/datePaginationPartner';
import MaskedInput from 'react-text-mask';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { NumericFormat } from 'react-number-format';
import { isValidCPF } from '../../../../utils/utils';

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        borderColor: '#EE880C',
        boxShadow: state.isFocused ? '0 0 5px 2px #EE880C' : provided.boxShadow,
        '&:hover': {
            borderColor: '#EE880C'
        }
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        '&:hover': {
            color: '#EE880C'
        }
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#390040' : state.isFocused ? '#EE880C' : provided.backgroundColor,
        color: state.isSelected ? '#fff' : state.isFocused ? '#fff' : provided.color,
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: '#EE880C',
            color: '#fff'
        }
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#390040',
    }),
};

setDefaultOptions({ locale: ptBR });

const formatDuration = (duration) => {
    const [hours, minutes] = duration.split(':');
    return `${hours}:${minutes}`;
};

export default function SchedulesCreatePage() {
    const navigate = useNavigate();
    const { partnerSlug } = useParams();
    const { token } = useAuthContext();
    const { servicesData } = useResumeServicesProvidedData(partnerSlug, token);
    const { data: partnerData } = useOnePartnerData(partnerSlug, token);
    const [isLoadingButton, setIsLoadingButton] = useState(false);

    const [selectedOption, setSelectedOption] = useState('');
    const [selectedTime, setSelectedTime] = useState(null);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [date, setDate] = useState(new Date());
    const [showCalendar, setShowCalendar] = useState(false);
    const [duration, setDuration] = useState('00:30');
    const [price, setPrice] = useState('');
    const [observations, setObservations] = useState('');
    const [customBudget, setCustomBudget] = useState(false);
    
    const [keyTabs, setKeyTabs] = useState('servico');
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    
    const [daysToAdd, setDaysToAdd] = useState(6); 
    
    const formattedDate = format(date, 'yyyy-MM-dd');
    const formattedEndDate = format(addDays(date, daysToAdd), 'yyyy-MM-dd');
    const isFirstOptionChange = useRef(true);
    
    const [shouldFetchServices, setShouldFetchServices] = useState(false);
    const { data: schedulesData, refetch } = useDaysSchedulesPartnerData(partnerSlug, selectedOption, formattedDate, formattedEndDate, "", token, shouldFetchServices);
    const [availableEmployees, setAvailableEmployees] = useState([]);
    
    useEffect(() => {
        const handleResize = () => {
            const windowWidth = window.innerWidth;
            if (windowWidth <= 991.98) {
                setDaysToAdd(2);
            } else if(windowWidth >= 991.98) {
                setDaysToAdd(6);
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [daysToAdd]);

    useEffect(() => {
        refetch();
    }, [daysToAdd, refetch]);
    
    const [clientEmail, setClientEmail] = useState('');
    const [shouldFetchClient, setShouldFetchClient] = useState(false);
    const { ClientData, isError: isErrorClient, isSuccess: isSuccessClient } = useClientPartnerData(partnerSlug, clientEmail, '', token, shouldFetchClient);
    const [errors, setErrors] = useState({});

    const [initialValues] = useState({
        id: '',
        name: '',
        email: '',
        cpf: '',
        phone: '',
        address: '',
    });

    const cpfMask = [/[0-9]/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
    const whatsMask = ['+', '5', '5', ' ', '(', /[0-9]/, /\d/, ')', ' ', /[0-9]/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

    const [formValues, setFormValues] = useState(initialValues);
    const [originalFormValues, setOriginalFormValues] = useState(initialValues);
    const { mutate: postClientPartner, data: postClientData} = usePostClientPartner(partnerSlug, formValues.email);
    
    const handleTimeSelect = (selection) => {
        setSelectedTime({ date: selection.date, time: selection.time });
        setAvailableEmployees(selection.availableEmployees);
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formValues.name) newErrors.name = 'O nome do cliente é obrigatório';
        if (!/\S+@\S+\.\S+/.test(formValues.email)) newErrors.email = 'O email é inválido';
        if (!formValues.cpf) {
            newErrors.cpf = "CPF é obrigatório.";
        } else if (!isValidCPF(formValues.cpf.replace(/\D/g, ''))) {
            newErrors.cpf = "CPF inválido.";
        }
        if (!formValues.phone) {
            newErrors.phone = "WhatsApp é obrigatório.";
        } else if (formValues.phone.replace(/\D/g, '').length < 11) {
            newErrors.phone = "WhatsApp deve ter pelo menos 11 dígitos.";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    useEffect(() => {
        if(isSuccessClient || isErrorClient){
            setShowAlert(false);
            setShouldFetchClient(false);
        }
        if(isErrorClient){
            setShouldFetchClient(false);
            setFormValues(initialValues);
        }
    }, [isSuccessClient, isErrorClient, initialValues]);
    
    useEffect(() => {
        if (selectedOption && isFirstOptionChange.current) {
            isFirstOptionChange.current = false;
        }
        if(date){
            refetch();
        }
    }, [date, selectedOption, refetch]);

    useEffect(() => {
        if (ClientData) {
            const newFormValues = {
                id: ClientData.id || '',
                name: ClientData.name || '',
                email: ClientData.email || '',
                cpf: ClientData.cpf || '',
                phone: ClientData.phone || '',
                address: ClientData.address || '',
            };
            setFormValues(newFormValues);
            setOriginalFormValues(newFormValues);
        }
    }, [ClientData]);

    useEffect(() => {
        if (postClientData) {
            const newFormValues = {
                id: postClientData.id || '',
                name: postClientData.name || '',
                email: postClientData.email || '',
                cpf: postClientData.cpf || '',
                phone: postClientData.phone || '',
                address: postClientData.address || '',
            };
            setFormValues(newFormValues);
        }
    }, [postClientData]);

    const handleDateChange = (newDate) => {
        if (newDate && !isNaN(newDate)) {
            setDate(newDate);
        }
    };

    const handleToday = () => {
        setDate(new Date());
    };

    const servicesOptions = useMemo(() => 
        servicesData?.map(categories => ({
            value: categories.id, 
            label: categories.name
        })) || [], 
        [servicesData]
    );

    const handleOptionChange = (e) => {
        const selectedId = e.target.value;
        setShouldFetchServices(true);
        
        const selectedService = servicesData.find(service => service.id === selectedId);
        if (selectedService) {
            setSelectedOption(selectedService.slug);
            setDuration(formatDuration(selectedService.duration));
            setPrice(selectedService.price);
        } else {
            setDuration('');
            setPrice('');
        }
        
        setSelectedTime(null);
        setAvailableEmployees([]);
        setSelectedEmployee(null);
    };

    const handleDurationChange = (e) => {
        const value = e.target.value;
        const regex = /^(\d{1,2})h:(\d{1,2})min$/; 
        const match = value.match(regex);
        if (match) {
            const hours = Math.min(Math.max(parseInt(match[1]), 0), 23);
            const minutes = Math.min(Math.max(parseInt(match[2]), 0), 59);

            setDuration(`${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`);
        } else {
            console.error("Formato inválido. Use HHh:MMmin.");
        }
    };

    const handleCustomBudgetChange = (e) => {
        setCustomBudget(e.target.id === 'radioPSchedule-2');
    };

    const handleCardClick = (employee) => {
        setSelectedEmployee(employee);
    };

    const handleNextTab = (currentKey) => {
        const keys = ['servico', 'horario', 'cliente', 'resumo'];
        const currentIndex = keys.indexOf(currentKey);
        const nextKey = keys[currentIndex + 1];
        
        if (currentKey === 'servico' && !selectedOption) {
            setAlertMessage("Por favor, selecione um serviço antes de continuar.");
            setShowAlert(true);
        } else if (currentKey === 'horario' && (!selectedTime || !selectedEmployee)) {
            setAlertMessage("Por favor, selecione uma data e um empregado antes de continuar.");
            setShowAlert(true);
        } else if (currentIndex < keys.length - 1) {
            setShowAlert(false);
            setKeyTabs(nextKey);
        }

        const tabElement = document.getElementById('controlled-tab-example');
        if (tabElement) {
            tabElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const handlePrevTab = (currentKey) => {
        const keys = ['servico', 'horario', 'cliente', 'resumo'];
        const currentIndex = keys.indexOf(currentKey);
        if (currentIndex > 0) {
            setKeyTabs(keys[currentIndex - 1]);
        }
        
        const tabElement = document.getElementById('controlled-tab-example');
        if (tabElement) {
            tabElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const handleEmailSearch = () => {
        setAlertMessage('');
        setShowAlert(false);

        if (!clientEmail || !/\S+@\S+\.\S+/.test(clientEmail)) {
            setAlertMessage("Por favor, insira um e-mail válido.");
            setShowAlert(true);
            setShouldFetchClient(false);
            return;
        }
        setShouldFetchClient(true);
    };

    const handleFormSubmitClient = (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        const formData = new FormData(e.target);
        formData.append('partner', partnerSlug);
        formData.append('name', formValues.name);
        formData.append('email', formValues.email);
        formData.append('cpf', formValues.cpf);
        formData.append('phone', formValues.phone);
        formData.append('address', formValues.address);

        if (JSON.stringify(formValues) !== JSON.stringify(originalFormValues)) {
            postClientPartner({ formData, partnerSlug, token });
            if(postClientPartner){
                toast.success('Dados do cliente atualizados com sucesso!.');
                setOriginalFormValues(formValues);
                handleNextTab('cliente');
            }
            else{
                toast.error('Erro ao atualizar dados do cliente, confira os dados e tente novamente!');
            }
        } else {
            handleNextTab('cliente');
            toast.info('Nenhuma alteração de dados detectada.');
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };
    
    const { mutate: postScheduleEmployeePartner, isError: isErrorPost, isSuccess: isSuccessPost } = usePostScheduleEmployeePartner(partnerSlug, formValues.id);

    const handleFormSubmitSchedule = () => {

        const formattedDate = format(selectedTime.date, 'yyyy-MM-dd');
        const startDateTime = `${formattedDate}T${selectedTime.time}:00`;

        const [durationHours, durationMinutes] = duration.split(':').map(Number);
        const endDateTime = addMinutes(startDateTime, durationHours * 60 + durationMinutes);
        const formattedEndDateTime = format(endDateTime, "yyyy-MM-dd'T'HH:mm:ss");
        
        const selectedService = servicesData.find(service => service.slug === selectedOption);

        if (!selectedService) {
            console.error("Serviço não encontrado!");
            return;
        }

        const formData = new FormData();
        formData.append('name', selectedService?.name);
        formData.append('price', price);
        formData.append('description', selectedService?.description);
        formData.append('duration', duration);
        formData.append('employee', selectedEmployee?.id);
        formData.append('clientList', formValues.id);
        formData.append('service', selectedService?.id);
        formData.append('start_time', startDateTime);
        formData.append('end_time', formattedEndDateTime);
        formData.append('observations', observations);
        formData.append('status', 'pen');

        postScheduleEmployeePartner({partnerSlug, formData, token });
    };
    
    useEffect(() => {
        if(isSuccessPost){
            toast.success('Agendamento realizado com sucesso!.');
            setIsLoadingButton(false);
            navigate(`/${partnerSlug}/agendamentosGuru/`);
        }
        if(isErrorPost){
            setSelectedTime(null);
            setAvailableEmployees([]);
            setSelectedEmployee(null);
            setIsLoadingButton(false);
            refetch();
            toast.error('Erro ao criar agendamento, confira os dados e a data disponível e tente novamente!');
            setKeyTabs('servico');
        }
    }, [isSuccessPost, isErrorPost, navigate, partnerSlug, refetch]);

    return (
        <section id={styles.SchedulesCreatePage}>
            <Container>
                <Row>
                    <p className={styles.title}>Novo Agendamento</p>
                </Row>
                <Tabs activeKey={keyTabs} onSelect={(k) => setKeyTabs(k)} id="controlled-tab-example" className="mb-3">
                    <Tab eventKey="servico" title="Serviço" disabled>
                        <Form>
                            <Row className={styles.rowForm}>
                                <Col xs={8} sm={6} md={6} lg={6} xl={6} className={styles.colDropDownTitle}>
                                    <Form.Label className={styles.title}>Selecione o Serviço</Form.Label>
                                </Col>
                                <Col xs={4} sm={6} md={6} lg={6} xl={6} className={styles.colButtonAdd}>
                                    <Button className={styles.buttonAdd}><FaPlus /></Button>
                                </Col>
                                <Form.Group>
                                    <Select
                                        options={servicesOptions}
                                        value={servicesOptions.find(option => option.value === selectedOption)}
                                        onChange={(selected) => handleOptionChange({ target: { value: selected.value } })}
                                        styles={customStyles}
                                        placeholder="Selecione o serviço"
                                    />
                                </Form.Group>
                                {selectedOption && (
                                    <>
                                        <Col xs={12} sm={12} md={12} lg={4} xl={4} className={styles.colDropDownTitle}>
                                            <Form.Label className={styles.title}>Orçamento personalizado?</Form.Label>
                                            <Form.Group onChange={handleCustomBudgetChange}>
                                                <Form.Check
                                                    inline
                                                    label="Não, usar padrão."
                                                    name="radioPSchedule"
                                                    type="radio"
                                                    id="radioPSchedule-1"
                                                    checked={!customBudget}
                                                    onChange={() => setCustomBudget(false)}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="Sim."
                                                    name="radioPSchedule"
                                                    type="radio"
                                                    id="radioPSchedule-2"
                                                    checked={customBudget}
                                                    onChange={() => setCustomBudget(true)}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={6} sm={6} md={6} lg={2} xl={2} className={styles.colFormTitle}>
                                            <Form.Label className={styles.title}>Duração</Form.Label>
                                        </Col>
                                        <Col xs={6} sm={6} md={6} lg={2} xl={2} className={styles.colFormControl}>
                                            <MaskedInput
                                                mask={[/\d/, /\d/, 'h', ':', /\d/, /\d/, 'min']}
                                                value={duration}
                                                onChange={handleDurationChange}
                                                placeholder="HH:MM"
                                                className={styles.formControlMask}
                                                disabled={!customBudget}
                                            />
                                        </Col>
                                        <Col xs={6} sm={6} md={6} lg={2} xl={2} className={styles.colFormTitle}>
                                            <Form.Label className={styles.title}>Preço</Form.Label>
                                        </Col>
                                        <Col xs={6} sm={6} md={6} lg={2} xl={2} className={styles.colFormControl}>
                                            <Form.Group>
                                                <NumericFormat
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    prefix="R$ "
                                                    className={styles.formControl + " form-control"}
                                                    value={price || ''}
                                                    onValueChange={(values) => {
                                                        const cleanedValue = values.value
                                                            .replace(/[^0-9,.]/g, '')
                                                            .replace(/(\..*)\./g, '$1');
                                                        
                                                        setPrice(cleanedValue);  // Atualiza o estado com o valor limpo
                                                    }} 
                                                    decimalScale={2}
                                                    disabled={!customBudget}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </>    
                                )}
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className={styles.colDropDownTitle}>
                                    <Form.Label className={styles.title}>Observações</Form.Label>
                                </Col>
                                <Form.Group>
                                    <Form.Control
                                        type="text"
                                        as="textarea"
                                        rows={3}
                                        placeholder="Observações além da descrição padrão"
                                        onChange={(e) => setObservations(e.target.value)}
                                        className={styles.select}
                                    />
                                </Form.Group>
                            </Row>
                            {showAlert && !selectedOption && (
                                <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible className='mt-4'>
                                    {alertMessage}
                                </Alert>
                            )}
                            <Row className={styles.RowFindSchedule}>
                                <p className={styles.findText}>Você deseja buscar seu horário por</p>
                                <Col xs={12} sm={6} md={6} lg={6} xl={6} className={styles.colButtonColab}>
                                    <Button className={styles.buttonColab}>Colaborador</Button>
                                </Col>
                                <Col xs={12} sm={6} md={6} lg={6} xl={6} className={styles.colButtonDate}>
                                    <Button className={styles.buttonDate} onClick={() => handleNextTab(keyTabs)}>Data</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Tab>
                    <Tab eventKey="horario" title="Horário" disabled>
                        <Row className={styles.RowScheduleHour}>
                            <Col xs={12} sm={6} md={6} lg={3} xl={3} className={styles.colHourTitle}>
                                <Form.Label className={styles.title}>Selecionar Data</Form.Label>
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={3} xl={3} className={styles.colWithCalendar}>
                                <InputGroup className={styles.buttonCalendarToggle}>
                                    <InputGroup.Text onClick={() => setShowCalendar(!showCalendar)}>
                                        <FaCalendarAlt/>
                                    </InputGroup.Text>
                                    <Form.Control
                                        type="text"
                                        value={date ? format(date, 'dd-MM-yyyy') : ''}
                                        onChange={handleDateChange}
                                        className={styles.buttonDate}
                                        disabled
                                    />
                                    <InputGroup.Text className={styles.inputButtonToday} onClick={handleToday} >
                                        <div className={styles.buttonToday}>Hoje</div>
                                    </InputGroup.Text>
                                </InputGroup>
                                {showCalendar && (
                                    <div className={styles.calendarWrapper}>
                                        <Calendar
                                            onChange={(newDate) => handleDateChange(newDate)}
                                            value={date}
                                            locale="pt-BR"
                                            className={styles.datePicker}
                                        />
                                    </div>
                                )}
                            </Col>
                        </Row>
                        <Row className={styles.RowPaginationDate}>
                            <p className={styles.title}>Datas Disponíveis</p>
                            <DatePaginationPartner
                                startDate={date}
                                onDateChange={handleDateChange}
                                partnerData={partnerData?.working_hours}
                                duration={duration}
                                schedulesData={schedulesData|| []}
                                onTimeSelect={handleTimeSelect}
                            />
                        </Row>
                        {selectedTime && 
                            <Row className={styles.RowPartnerGuru}>
                                <p className={styles.partnerTitle}>Estes são os profissionais disponíveis para o dia:
                                <br/>{selectedTime.date ? format(selectedTime.date, 'd \'de\' MMMM \'de\' yyyy, EEEE', { locale: ptBR }) : ''}
                                </p>
                            </Row>
                        }
                        <Row className={styles.RowPartnerGuru}>
                        {selectedTime && (
                            <Row className={styles.RowEmployeeGuru}>
                                {availableEmployees.length > 0 ? (
                                    availableEmployees.map((employee) => {
                                        const isSelected = selectedEmployee?.id === employee.id;
                                    return (
                                        <Col key={employee.id} xs={6} sm={4} md={3} lg={2} xl={2} className={styles.colPartnerGuru}>
                                            <Card className={`${styles.partnerCard} ${isSelected ? styles.cardSelected: ''}`} onClick={() => handleCardClick(employee)}>
                                            <div className={styles.divPartnerImage}>
                                                <Image src={employee.photo? employee.photo : NoImage} className={styles.partnerImage} />
                                            </div>
                                            <Card.Body>
                                                <Card.Title className={`${styles.partnerName} ${isSelected ? styles.cardSelected: ''}`}>{employee.name}</Card.Title>
                                            </Card.Body>
                                            </Card>
                                        </Col>
                                    )})
                                ) : (
                                    <p>Nenhum empregado disponível</p>
                                )}
                            </Row>
                        )}
                        </Row>
                        {showAlert && (!selectedTime || !selectedEmployee) &&(
                            <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible className='mt-4'>
                                {alertMessage}
                            </Alert>
                        )}
                        <Row className={styles.RowButtonNextBack}>
                            <Col className={styles.colButtonBack}>
                                <Button className={styles.buttonPrev} onClick={() => handlePrevTab(keyTabs)}>Voltar</Button>
                            </Col>
                            <Col className={styles.colButtonNext}>
                                <Button className={styles.buttonNext} onClick={() => handleNextTab(keyTabs)}>Próximo</Button>
                            </Col>
                        </Row>
                    </Tab>
                    <Tab eventKey="cliente" title="Cliente" disabled>
                        <Row className={styles.rowSearchClient}>
                            <p className={styles.title}>Utilizar dados da sua Lista de Clientes:</p>
                            <InputGroup>
                                <Form.Control
                                    type="email"
                                    placeholder="Buscar cliente pelo e-mail"
                                    aria-label="Buscar cliente"
                                    className={styles.inputControl}
                                    value={clientEmail}
                                    onChange={(e) => setClientEmail(e.target.value)}
                                    required
                                />
                                <Button variant="outline-secondary" className={styles.inputControl} onClick={handleEmailSearch}>
                                    <FaSearch className={styles.icon} />
                                </Button>
                            </InputGroup>
                        </Row>
                        {showAlert && (
                            <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible className='mt-4'>
                                {alertMessage}
                            </Alert>
                        )}
                        <Row className={styles.rowClientData}>
                            <Form className={styles.form} noValidate onSubmit={handleFormSubmitClient}>
                                <Row>
                                    <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <Form.Group controlId="formFullName">
                                            <Form.Label className={styles.formLabel}>Nome Completo</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="name"
                                                value={formValues.name}
                                                onChange={handleInputChange}
                                                isInvalid={!!errors.name}
                                                className={styles.formControl}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.name}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <Form.Group controlId="formEmail">
                                            <Form.Label className={styles.formLabel}>E-mail</Form.Label>
                                            <Form.Control
                                                type="email"
                                                name="email"
                                                value={formValues.email}
                                                onChange={handleInputChange}
                                                isInvalid={!!errors.email}
                                                className={styles.formControl}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.email}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <Form.Group controlId="formCPF">
                                            <Form.Label className={styles.formLabel}>CPF</Form.Label>
                                            <MaskedInput
                                                mask={cpfMask}
                                                className={styles.formControl + " form-control"}
                                                value={formValues.cpf}
                                                onChange={(e) => setFormValues({ ...formValues, cpf: e.target.value })}
                                                render={(ref, props) => (
                                                    <Form.Control
                                                        ref={ref}
                                                        {...props}
                                                        isInvalid={!!errors.cpf}
                                                    />
                                                )}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.cpf}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <Form.Group controlId="formphone">
                                            <Form.Label className={styles.formLabel}>WhatsApp</Form.Label>
                                            <MaskedInput
                                                mask={whatsMask}
                                                className={styles.formControl + " form-control"}
                                                value={formValues.phone}
                                                onChange={(e) => setFormValues({ ...formValues, phone: e.target.value })}
                                                render={(ref, props) => (
                                                    <Form.Control
                                                        ref={ref}
                                                        {...props}
                                                        isInvalid={!!errors.phone}
                                                    />
                                                )}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.phone}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <Form.Group controlId="formAddress">
                                            <Form.Label className={styles.formLabel}>Endereço</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="address"
                                                value={formValues.address}
                                                onChange={handleInputChange}
                                                className={styles.formControl}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className={styles.RowButtonNextBack}>
                                    <Col className={styles.colButtonBack}>
                                        <Button className={styles.buttonPrev} onClick={() => handlePrevTab(keyTabs)}>Voltar</Button>
                                    </Col>
                                    <Col className={styles.colButtonNext}>
                                        <Button className={styles.buttonNext} type="submit">Enviar</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Row>
                    </Tab>
                    <Tab eventKey="resumo" title="Resumo" disabled>
                        <Row className={styles.rowSchedulingData}>
                            <p className={styles.titleSchedulingData}>Dados do Agendamento:</p>
                        </Row>
                        <Row className={styles.rowSchedulingResume}>
                            <p className={styles.titleResume}><strong>Serviço:</strong> {selectedOption && servicesData.find(service => service.slug === selectedOption)?.name}</p>
                            <p className={styles.titleResume}><strong>Data e Hora:</strong> {selectedTime && `${format(new Date(selectedTime.date), 'dd/MM/yyyy')} às ${selectedTime.time}`}</p>
                            <p className={styles.titleResume}><strong>Duração:</strong> {duration}</p>
                            <p className={styles.titleResume}><strong>Valor:</strong> {price}</p>
                            <p className={styles.titleResume}><strong>Descrição:</strong> {selectedOption && servicesData.find(service => service.slug === selectedOption)?.description}</p>
                            <p className={styles.titleResume}><strong>Observações:</strong> {observations}</p>
                        </Row>
                        <Row className={styles.rowSchedulingResumeClient}>
                            <p className={styles.titleResume}><strong>Cliente:</strong> {formValues.name}</p>
                            <p className={styles.titleResume}><strong>CPF:</strong> {formValues.cpf}</p>
                            <p className={styles.titleResume}><strong>Telefone:</strong> {formValues.phone}</p>
                            <p className={styles.titleResume}><strong>Endereço:</strong> {formValues.address}</p>
                        </Row>
                        <Row className={styles.RowButtonNextBack}>
                            <Col className={styles.colButtonBack}>
                                <Button className={styles.buttonPrev} onClick={() => handlePrevTab(keyTabs)}>Voltar</Button>
                            </Col>
                            <Col className={styles.colButtonNext}>
                                <Button className={styles.buttonNext} onClick={() => handleFormSubmitSchedule()} disabled={isLoadingButton}>
                                    {isLoadingButton ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Salvar"}
                                </Button>
                            </Col>
                        </Row>
                    </Tab>
                </Tabs>
            </Container>
        </section>
    );
}

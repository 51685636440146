import React, { useState, useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Confetti from 'react-confetti';
import styles from "./style.module.scss";

export default function TopsDaCidadePage() {
    const [showConfetti, setShowConfetti] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowConfetti(false);
        }, 5000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <HelmetProvider>
            {showConfetti && <Confetti />}
            <section id={styles.TopsDaCidadePage}>
                <Container>
                    <Helmet>
                        <title>Tops da Cidade 2024 - GoinGuru</title>
                    </Helmet>
                    <Row className={styles.rowTitle}>
                        <p className={styles.title}>Inscrições Tops da Cidade</p>
                        <p className={styles.ano}>2024</p>
                    </Row>
                    <Row className={styles.rowText}>
                        <p className={styles.text}>Seja escolhido como o melhor da sua cidade</p>
                        <p className={styles.text}>Inscrições até o dia 30 de Novembro de 2024</p>
                    </Row>
                    <Row>
                        <iframe title='TopsDaCidadeBot' src="https://sitebot.builderall.com/sitebot.php?key=RLWQBa5B8Uu4" style={{border: 0, width: "100%", height: "500px"}} allowFullScreen></iframe>
                    </Row>
                </Container>
            </section>
        </HelmetProvider>
    );
}

import { useQuery } from "@tanstack/react-query";
import { BASE_URL, AUTH_DEBUG } from "../default";

const getListCategoriesGuru = async (token) => {
    const url = `${BASE_URL}/blog/listCategories/`;

    const options = {
        method: 'GET',
        credentials: 'include',
        headers: {
            jwt: token,
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    };

    const response = await fetch(url, options);
    if (response.ok) {
        const data = await response.json();
        AUTH_DEBUG && console.log("BlogGuruAPI::getListCategoriesGuru(): ", data);
        return data;
    } else {
        throw new Error("Error on getListCategoriesGuru()");
    }
};

export function useListCategoriesGuruData(token) {
    const query = useQuery({
        queryKey: ['ListCategories'],
        queryFn: () => getListCategoriesGuru(token),
        staleTime: 1000 * 60 * 10,
        cacheTime: 1000 * 60 * 10,
        enabled: !!token,
    });

    return {
        ...query,
        categoriesData: query.data,
    };
}

const getListAuhtorGuru = async (token) => {
    const url = `${BASE_URL}/blog/listAuthors/`;

    const options = {
        method: 'GET',
        credentials: 'include',
        headers: {
            jwt: token,
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    };

    const response = await fetch(url, options);
    if (response.ok) {
        const data = await response.json();
        AUTH_DEBUG && console.log("BlogGuruAPI::getListAuhtorGuru(): ", data);
        return data;
    } else {
        throw new Error("Error on getListAuhtorGuru()");
    }
};

export function useListAuhtorGuruData(token) {
    const query = useQuery({
        queryKey: ['ListAuthors'],
        queryFn: () => getListAuhtorGuru(token),
        staleTime: 1000 * 60 * 10,
        cacheTime: 1000 * 60 * 10,
        enabled: !!token,
    });

    return {
        ...query,
        authorsData: query.data,
    };
}
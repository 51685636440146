import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Container, Row, Col, Form, Button, Image, Alert, Spinner, Modal } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import styles from './style.module.scss';
import { useAuthContext } from '../../../../contexts/authContext';
import { PartnerAPI, useServicesProvidedData, useOneCategoriesServicesPartnerData, useOneServicesProvidedData, usePatchOneServicesProvided } from '../../../../api/PartnerAPI/partnerClient';
import { toast } from 'react-toastify';
import { NumericFormat } from 'react-number-format';

export default function ServicesEditPage() {
    const { partnerSlug, serviceSlug } = useParams();
    const { token, user } = useAuthContext();
    const navigate = useNavigate();
    const [isLoadingButton, setIsLoadingButton] = useState(false);
    const { data, isLoading, isError: isErrorData } = useOneServicesProvidedData(serviceSlug, token);
    const { categoriesData } = useOneCategoriesServicesPartnerData(partnerSlug, token);
    const { mutate: patchOneServicesProvided, isSuccess, isError } = usePatchOneServicesProvided(partnerSlug, serviceSlug);
    const { refetch } = useServicesProvidedData(partnerSlug, token, 1);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const [formValues, setFormValues] = useState({
        photo: null,
        service: '',
        description: '',
        price: '',
        duration: '',
        customquote: false,
        homeService: false,
        isOnlineService: false,
        isPublished: false,
        categorias: [],
        tags: [],
        allCategorias: [],
        allTags: []
    });
    const [originalValues, setOriginalValues] = useState({});

    useEffect(() => {
        if (data) {
            const initialValues = {
                photo: data.photo,
                service: data.name,
                description: data.description,
                price: data.price,
                duration: formatDuration(data.duration),
                customquote: data.is_custom_quote,
                homeService: data.is_home_service,
                isOnlineService: data.is_online_service,
                isPublished: data.is_published,
                categorias: data.categories_service,
                tags: data.services_service,
            };
            setFormValues(prev => ({ ...prev, ...initialValues }));
            setOriginalValues(initialValues);
        }
    }, [data]);

    const [errors, setErrors] = useState({});

    const validateForm = () => {
        const newErrors = {};
        if (!formValues.photo) newErrors.photo = 'A imagem do serviço é obrigatória.';
        if (!formValues.service) newErrors.service = 'O nome do serviço é obrigatório.';
        if (!formValues.description) newErrors.description = 'A descrição do serviço é obrigatória.';
        if (!formValues.customquote && !formValues.price) newErrors.price = 'O preço é obrigatório.';
        if (!formValues.duration || formValues.duration === '00:00:00') newErrors.duration = 'A duração deve ser maior.';
        if (!formValues.categorias.length) newErrors.categorias = 'Pelo menos uma categoria é obrigatória.';
        if (!formValues.tags.length) newErrors.tags = 'Pelo menos uma tag é obrigatória.';
        
        const flagCount = [formValues.homeService, formValues.isOnlineService].filter(Boolean).length;
        if (flagCount > 1) {
            newErrors.flags = 'Apenas uma das opções "Atendimento a Domicílio" ou "Serviço Online" pode estar ativada.';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!validateForm()) {
            toast.error('Preencha os campos obrigatórios!');
            return;
        }
        setIsLoadingButton(true);

        const changedValues = {};
        Object.keys(formValues).forEach(key => {
            if (JSON.stringify(formValues[key]) !== JSON.stringify(originalValues[key])) {
                changedValues[key] = formValues[key];
            }
        });
        
        const formData = new FormData();
        if (changedValues.photo) formData.append('photo', formValues.photo);
        if (changedValues.service) formData.append('name', formValues.service);
        if (changedValues.description) formData.append('description', formValues.description);
        if (formValues.customquote) {
            formData.append('price', '');
        } else if (changedValues.price) {
            formData.append('price', formValues.price);
        }
        if (changedValues.duration) formData.append('duration', formValues.duration + ':00');
        formData.append('is_home_service', formValues.homeService);
        formData.append('is_published', formValues.isPublished);
        formData.append('is_custom_quote', formValues.customquote);
        formData.append('is_online_service', formValues.isOnlineService);
        if (changedValues.categorias) formValues.categorias.forEach(categoria => formData.append('categories_service', categoria));
        if (changedValues.tags) formValues.tags.forEach(tag => formData.append('services_service', tag));

        patchOneServicesProvided({ formData, token, serviceSlug });
    };

    const categoriesOptions = useMemo(() => 
        categoriesData?.categories?.map(categories => ({
            value: categories.id, 
            label: categories.name
        })) || [], 
        [categoriesData]
    );

    const tagsOptions = useMemo(() => 
        categoriesData?.services?.map(tags => ({
            value: tags.id, 
            label: tags.name
        })) || [], 
        [categoriesData]
    );

    const handleCategoryRemove = (id) => {
        setFormValues((prev) => ({
            ...prev,
            categorias: prev.categorias.filter(categoriaId => categoriaId !== id)
        }));
    };

    const handleTagRemove = (id) => {
        setFormValues((prev) => ({
            ...prev,
            tags: prev.tags.filter(tagId => tagId !== id)
        }));
    };

    const generateTimeOptions = () => {
        const times = [];
        for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute += 30) {
                const formattedHour = hour.toString().padStart(2, '0');
                const formattedMinute = minute.toString().padStart(2, '0');
                times.push(`${formattedHour}:${formattedMinute}`);
            }
        }
        return times;
    };
    
    const timeOptions = generateTimeOptions();

    const formatDuration = (duration) => {
        const [hours, minutes] = duration.split(':');
        return `${hours}:${minutes}`;
    };
    

    const handleBack = useCallback(() => {
        navigate(`/${partnerSlug}/servicosGuru/`);
    }, [navigate, partnerSlug]);

    useEffect(() => {
        if (isSuccess) {
            toast.success('Serviço editado com sucesso!');
            setIsLoadingButton(false);
            handleBack();
        } 
        if (isError) {
            toast.error('Erro ao editar serviço, tente novamente!');
            setIsLoadingButton(false);
        }
    }, [isSuccess, isError, handleBack]);

    const handleChange = (e) => {
        const { name, value, type, checked, files } = e.target;
    
        if (type === 'file') {
            setFormValues((prev) => ({
                ...prev,
                [name]: files[0]
            }));
        } else if (type === 'checkbox') {
            if (name === 'homeService' && checked) {
                setFormValues((prev) => ({
                    ...prev,
                    homeService: true,
                    isOnlineService: false,
                }));
            } else if (name === 'isOnlineService' && checked) {
                setFormValues((prev) => ({
                    ...prev,
                    homeService: false,
                    isOnlineService: true,
                }));
            } else {
                setFormValues((prev) => ({
                    ...prev,
                    [name]: checked
                }));
            }
        } else {
            setFormValues((prev) => ({
                ...prev,
                [name]: value
            }));
        }
    };

    const handleDeleteModalOpen = () => {
        setDeleteModalOpen(true);
    };

    const handleDeleteModalClose = () => {
        setDeleteModalOpen(false);
    };

    const handleDelete = async () => {
        try {
            const response = await PartnerAPI.deleteOneServicesProvided(serviceSlug, token);
            if (response.status === 200) {
                refetch(user.id, token, 1);
                handleDeleteModalClose();
                toast.success('Serviço excluído com sucesso!');
                handleBack();
            } else {
                toast.error('Erro ao excluir serviço, tente novamente!');
            }
        } catch (error) {
            toast.error('Erro ao excluir serviço, tente novamente!');
        }
    };

    if (isLoading) {
        return (
            <section id={styles.SectionCompanyGuru}>
                <Container>
                    <Row>
                        <Col className="text-center">
                            <Spinner animation="border" role="status">
                            </Spinner>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }

    if (isErrorData) {
        return (
            <section id={styles.SectionCompanyGuru}>
                <Container>
                    <Row>
                        <Col>
                            <Alert variant="danger">
                                Erro ao carregar os dados. Por favor, tente novamente mais tarde.
                            </Alert>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }

    return (
        <section id={styles.ServicesEditPage}>
            <Container>
                <Row>
                    <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                        <p className={styles.title}>Novo Serviço</p>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6} xl={6} className={styles.colButton}>
                        <Button className={styles.buttonDelete} onClick={handleDeleteModalOpen}>
                            Excluir
                        </Button>
                    </Col>
                </Row>
                <Row className={styles.rowForm}>
                    <Form onSubmit={handleSubmit} className={styles.form}>
                        <Row>
                            <Col xs={12} md={6} className={styles.colPhoto}>
                                {formValues.photo && 
                                    <Image
                                        src={formValues.photo instanceof Blob ? URL.createObjectURL(formValues.photo) : formValues.photo}
                                        className={styles.photo}
                                    />
                                }
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group controlId="photo">
                                    <Form.Label className={styles.formTitle}>Foto</Form.Label>
                                    <Form.Control
                                        type="file"
                                        className={styles.formControl}
                                        name="photo"
                                        onChange={handleChange}
                                        accept=".jpg,.jpeg,.png,.webp"
                                        isInvalid={!!errors.photo}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.photo}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group controlId="service">
                            <Form.Label className={styles.formTitle}>Nome do Serviço</Form.Label>
                            <Form.Control
                                type="text"
                                className={styles.formControl}
                                name="service"
                                value={formValues.service}
                                onChange={handleChange}
                                maxLength={100}
                                isInvalid={!!errors.service}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.service}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="description">
                            <Form.Label className={styles.formTitle}>Descrição</Form.Label>
                            <Form.Control
                                as="textarea"
                                className={styles.formControl}
                                name="description"
                                value={formValues.description}
                                onChange={handleChange}
                                maxLength={300}
                                isInvalid={!!errors.description}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.description}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Row>
                            <Col xs={12} sm={6} md={6} lg={3} xl={3}>
                                <Form.Group controlId="homeService">
                                    <Form.Label className={styles.formTitle}>Atendimento a Domicílio?</Form.Label>
                                    <Form.Check
                                        type="switch"
                                        id="homeServiceSwitch"
                                        name="homeService"
                                        label={formValues.homeService ? "Sim" : "Não"}
                                        checked={formValues.homeService}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={3} xl={3}>
                            <Form.Group controlId="isOnlineService">
                                    <Form.Label className={styles.formTitle}>Serviço Online?</Form.Label>
                                    <Form.Check
                                        type="switch"
                                        id="isOnlineServiceSwitch"
                                        name="isOnlineService"
                                        label={formValues.isOnlineService ? "Sim" : "Não"}
                                        checked={formValues.isOnlineService}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={3} xl={3}>
                                <Form.Group controlId="customquote">
                                    <Form.Label className={styles.formTitle}>Orçamento Personalizado?</Form.Label>
                                    <Form.Check
                                        type="switch"
                                        id="customQuoteSwitch"
                                        name="customquote"
                                        label={formValues.customquote ? "Sim" : "Não"}
                                        checked={formValues.customquote}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={3} xl={3}>
                                <Form.Group controlId="isPublished">
                                    <Form.Label className={styles.formTitle}>Publicar?</Form.Label>
                                    <Form.Check
                                        type="switch"
                                        id="isPublishedSwitch"
                                        name="isPublished"
                                        label={formValues.isPublished ? "Publicado" : "Não Publicado"}
                                        checked={formValues.isPublished}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        {errors.flags && (
                            <Alert variant="danger">
                                {errors.flags}
                            </Alert>
                        )}
                        <Row>
                            <Col xs={12} md={6}>
                                <Form.Group controlId="duration">
                                    <Form.Label className={styles.formTitle}>Duração</Form.Label>
                                    <Form.Select
                                        className={styles.formControl}
                                        name="duration"
                                        value={formValues.duration}
                                        onChange={handleChange}
                                        isInvalid={!!errors.duration}
                                    >
                                        {timeOptions.map((time, i) => (
                                            <option key={i} value={time}>{time}</option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.duration}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                {!formValues.customquote && (
                                    <Form.Group controlId="price">
                                        <Form.Label className={styles.formTitle}>Preço</Form.Label>
                                        <NumericFormat 
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            prefix="R$ "
                                            className={styles.formControl + " form-control"}
                                            value={formValues.price}
                                            onValueChange={(values) => {
                                                const cleanedValue = values.value
                                                    .replace(/[^0-9,.]/g, '')
                                                    .replace(/(\..*)\./g, '$1');
                                                handleChange({ target: { name: 'price', value: cleanedValue } });
                                            }}
                                            decimalScale={2}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.price}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6}>
                                <Form.Group controlId="categorias">
                                    <Form.Label className={styles.formTitle}>Categorias</Form.Label>
                                    <Select 
                                        options={categoriesOptions}
                                        placeholder="Selecione as Categorias"
                                        isClearable
                                        styles={customStyles}
                                        onChange={(selectedOption) => {
                                            const selectedId = selectedOption ? selectedOption.value : null;
                                            if (selectedId && !formValues.categorias.includes(selectedId)) {
                                                if (formValues.categorias.length >= 10) {
                                                    toast.error('Você pode selecionar no máximo 10 categorias.');
                                                    return;
                                                }
                                                setFormValues((prev) => ({
                                                    ...prev,
                                                    categorias: [...prev.categorias, selectedId]
                                                }));
                                            }
                                        }}
                                    />
                                    <div className={styles.selectedDrop}>
                                        {formValues.categorias.map((categoriaId) => {
                                            const categoria = categoriesData?.categories.find(c => c.id === categoriaId);
                                            return (
                                                <Button
                                                    key={categoriaId}
                                                    onClick={() => handleCategoryRemove(categoriaId)}
                                                    className={styles.selectedDrop}
                                                >
                                                    {categoria ? categoria.name : "Categoria Desconhecida"} &times;
                                                </Button>
                                            );
                                        })}
                                    </div>
                                    {errors.categorias && (
                                        <Alert variant="danger">
                                            {errors.categorias}
                                        </Alert>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group controlId="tags">
                                    <Form.Label className={styles.formTitle}>Tags</Form.Label>
                                    <Select 
                                        options={tagsOptions}
                                        placeholder="Selecione as Tags"
                                        isClearable
                                        styles={customStyles}
                                        onChange={(selectedOption) => {
                                            const selectedId = selectedOption ? selectedOption.value : null;
                                            if (selectedId && !formValues.tags.includes(selectedId)) {
                                                if (formValues.tags.length >= 10) {
                                                    toast.error('Você pode selecionar no máximo 10 categorias.');
                                                    return;
                                                }
                                                setFormValues((prev) => ({
                                                    ...prev,
                                                    tags: [...prev.tags, selectedId]
                                                }));
                                            }
                                        }}
                                    />
                                    <div className={styles.selectedDrop}>
                                        {formValues.tags.map((tagId) => {
                                            const tag = categoriesData?.services.find(t => t.id === tagId);
                                            return (
                                                <Button
                                                    key={tagId}
                                                    onClick={() => handleTagRemove(tagId)}
                                                    className={styles.selectedDrop}
                                                >
                                                    {tag?.name} &times;
                                                </Button>
                                            );
                                        })}
                                    </div>
                                    {errors.tags && (
                                        <Alert variant="danger">
                                            {errors.tags}
                                        </Alert>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className={styles.rowButton}>
                            <Col className={styles.colButtonCancelar}>
                                <Button className={styles.buttonCancelar} onClick={handleBack}>Cancelar</Button>
                            </Col>
                            <Col className={styles.colButtonSend}>
                                <Button className={styles.button} type="submit" disabled={isLoadingButton}>
                                    {isLoadingButton ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Salvar"}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Row>
            </Container>
            <Modal show={deleteModalOpen} onHide={handleDeleteModalClose} centered className={styles.modal}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmar Exclusão</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Tem certeza de que deseja excluir este serviço?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className={styles.buttonCancel} onClick={handleDeleteModalClose}>
                        Cancelar
                    </Button>
                    <Button className={styles.buttonDelete} onClick={handleDelete}>
                        Excluir
                    </Button>
                </Modal.Footer>
            </Modal>
        </section>
    );
}

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        marginTop: '5px',
        borderColor: '#EE880C',
        boxShadow: state.isFocused ? '0 0 5px 2px #EE880C' : provided.boxShadow,
        '&:hover': {
            borderColor: '#EE880C'
        }
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        '&:hover': {
            color: '#EE880C'
        }
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#390040' : state.isFocused ? '#EE880C' : provided.backgroundColor,
        color: state.isSelected ? '#fff' : state.isFocused ? '#fff' : provided.color,
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: '#EE880C',
            color: '#fff'
        }
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#390040',
    }),
};
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { BASE_URL, AUTH_DEBUG, HttpResponse, HttpStatus, } from "../default";

const getPartnerResume = async (user_id, token) => {
    const url = `${BASE_URL}/partner/info/${user_id}`;

    const options = {
        method: 'GET',
        credentials: 'include',
        headers: {
            jwt: token,
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    };

    const response = await fetch(url, options);
    if (response.ok) {
        const data = await response.json();
        AUTH_DEBUG && console.log("PartnerAPI::getPartnerResume(): ", data);
        return data;
    } else {
        throw new Error("Error on getPartnerResume()");
    }
};

export function usePartnerResumeData(user_id, token) {
    const query = useQuery({
        queryKey: ['PartnerResume', user_id],
        queryFn: () => getPartnerResume(user_id, token),
        staleTime: 1000 * 60 * 10,
        cacheTime: 1000 * 60 * 10,
        enabled: !!token,
    });

    return {
        ...query,
        data: query.data,
    };
}

const postCreateOnePartner = async ({ formData, token }) => {
    const url = `${BASE_URL}/partner/partners/`;

    const options = {
        method: 'POST',
        credentials: 'include',
        headers: {
            jwt: token,
        },
        body: formData,
    };

    const response = await fetch(url, options);
    if (!response.ok) {
        let errorMessage = 'Erro na rede, tente novamente!';
        try {
            const errorData = await response.json();
            if (errorData && errorData.detail) {
                errorMessage = errorData.detail;
            } else if (errorData && typeof errorData === 'object') {
                errorMessage = Object.values(errorData).flat().join(', ');
            }
        } catch (e) {
        }
        throw new Error(errorMessage);
    }
    return response.json();
};

export function usePostCreateOnePartner(token) {
    const queryClient = useQueryClient(); 

    const mutate = useMutation({
        mutationFn: postCreateOnePartner,
        mutationKey: { token },
        enabled: [!!token],
        onError: (error) => {
            return error.message;
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['PartnerResume', token]);
        },
    });

    return mutate;
}

const postOnePartner = async ({ formData, partnerSlug, token }) => {
    const url = `${BASE_URL}/partner/partners/slug/${partnerSlug}/`;

    const options = {
        method: 'PATCH',
        credentials: 'include',
        headers: {
            jwt: token,
        },
        body: formData,
    };

    const response = await fetch(url, options);
    if (!response.ok) {
        let errorMessage = 'Erro na rede, tente novamente!';
        try {
            const errorData = await response.json();
            if (errorData && errorData.detail) {
                errorMessage = errorData.detail;
            } else if (errorData && typeof errorData === 'object') {
                errorMessage = Object.values(errorData).flat().join(', ');
            }
        } catch (e) {
        }
        throw new Error(errorMessage);
    }
    return response.json();
};


export function usePostOnePartner(partnerSlug) {
    const queryClient = useQueryClient(); 

    const mutate = useMutation({
        mutationFn: postOnePartner,
        mutationKey: { partnerSlug },
        enabled: [!!partnerSlug],
        onError: (error) => {
            return error.message;
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['OnePartner', partnerSlug]);
        },
    });

    return mutate;
}

const getOnePartner = async (partnerSlug, token) => {
    const url = `${BASE_URL}/partner/partners/slug/${partnerSlug}/`;

    const options = {
        method: 'GET',
        credentials: 'include',
        headers: {
            jwt: token,
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    };

    const response = await fetch(url, options);
    if (response.ok) {
        const data = await response.json();
        AUTH_DEBUG && console.log("PartnerAPI::getOnePartner(): ", data);
        return data;
    } else {
        throw new Error("Error on getOnePartner()");
    }
};

export function useOnePartnerData(partnerSlug, token) {
    const query = useQuery({
        queryKey: ['OnePartner', partnerSlug],
        queryFn: () => getOnePartner(partnerSlug, token),
        staleTime: 1000 * 60 * 5,
        cacheTime: 1000 * 60 * 5,
        enabled: !!token && !!partnerSlug,
    });

    return {
        ...query,
        data: query.data,
    };
}

const getCategoriesAndServicesPartner = async (token) => {
    const url = `${BASE_URL}/partner/categoryAndService/`;

    const options = {
        method: 'GET',
        credentials: 'include',
        headers: {
            jwt: token,
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    };

    const response = await fetch(url, options);
    if (response.ok) {
        const data = await response.json();
        AUTH_DEBUG && console.log("PartnerAPI::getCategoriesAndServicesPartner(): ", data);
        return data;
    } else {
        throw new Error("Error on getCategoriesAndServicesPartner()");
    }
};

export function useCategoriesAndServicesPartnerData(token) {
    const query = useQuery({
        queryKey: ['CategoriesAndServicesPartner'],
        queryFn: () => getCategoriesAndServicesPartner(token),
        staleTime: 1000 * 60 * 1,
        cacheTime: 1000 * 60 * 1,
        enabled: !!token,
    });

    return {
        ...query,
        categoriesData: query.data,
    };
}

const getOneCategoriesServicesPartner = async (partnerSlug, token) => {
    const url = `${BASE_URL}/partner/categoryAndService/${partnerSlug}/`;

    const options = {
        method: 'GET',
        credentials: 'include',
        headers: {
            jwt: token,
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    };

    const response = await fetch(url, options);
    if (response.ok) {
        const data = await response.json();
        AUTH_DEBUG && console.log("PartnerAPI::getOneCategoriesServicesPartner(): ", data);
        return data;
    } else {
        throw new Error("Error on getOneCategoriesServicesPartner()");
    }
};

export function useOneCategoriesServicesPartnerData(partnerSlug, token) {
    const query = useQuery({
        queryKey: ['OneCategoriesServicesPartner'],
        queryFn: () => getOneCategoriesServicesPartner(partnerSlug, token),
        staleTime: 1000 * 60 * 5,
        cacheTime: 1000 * 60 * 5,
        enabled: !!token,
    });

    return {
        ...query,
        categoriesData: query.data,
    };
}

//--------------------- SERVICES PARTNER ---------------------

const getServicesProvidedPartner = async (partnerSlug, token, page) => {
    const url = `${BASE_URL}/partner/servicesProvided/slug/${partnerSlug}/?page=${page}`;

    const options = {
        method: 'GET',
        credentials: 'include',
        headers: {
            jwt: token,
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    };

    const response = await fetch(url, options);
    if (response.ok) {
        const data = await response.json();
        AUTH_DEBUG && console.log("PartnerAPI::getServicesProvidedPartner(): ", data);
        return data;
    } else {
        throw new Error("Error on getServicesProvidedPartner()");
    }
};

export function useServicesProvidedData(partnerSlug, token, page) {
    const query = useQuery({
        queryKey: ['ServicesProvidedPartner', partnerSlug, page],
        queryFn: () => getServicesProvidedPartner(partnerSlug, token, page),
        staleTime: 1000 * 60 * 5,
        cacheTime: 1000 * 60 * 5,
        enabled: !!token,
    });

    return {
        ...query,
        data: query.data,
    };
}

const getOneServicesProvided = async (serviceSlug, token) => {
    const url = `${BASE_URL}/partner/servicesProvided/slugOne/${serviceSlug}/`;

    const options = {
        method: 'GET',
        credentials: 'include',
        headers: {
            jwt: token,
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    };

    const response = await fetch(url, options);
    if (response.ok) {
        const data = await response.json();
        AUTH_DEBUG && console.log("PartnerAPI::getOneServicesProvided(): ", data);
        return data;
    } else {
        throw new Error("Error on getOneServicesProvided()");
    }
};

export function useOneServicesProvidedData(serviceSlug, token) {
    const query = useQuery({
        queryKey: ['OneServicesProvidedPartner', serviceSlug],
        queryFn: () => getOneServicesProvided(serviceSlug, token),
        staleTime: 1000 * 60 * 30,
        cacheTime: 1000 * 60 * 30,
        enabled: !!token,
    });

    return {
        ...query,
        data: query.data,
    };
}

const postOneServicesProvided = async ({ formData, token, partnerSlug }) => {
    const url = `${BASE_URL}/partner/servicesProvided/slug/${partnerSlug}/`;

    const options = {
        method: 'POST',
        credentials: 'include',
        headers: {
            jwt: token,
        },
        body: formData,
    };

    const response = await fetch(url, options);
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.json();
};

export function usePostOneServicesProvided(partnerSlug) {
    const queryClient = useQueryClient();

    const mutate = useMutation({
        mutationFn: postOneServicesProvided,
        mutationKey: { partnerSlug },
        enabled: [!!partnerSlug],
        onSuccess: () => {
            queryClient.invalidateQueries(['ServicesProvidedPartner', partnerSlug]);
        },
    });

    return mutate;
}

const patchOneServicesProvided = async ({ formData, token, serviceSlug }) => {
    const url = `${BASE_URL}/partner/servicesProvided/slugOne/${serviceSlug}/`;

    const options = {
        method: 'PATCH',
        credentials: 'include',
        headers: {
            jwt: token,
        },
        body: formData,
    };

    const response = await fetch(url, options);
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.json();
};

export function usePatchOneServicesProvided(partnerSlug, serviceSlug) {
    const queryClient = useQueryClient();

    const mutate = useMutation({
        mutationFn: patchOneServicesProvided,
        mutationKey: { partnerSlug },
        enabled: [!!partnerSlug && !!serviceSlug],
        onSuccess: () => {
            queryClient.invalidateQueries(['ServicesProvidedPartner', 'OneServicesProvidedPartner', partnerSlug, serviceSlug]);
        },
    });

    return mutate;
}

const deleteOneServicesProvided = async ( serviceSlug, token) => {
    const url = `${BASE_URL}/partner/servicesProvided/slugOne/${serviceSlug}/`
    try {
        const options = {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                jwt: token,
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
        }

        const response = await fetch(url, options);
        if (response.ok) {
            return new HttpResponse(HttpStatus.OK, null);
        } else {
            throw new Error("Error on deleteOneServicesProvided()")
        }
    } catch (error) {
        console.warn(error)
        return new HttpResponse(HttpStatus.ERROR, null);
    }
}
//--------------------- Employee PARTNER ---------------------

const getEmployeePartner = async (partnerSlug, token, page) => {
    const url = `${BASE_URL}/partner/employeePartner/slug/${partnerSlug}/?page=${page}`;

    const options = {
        method: 'GET',
        credentials: 'include',
        headers: {
            jwt: token,
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    };

    const response = await fetch(url, options);
    if (response.ok) {
        const data = await response.json();
        AUTH_DEBUG && console.log("PartnerAPI::getEmployeePartner(): ", data);
        return data;
    } else {
        throw new Error("Error on getEmployeePartner()");
    }
};

export function useEmployeePartnerData(partnerSlug, token, page) {
    const query = useQuery({
        queryKey: ['EmployeePartner', partnerSlug, page],
        queryFn: () => getEmployeePartner(partnerSlug, token, page),
        staleTime: 1000 * 60 * 5,
        cacheTime: 1000 * 60 * 5,
        enabled: !!token,
    });

    return {
        ...query,
        data: query.data,
    };
}

const getResumeServicesProvidedPartner = async (partnerSlug, token) => {
    const url = `${BASE_URL}/partner/servicesProvided/resume/${partnerSlug}/`;

    const options = {
        method: 'GET',
        credentials: 'include',
        headers: {
            jwt: token,
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    };

    const response = await fetch(url, options);
    if (response.ok) {
        const data = await response.json();
        AUTH_DEBUG && console.log("PartnerAPI::getResumeServicesProvidedPartner(): ", data);
        return data;
    } else {
        throw new Error("Error on getResumeServicesProvidedPartner()");
    }
};

export function useResumeServicesProvidedData(partnerSlug, token) {
    const query = useQuery({
        queryKey: ['ResumeServicesProvidedPartner', partnerSlug],
        queryFn: () => getResumeServicesProvidedPartner(partnerSlug, token),
        staleTime: 1000 * 60 * 5,
        cacheTime: 1000 * 60 * 5,
        enabled: !!token,
    });

    return {
        ...query,
        servicesData: query.data,
    };
}

const postOneEmployeePartner = async ({ formData, token, partnerSlug }) => {
    const url = `${BASE_URL}/partner/employeePartner/slug/${partnerSlug}/`;

    const options = {
        method: 'POST',
        credentials: 'include',
        headers: {
            jwt: token,
        },
        body: formData,
    };

    const response = await fetch(url, options);
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.json();
};

export function usePostOneEmployeePartner(partner_id) {
    const queryClient = useQueryClient();

    const mutate = useMutation({
        mutationFn: postOneEmployeePartner,
        mutationKey: { partner_id },
        enabled: [!!partner_id],
        onSuccess: () => {
            queryClient.invalidateQueries(['EmployeePartner', partner_id]);
        },
    });

    return mutate;
}

const getOneEmployeePartner = async (employeeSlug, token) => {
    const url = `${BASE_URL}/partner/employeePartner/slugOne/${employeeSlug}/`;

    const options = {
        method: 'GET',
        credentials: 'include',
        headers: {
            jwt: token,
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    };

    const response = await fetch(url, options);
    if (response.ok) {
        const data = await response.json();
        AUTH_DEBUG && console.log("PartnerAPI::getOneServicesProvided(): ", data);
        return data;
    } else {
        throw new Error("Error on getOneServicesProvided()");
    }
};

export function useOneEmployeePartnerData(employeeSlug, token) {
    const query = useQuery({
        queryKey: ['OneEmployeePartner', employeeSlug],
        queryFn: () => getOneEmployeePartner(employeeSlug, token),
        staleTime: 1000 * 60 * 30,
        cacheTime: 1000 * 60 * 30,
        enabled: !!token,
    });

    return {
        ...query,
        data: query.data,
    };
}

const patchOneEmployeePartner = async ({ formData, token, employeeSlug }) => {
    const url = `${BASE_URL}/partner/employeePartner/slugOne/${employeeSlug}/`;

    const options = {
        method: 'PATCH',
        credentials: 'include',
        headers: {
            jwt: token,
        },
        body: formData,
    };

    const response = await fetch(url, options);
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.json();
};

export function usePatchOneEmployeePartner(employeeSlug) {
    const queryClient = useQueryClient();

    const mutate = useMutation({
        mutationFn: patchOneEmployeePartner,
        mutationKey: { employeeSlug },
        enabled: [!!employeeSlug],
        onSuccess: () => {
            queryClient.invalidateQueries(['EmployeePartner', employeeSlug]);
        },
    });

    return mutate;
}

const deleteOneEmployee = async ( employeeSlug, token) => {
    const url = `${BASE_URL}/partner/employeePartner/slugOne/${employeeSlug}/`
    try {
        const options = {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                jwt: token,
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
        }

        const response = await fetch(url, options);
        if (response.ok) {
            return new HttpResponse(HttpStatus.OK, null);
        } else {
            throw new Error("Error on deleteOneServicesProvided()")
        }
    } catch (error) {
        console.warn(error)
        return new HttpResponse(HttpStatus.ERROR, null);
    }
}

//--------------------- SCHEDULES PARTNER ---------------------

const getEmployeeListPartner = async (partnerSlug, token) => {
    const url = `${BASE_URL}/partner/schedules/listEmployee/${partnerSlug}/`;

    const options = {
        method: 'GET',
        credentials: 'include',
        headers: {
            jwt: token,
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    };

    const response = await fetch(url, options);
    if (response.ok) {
        const data = await response.json();
        AUTH_DEBUG && console.log("PartnerAPI::getEmployeeListPartner(): ", data);
        return data;
    } else {
        throw new Error("Error on getEmployeeListPartner()");
    }
};

export function useEmployeeListPartnerData(partnerSlug, token) {
    const query = useQuery({
        queryKey: ['EmployeePartnerList', partnerSlug],
        queryFn: () => getEmployeeListPartner(partnerSlug, token),
        staleTime: 1000 * 60 * 5,
        cacheTime: 1000 * 60 * 5,
        enabled: !!token,
    });

    return {
        ...query,
        data: query.data,
    };
}

const getDaysSchedulesOneEmployeePartner = async (partnerSlug, start_date, end_date, employee_id, token) => {
    const url = `${BASE_URL}/partner/schedules/list/${partnerSlug}/?start_date=${start_date}&end_date=${end_date}&employee_id=${employee_id}`;

    const options = {
        method: 'GET',
        credentials: 'include',
        headers: {
            jwt: token,
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    };

    const response = await fetch(url, options);
    if (response.ok) {
        const data = await response.json();
        AUTH_DEBUG && console.log("PartnerAPI::getDaysSchedulesOneEmployeePartner(): ", data);
        return data;
    } else {
        throw new Error("Error on getDaysSchedulesOneEmployeePartner()");
    }
};

export function useDaysSchedulesOneEmployeePartnerData(partnerSlug, start_date, end_date, employee_id, token, shouldFetchSchedules) {
    const queryEnabled = !!token && !!start_date && shouldFetchSchedules;

    const query = useQuery({
        queryKey: ['DaysSchedulesPartner', employee_id],
        queryFn: () => {
            if (queryEnabled) {
                return getDaysSchedulesOneEmployeePartner(partnerSlug, start_date, end_date, employee_id, token);
            } else {
                return Promise.resolve([]);
            }
        },
        enabled: queryEnabled,
        staleTime: 1000 * 60 * 5,
        cacheTime: 1000 * 60 * 5,
        retry: 0,
    });

    return {
        ...query,
        data: query.data,
    };
}

const getDaysSchedulesPartner = async (partnerSlug, serviceSlug, start_date, end_date, employee_id, token) => {
    const url = `${BASE_URL}/partner/schedules/partner/${partnerSlug}/service/${serviceSlug}/?start_date=${start_date}&end_date=${end_date}&employee_id=${employee_id}`;

    const options = {
        method: 'GET',
        credentials: 'include',
        headers: {
            jwt: token,
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    };

    const response = await fetch(url, options);
    if (response.ok) {
        const data = await response.json();
        AUTH_DEBUG && console.log("PartnerAPI::getDaysSchedulesPartner(): ", data);
        return data;
    } else {
        throw new Error("Error on getDaysSchedulesPartner()");
    }
};

export function useDaysSchedulesPartnerData(partnerSlug, serviceSlug, start_date, end_date, employee_id, token, shouldFetchServices) {
    const queryEnabled = !!token && !!start_date && !!serviceSlug && shouldFetchServices;

    const query = useQuery({
        queryKey: ['DaysSchedulesPartner', employee_id],
        queryFn: () => {
            if (queryEnabled) {
                return getDaysSchedulesPartner(partnerSlug, serviceSlug, start_date, end_date, employee_id, token);
            } else {
                return Promise.resolve([]);
            }
        },
        enabled: queryEnabled,
        staleTime: 1000 * 60 * 30,
        cacheTime: 1000 * 60 * 30,
        retry: 0,
    });

    return {
        ...query,
        data: query.data,
    };
}

const getClientPartner = async (partnerSlug, clientEmail, clientCPF, token) => {
    const url = `${BASE_URL}/partner/clients/getClient/?email-client=${clientEmail}&cpf-client=${clientCPF}&partnerSlug=${partnerSlug}`;

    const options = {
        method: 'GET',
        credentials: 'include',
        headers: {
            jwt: token,
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    };

    const response = await fetch(url, options);
    if (response.ok) {
        const data = await response.json();
        AUTH_DEBUG && console.log("PartnerAPI::getClientPartner(): ", data);
        return data;
    } else {
        throw new Error("Error on getClientPartner()");
    }
};

export function useClientPartnerData(partnerSlug, clientEmail, clientCPF, token, shouldFetch) {
    const query = useQuery({
        queryKey: [ partnerSlug, clientEmail],
        queryFn: () => getClientPartner(partnerSlug, clientEmail, clientCPF, token),
        enabled: shouldFetch,
        retry: 0,
    });

    return {
        ...query,
        ClientData: query.data,
    };
}

const postClientPartner = async ({ formData, partnerSlug, token }) => {
    const url = `${BASE_URL}/partner/client/slug/${partnerSlug}/`;

    const options = {
        method: 'POST',
        credentials: 'include',
        headers: {
            jwt: token,
        },
        body: formData,
    };

    const response = await fetch(url, options);
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.json();
};

export function usePostClientPartner(partnerSlug, clientEmail) {

    const mutate = useMutation({
        mutationFn: postClientPartner,
        mutationKey: { partnerSlug },
        enabled: [!!partnerSlug && !!clientEmail],
    });

    return mutate;
}

const postScheduleEmployeePartner = async ({partnerSlug, formData, token }) => {
    const url = `${BASE_URL}/partner/schedules/partner/${partnerSlug}/`;

    const options = {
        method: 'POST',
        credentials: 'include',
        headers: {
            jwt: token,
        },
        body: formData,
    };

    const response = await fetch(url, options);
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.json();
};

export function usePostScheduleEmployeePartner(partner_id, clientId) {
    const mutate = useMutation({
        mutationFn: postScheduleEmployeePartner,
        mutationKey: { partner_id },
        enabled: [!!partner_id && !!clientId],
    });

    return mutate;
}

//--------------------- CLIENT LIST ---------------------

const getClientListPartner = async (partnerSlug, token, page) => {
    const url = `${BASE_URL}/partner/client/slug/${partnerSlug}/?page=${page}`;

    const options = {
        method: 'GET',
        credentials: 'include',
        headers: {
            jwt: token,
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    };

    const response = await fetch(url, options);
    if (response.ok) {
        const data = await response.json();
        AUTH_DEBUG && console.log("PartnerAPI::getClientListPartner(): ", data);
        return data;
    } else {
        throw new Error("Error on getClientListPartner()");
    }
};

export function useClientListPartnerData(partnerSlug, token, page) {
    const query = useQuery({
        queryKey: ['ClientListPartner', partnerSlug, page],
        queryFn: () => getClientListPartner(partnerSlug, token, page),
        staleTime: 1000 * 60 * 5,
        cacheTime: 1000 * 60 * 5,
        enabled: !!token,
    });

    return {
        ...query,
        data: query.data,
    };
}

const postOneClientListPartner = async ({ formData, partnerSlug, token }) => {
    const url = `${BASE_URL}/partner/client/slug/${partnerSlug}/`;

    const options = {
        method: 'POST',
        credentials: 'include',
        headers: {
            jwt: token,
        },
        body: formData,
    };

    const response = await fetch(url, options);
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.json();
};

export function usePostOneClientListPartner(partnerSlug, clientEmail) {
    const queryClient = useQueryClient();

    const mutate = useMutation({
        mutationFn: postOneClientListPartner,
        mutationKey: { partnerSlug },
        enabled: [!!partnerSlug && !!clientEmail],
        onSuccess: () => {
            queryClient.invalidateQueries(['ClientListPartner', partnerSlug]);
        },
    });

    return mutate;
}

const getOneClientListPartner = async (clientSlug, token) => {
    const url = `${BASE_URL}/partner/client/slugOne/${clientSlug}/`;

    const options = {
        method: 'GET',
        credentials: 'include',
        headers: {
            jwt: token,
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    };

    const response = await fetch(url, options);
    if (response.ok) {
        const data = await response.json();
        AUTH_DEBUG && console.log("PartnerAPI::getOneClientListPartner(): ", data);
        return data;
    } else {
        throw new Error("Error on getOneClientListPartner()");
    }
};

export function useOneClientListPartnerData(clientSlug, token) {
    const query = useQuery({
        queryKey: ['ClientListPartner', clientSlug],
        queryFn: () => getOneClientListPartner(clientSlug, token),
        staleTime: 1000 * 60 * 5,
        cacheTime: 1000 * 60 * 5,
        enabled: !!token,
    });

    return {
        ...query,
        data: query.data,
    };
}

const patchOneClientListPartner = async ({ formData, clientSlug, token }) => {
    const url = `${BASE_URL}/partner/client/slugOne/${clientSlug}/`;

    const options = {
        method: 'PATCH',
        credentials: 'include',
        headers: {
            jwt: token,
        },
        body: formData,
    };

    const response = await fetch(url, options);
    const responseData = await response.json();

    if (!response.ok) {
        const errorDetail = responseData?.cpf?.[0] || responseData.detail || 'Erro na requisição';
        throw new Error(errorDetail);
    }

    return responseData;
};

export function usePatchOneClientListPartner(clientSlug, clientEmail) {
    const queryClient = useQueryClient();

    const mutate = useMutation({
        mutationFn: patchOneClientListPartner,
        mutationKey: { clientSlug },
        enabled: [!!clientSlug && !!clientEmail],
        onSuccess: () => {
            queryClient.invalidateQueries(['ClientListPartner', clientSlug]);
        },
    });

    return mutate;
}

const deleteOneClient = async ( clientToDelete, token) => {
    const url = `${BASE_URL}/partner/client/slugOne/${clientToDelete}/`
    try {
        const options = {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                jwt: token,
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
        }

        const response = await fetch(url, options);
        if (response.ok) {
            return new HttpResponse(HttpStatus.OK, null);
        } else {
            throw new Error("Error on deleteOneServicesProvided()")
        }
    } catch (error) {
        console.warn(error)
        return new HttpResponse(HttpStatus.ERROR, null);
    }
}

//--------------------- ORDER PARTNER ---------------------

const postOrderPaymentPartner = async ({partnerSlug, formData, token }) => {
    const url = `${BASE_URL}/payment/paymentOrder/partner/${partnerSlug}/`;

    const options = {
        method: 'POST',
        credentials: 'include',
        headers: {
            jwt: token,
        },
        body: formData,
    };

    const response = await fetch(url, options);
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.json();
};

export function usePostOrderPaymentPartner(partnerSlug, clientId) {
    const mutate = useMutation({
        mutationFn: postOrderPaymentPartner,
        mutationKey: { partnerSlug },
        enabled: [!!partnerSlug && !!clientId],
    });

    return mutate;
}

const listOrderPaymentPartner = async (partnerSlug, token, page) => {
    const url = `${BASE_URL}/payment/paymentOrder/partner/${partnerSlug}/?page=${page}`;

    const options = {
        method: 'GET',
        credentials: 'include',
        headers: {
            jwt: token,
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    };

    const response = await fetch(url, options);
    if (response.ok) {
        const data = await response.json();
        AUTH_DEBUG && console.log("PartnerAPI::listOrderPaymentPartner(): ", data);
        return data;
    } else {
        throw new Error("Error on listOrderPaymentPartner()");
    }
};

export function useListOrderPaymentPartner(partnerSlug, token, page) {
    const query = useQuery({
        queryKey: ['PartnerHistoryFinancial', partnerSlug, page],
        queryFn: () => listOrderPaymentPartner(partnerSlug, token, page),
        staleTime: 1000 * 60 * 5,
        cacheTime: 1000 * 60 * 5,
        enabled: !!token && !!partnerSlug,
    });

    return {
        ...query,
        data: query.data,
    };
}

const getOnePartnerRequestsDetails = async ( token, requestSlug ) => {
    const url = `${BASE_URL}/payment/paymentOrder/partner/details/${requestSlug}/`;

    const options = {
        method: 'GET',
        credentials: 'include',
        headers: {
            jwt: token,
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    };

    const response = await fetch(url, options);
    if (response.ok) {
        const data = await response.json();
        AUTH_DEBUG && console.log("PartnerAPI::getOnePartnerRequestsDetails(): ", data);
        return data;
    } else {
        throw new Error("Error on getOnePartnerRequestsDetails()");
    }
};

export function useOnePartnerRequestsDetailsData( token, requestSlug ) {
    const query = useQuery({
        queryKey: [ 'OneClientRequests', requestSlug ],
        queryFn: () => getOnePartnerRequestsDetails( token, requestSlug ),
        enabled: !!token && !!requestSlug,
        retry: 0,
    });

    return {
        ...query,
        ClientRequestData: query.data,
    };
}

const patchOneRequestPaymentPartner = async ({ requestSlug, token }) => {
    const url = `${BASE_URL}/payment/paymentOrder/partner/details/${requestSlug}/`;

    const options = {
        method: 'PATCH',
        credentials: 'include',
        headers: {
            jwt: token,
        },
    };

    const response = await fetch(url, options);
    const responseData = await response.json();

    if (!response.ok) {
        const errorDetail = responseData?.cpf?.[0] || responseData.detail || 'Erro na requisição';
        throw new Error(errorDetail);
    }

    return responseData;
};

export function usePatchOneRequestPaymentPartner(requestSlug, token) {
    const queryClient = useQueryClient();

    const mutate = useMutation({
        mutationFn: patchOneRequestPaymentPartner,
        mutationKey: { requestSlug },
        enabled: [!!requestSlug && !!token],
        onSuccess: () => {
            queryClient.invalidateQueries([ 'OneClientRequests', requestSlug ]);
        },
    });

    return mutate;
}

const getPaymentReportPartner = async (partnerSlug, token, year, month) => {
    const url = `${BASE_URL}/payment/paymentReport/${partnerSlug}/?year=${year}&month=${month}`;

    const options = {
        method: 'GET',
        credentials: 'include',
        headers: {
            jwt: token,
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    };

    const response = await fetch(url, options);
    if (response.ok) {
        const data = await response.json();
        AUTH_DEBUG && console.log("PartnerAPI::getServicesProvidedPartner(): ", data);
        return data;
    } else {
        throw new Error("Error on getServicesProvidedPartner()");
    }
};

export function usePaymentReportPartnerData(partnerSlug, token, year, month) {
    const query = useQuery({
        queryKey: ['PaymentReportPartner', partnerSlug, year, month],
        queryFn: () => getPaymentReportPartner(partnerSlug, token, year, month),
        staleTime: 1000 * 60 * 5,
        cacheTime: 1000 * 60 * 5,
        enabled: !!token && !!year && !!month,
    });

    return {
        ...query,
        data: query.data,
    };
}

const getPaymentReportDetailPartner = async (token, requestSlug) => {
    const url = `${BASE_URL}/payment/paymentReport/details/${requestSlug}/`;

    const options = {
        method: 'GET',
        credentials: 'include',
        headers: {
            jwt: token,
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    };

    const response = await fetch(url, options);
    if (response.ok) {
        const data = await response.json();
        AUTH_DEBUG && console.log("PartnerAPI::getServicesProvidedPartner(): ", data);
        return data;
    } else {
        throw new Error("Error on getServicesProvidedPartner()");
    }
};

export function usePaymentReportDetailPartnerPartnerData(token, requestSlug) {
    const query = useQuery({
        queryKey: ['PaymentReportPartnerDetail', requestSlug],
        queryFn: () => getPaymentReportDetailPartner(token, requestSlug),
        staleTime: 1000 * 60 * 5,
        cacheTime: 1000 * 60 * 5,
        enabled: !!token && !!requestSlug,
    });

    return {
        ...query,
        data: query.data,
    };
}

export const PartnerAPI = {
    deleteOneServicesProvided,
    deleteOneEmployee,
    deleteOneClient,
}

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Container, Row, Col, Form, Button, Image, Modal, Spinner, Alert } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styles from './style.module.scss';
import Select from 'react-select';
import { BlogAPI } from '../../../api/blog';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useAuthContext } from '../../../contexts/authContext';
import { useListAuhtorGuruData, useListCategoriesGuruData } from '../../../api/blogAPI/blogGuru';
import { toast } from 'react-toastify';

export default function BlogEditPosts() {
    const { token } = useAuthContext();
    const { id } = useParams();
    const navigate = useNavigate();
    const [isLoadingButton, setIsLoadingButton] = useState(false);
    const [title, setTitle] = useState('');
    const [resume, setResume] = useState('');
    const [bodyPost, setBodyPost] = useState('');
    const [banner, setBanner] = useState(null); 
    const [authors, setAuthors] = useState([]);
    const [categories, setCategories] = useState([]);
    const [isPublished, setIsPublished] = useState(false);
    const [allCategories, setAllCategories] = useState([]);
    const [allAuthors, setAllAuthors] = useState([]);
    const [errors, setErrors] = useState({});

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const [formValues, setFormValues] = useState({
        categorias: [],
        authors: [],
    });

    const validateForm = () => {
        const newErrors = {};
        
        if (!title) newErrors.title = 'O título é obrigatório';
        if (!resume) newErrors.resume = 'O resumo é obrigatório';
        if (!bodyPost) newErrors.bodyPost = 'O texto é obrigatório';

        if (!formValues.categorias) newErrors.categorias = 'O campo categoria é obrigatório!';
        if (formValues.categorias.length === 0) newErrors.categorias = 'O campo categoria é obrigatório!';

        if (!formValues.authors) newErrors.author = 'O campo categoria é obrigatório!';
        if (formValues.authors.length === 0) newErrors.author = 'O campo categoria é obrigatório!';
    
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!validateForm()) {
            return;
        }
        setIsLoadingButton(true);

        const formData = new FormData();
        formData.append('title', title);
        formData.append('resume', resume);
        formData.append('bodyPost', bodyPost);
        if (banner && banner instanceof File) {
            formData.append('banner', banner);
        }
        formValues.authors.forEach(autor => formData.append('authors', autor));
        formValues.categorias.forEach(autor => formData.append('categories', autor));
        formData.append('is_published', isPublished);

        try {
            const response = await BlogAPI.editPost(id, formData, token);
            if (response.status === 200) {
                setTitle('');
                setResume('');
                setBodyPost('');
                setBanner(null);
                setAuthors([]);
                setCategories([]);
                setIsPublished(false);
                setIsLoadingButton(false);
                navigate('/posts/');
            } else {
                setIsLoadingButton(false);
                console.error('Erro ao editar post:', response);
            }
        } catch (error) {
            setIsLoadingButton(false);
            console.error('Erro ao editar post:', error.message);
        }
    };

    const getPostData = useCallback(async () => {
        const responsePost = await BlogAPI.getOnePost(id, token);
        if(responsePost.status === 200) {
            setTitle(responsePost.data.title);
            setResume(responsePost.data.resume);
            setBodyPost(responsePost.data.bodyPost);
            setBanner(responsePost.data.banner);
            setIsPublished(responsePost.data.is_published)

            const initialValues = {
                categorias: responsePost.data.categories,
                authors: responsePost.data.authors,
            };
            setFormValues(initialValues);

        } else {
            navigate('/posts/');
        }
    }, [id, token, navigate]);

    const handleDelete = async () => {
        try {
            const response = await BlogAPI.deletePost(id, token);
            if (response.status === 200) {
                navigate('/posts/');
            } else {
                console.error('Erro ao deletar post:', response);
            }
        } catch (error) {
            console.error('Erro ao deletar post:', error.message);
        }
    };

    const { categoriesData, isLoading, isError, refetch } = useListCategoriesGuruData(token);
    const { authorsData, isLoading: isLoadingAuthor, isError: isErrorAuthor } = useListAuhtorGuruData(token);

    const categoriesOptions = useMemo(() => 
        categoriesData?.map(state => ({
            value: state.id, 
            label: state.name
        })) || [], 
        [categoriesData]
    );

    const authorsOptions = useMemo(() => 
        authorsData?.map(state => ({
            value: state.id, 
            label: state.name
        })) || [], 
        [authorsData]
    );

    const handleCategoryRemove = (id) => {
        setFormValues((prev) => ({
            ...prev,
            categorias: prev.categorias.filter(categoriaId => categoriaId !== id)
        }));
    };

    const handleAuthorRemove = (id) => {
        setFormValues((prev) => ({
            ...prev,
            authors: prev.authors.filter(autorId => autorId !== id)
        }));
    };

    const getCategoriesData = async () => {
        const responseCategories = await BlogAPI.getListCategories();
        if (responseCategories.status === 200) {
            setAllCategories(responseCategories.data);
        }
    };

    const getAuthorsData = async () => {
        const responseAuthors = await BlogAPI.getListAuhtor();
        if (responseAuthors.status === 200) {
            setAllAuthors(responseAuthors.data);
        }
    };

    useEffect(() => {
        getPostData();
        getCategoriesData();
        getAuthorsData();
    }, [getPostData]);

    const handleDeleteModalOpen = () => {
        setDeleteModalOpen(true);
    };

    const handleDeleteModalClose = () => {
        setDeleteModalOpen(false);
    };

    const handleBack = () => {
        navigate('/posts/');
    };

    return (
        <HelmetProvider>
            <section id={styles.BlogPostsPage}>
                <Helmet>
                    <title>Editar Post - Blog</title>
                </Helmet>
                <Container>
                    <Row>
                        <Col>
                            <p className={styles.title}>Editar Post</p>
                        </Col>
                        <Col className={styles.colButton}>
                            <Button className={styles.buttonPost} onClick={handleDeleteModalOpen}>
                                Excluir
                            </Button>
                        </Col>
                    </Row>
                    <Row className={styles.rowPost}>
                        <Form onSubmit={handleSubmit} className={styles.form} encType='multipart/form-data'>
                            <Row>
                                <Col xs={12} md={6} className={styles.rowBanner}>
                                    {banner && (
                                        <Image
                                            src={banner instanceof Blob ? URL.createObjectURL(banner) : banner}
                                            className={styles.banner}
                                        />
                                    )}
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Group controlId="banner">
                                        <Form.Label className={styles.formTitle}>Banner - (1280x720px)</Form.Label>
                                        <Form.Control
                                            className={styles.formControl}
                                            type="file"
                                            onChange={(e) => setBanner(e.target.files[0])}
                                            accept=".jpg,.jpeg,.png,.webp"
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Form.Group controlId="title">
                                <Form.Label className={styles.formTitle}>Título</Form.Label>
                                <Form.Control
                                    className={styles.formControl}
                                    type="text"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    maxLength={255}
                                    isInvalid={!!errors.title}
                                />
                                <Form.Control.Feedback className={styles.formControl} type="invalid">
                                    {errors.title}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="resume">
                                <Form.Label className={styles.formTitle}>Resumo</Form.Label>
                                <Form.Control
                                    className={styles.formControl}
                                    as="textarea"
                                    value={resume}
                                    onChange={(e) => setResume(e.target.value)}
                                    maxLength={255}
                                    isInvalid={!!errors.resume}
                                />
                                <Form.Control.Feedback className={styles.formControl} type="invalid">
                                    {errors.resume}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="body">
                                <Form.Label className={styles.formTitle}>Texto</Form.Label>
                                <ReactQuill
                                    value={bodyPost}
                                    onChange={setBodyPost}
                                    modules={{
                                        toolbar: [
                                            [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
                                            [{size: []}],
                                            [{ 'align': [] }, 'bold', 'italic', 'underline', 'strike', 'blockquote',],
                                            [{'list': 'ordered'}, {'list': 'bullet'}, 
                                            {'indent': '-1'}, {'indent': '+1'}],
                                            ['link', 'image', 'video'],
                                            ['clean']
                                        ],
                                    }}
                                    formats={[
                                        'header', 'font', 'size',
                                        'align', 'bold', 'italic', 'underline', 'strike', 'blockquote',
                                        'list', 'bullet', 'indent',
                                        'link', 'image', 'video'
                                    ]}
                                    isInvalid={!!errors.bodyPost}
                                />
                                <div className="invalid-feedback d-block">
                                    {errors.bodyPost}
                                </div>
                            </Form.Group>
                            
                            <Row>
                                <Col xs={12} md={6}>
                                <Form.Group controlId="authors">
                                        <Form.Label className={styles.formTitle}>Autores</Form.Label>
                                        <Select
                                            options={authorsOptions}
                                            placeholder="Selecione os Autores"
                                            styles={customStyles}
                                            onChange={(selectedOption) => {
                                                const selectedId = selectedOption ? selectedOption.value : null;
                                                if (selectedId && !formValues.authors.includes(selectedId)) {
                                                    if (formValues.authors.length >= 10) {
                                                        toast.error('Você pode selecionar no máximo 10 categorias.');
                                                        return;
                                                    }
                                                    setFormValues((prev) => ({
                                                        ...prev,
                                                        authors: [...prev.authors, selectedId]
                                                    }));
                                                }
                                            }}
                                        />
                                        <div className={styles.selectedDrop}>
                                            {formValues.authors.map((authorId) => {
                                                const author = authorsData?.find(c => c.id === authorId);
                                                return (
                                                    <Button
                                                        key={authorId}
                                                        onClick={() => handleAuthorRemove(authorId)}
                                                        className={styles.selectedDrop}
                                                    >
                                                        {author ? author.name : "Autor Desconhecido"} &times;
                                                    </Button>
                                                );
                                            })}
                                        </div>
                                        {errors.author && (
                                            <Alert variant="danger">
                                                {errors.author}
                                            </Alert>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Group controlId="categories">
                                        <Form.Label className={styles.formTitle}>Categorias</Form.Label>
                                        <Select
                                            options={categoriesOptions}
                                            placeholder="Selecione as Categorias"
                                            styles={customStyles}
                                            onChange={(selectedOption) => {
                                                const selectedId = selectedOption ? selectedOption.value : null;
                                                if (selectedId && !formValues.categorias.includes(selectedId)) {
                                                    if (formValues.categorias.length >= 10) {
                                                        toast.error('Você pode selecionar no máximo 10 categorias.');
                                                        return;
                                                    }
                                                    setFormValues((prev) => ({
                                                        ...prev,
                                                        categorias: [...prev.categorias, selectedId]
                                                    }));
                                                }
                                            }}
                                        />
                                        <div className={styles.selectedDrop}>
                                            {formValues.categorias.map((categoriaId) => {
                                                const categoria = categoriesData?.find(c => c.id === categoriaId);
                                                return (
                                                    <Button
                                                        key={categoriaId}
                                                        onClick={() => handleCategoryRemove(categoriaId)}
                                                        className={styles.selectedDrop}
                                                    >
                                                        {categoria ? categoria.name : "Categoria Desconhecida"} &times;
                                                    </Button>
                                                );
                                            })}
                                        </div>
                                        {errors.categorias && (
                                            <Alert variant="danger">
                                                {errors.categorias}
                                            </Alert>
                                        )}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Form.Group controlId="isPublished">
                                <Form.Label className={styles.formTitle}>Publicar</Form.Label>
                                <Form.Check
                                    type="switch"
                                    id="isPublishedSwitch"
                                    label={isPublished ? "Finalizado" : "Em andamento"}
                                    checked={isPublished}
                                    onChange={(e) => setIsPublished(e.target.checked)}
                                />
                            </Form.Group>
                            <Row className={styles.rowButton}>
                                <Button className={styles.buttonCancelar} onClick={handleBack}>Cancelar</Button>
                                <Button className={styles.button} type="submit" disabled={isLoadingButton}>
                                    {isLoadingButton ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Salvar"}
                                </Button>
                            </Row>
                        </Form>
                    </Row>
                </Container>
                <Modal show={deleteModalOpen} onHide={handleDeleteModalClose} centered className={styles.modal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmar Exclusão</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Tem certeza de que deseja excluir este post?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className={styles.buttonCancel} onClick={handleDeleteModalClose}>
                            Cancelar
                        </Button>
                        <Button className={styles.buttonDelete} onClick={handleDelete}>
                            Excluir
                        </Button>
                    </Modal.Footer>
                </Modal>
            </section>
        </HelmetProvider>
    );
}

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        // marginTop: '5px',
        borderColor: '#EE880C',
        boxShadow: state.isFocused ? '0 0 5px 2px #EE880C' : provided.boxShadow,
        '&:hover': {
            borderColor: '#EE880C'
        }
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        '&:hover': {
            color: '#EE880C'
        }
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#390040' : state.isFocused ? '#EE880C' : provided.backgroundColor,
        color: state.isSelected ? '#fff' : state.isFocused ? '#fff' : provided.color,
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: '#EE880C',
            color: '#fff'
        }
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#390040',
    }),
};
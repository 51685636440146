import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import styles from "./style.module.scss"
import { Helmet, HelmetProvider } from 'react-helmet-async';
// import SectionSearchHome from '../../sections/sectionSearchHome';
// import { AiOutlineExclamationCircle } from "react-icons/ai";
import AImage from "../../assets/about.jpeg"

export default function AboutPage() {

  return (
    <HelmetProvider>
        <section id={styles.AboutPage}>
            <Helmet>
                <title>About us - GoinGuru</title>
                <meta name="description" content="GoinGuru" />
            </Helmet>
            <div className={styles.rowCard}>
                <Container>
                    <Row>
                        <Col className={styles.colTitle}>
                            <p className={styles.title}>Sobre nós <br/> e como <br/>Transformamos Vidas</p>
                        </Col>
                        <Col>
                            <Image src={AImage} className={styles.image}/>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container> 
                <Row className={styles.rowInfo}>
                    <p className={styles.Ititle}>Sobre Nós</p>
                    <p className={styles.Itext}>Bem-vindo à Goinguru, onde conectamos talentos e necessidades, transformando vidas e promovendo a economia local. Somos uma plataforma inovadora que facilita a interação segura e eficiente entre clientes e prestadores de serviços, oferecendo uma experiência única e confiável.</p>
                </Row>
                
                <Row className={styles.rowInfo}>
                    <p className={styles.Isubtitle}>Nossa História</p>
                    <p className={styles.Itext}>A Goinguru nasceu da combinação das palavras "go" e "guru", simbolizando movimento, ação e expertise. Inspirados pelo canguru, nosso mascote, incorporamos valores como força, segurança, agilidade, liberdade, e adaptabilidade. Assim, nossa missão é clara: transformar vidas de profissionais e empresas a avançarem e terem sucesso, trazendo visibilidade e segurança.</p>
                </Row>

                <Row className={styles.rowInfo}>
                    <p className={styles.Isubtitle}>Nossa Missão</p>
                    <p className={styles.Itext}>Transformar vidas ao conectar clientes e prestadores de serviço de forma segura, promovendo a economia local e expandindo oportunidades de trabalho. Acreditamos que, ao facilitar essas conexões, podemos criar uma comunidade mais forte e colaborativa, onde todos têm a chance de prosperar.</p>
                </Row>

                <Row className={styles.rowInfo}>
                    <p className={styles.Isubtitle}>Nossos Valores</p>
                    <p className={styles.Itext}>Na Goinguru, nossos valores são a base de todas as nossas operações e decisões. Eles refletem nosso compromisso com a comunidade, os clientes e os prestadores de serviço:</p>
                    <li className={styles.Isubtext}>Transparência: Comprometidos com a construção de uma plataforma segura e confiável, onde a interação ocorre com integridade.</li>
                    <li className={styles.Isubtext}>Inovação e Tecnologia: Proporcionar uma experiência eficiente e intuitiva, promovendo a inovação contínua.</li>
                    <li className={styles.Isubtext}>Colaboração: Fomentamos uma comunidade colaborativa, onde feedbacks e experiências são compartilhados para melhorar constantemente nossos serviços.</li>
                    <li className={styles.Isubtext}>Transcendência de Fronteiras: Exploramos oportunidades para conectar clientes e prestadores de serviço de diferentes regiões, criando uma comunidade de oportunidades de trabalho.</li>
                </Row>

                <Row className={styles.rowInfo}>
                    <p className={styles.Isubtitle}>O Que Oferecemos</p>
                    <p className={styles.Itext}>A Goinguru é mais do que uma plataforma de serviços; é um ecossistema que conecta clientes e fornecedores de diversas categorias em um único lugar, garantindo segurança e eficiência através de um sistema de pagamento interno.</p>
                    <p className={styles.Itext}>Nosso diferencial inclui:</p>
                    <li className={styles.Isubtext}>Experiência do Prestador: Prestadores podem compartilhar fotos, avaliações e fatos que comprovem a eficiência de seus serviços.</li>
                    <li className={styles.Isubtext}>Oportunidades Locais: Serviços encontrados na sua cidade, promovendo uma conexão mais rápida e fácil.</li>
                    <li className={styles.Isubtext}>Expansão para serviços online: Profissionais que realizam atendimento online possuem um sistema de busca dedicado, expandindo as possibilidades para todo o Brasil.</li>
                    <li className={styles.Isubtext}>Verificação de Antecedentes: Prestadores de serviço com o perfil verificado passam por uma revisão do AAC (Atestado de Antecedentes Criminais).</li>
                </Row>
                
                <Row className={styles.rowInfo}>
                    <p className={styles.Isubtitle}>Nossa Visão</p>
                    <p className={styles.Itext}>Nossa visão é criar uma plataforma que reflete nossos valores e missão, proporcionando uma experiência que vai além das expectativas. Queremos ser reconhecidos como líderes na conexão de talentos e necessidades, promovendo um ambiente seguro, inclusivo e inovador.</p>
                </Row>

                <Row className={styles.rowInfo}>
                    <p  className={styles.Isubtitle}>Junte-se a Nós</p>
                    <p className={styles.Itext}>Na Goinguru, acreditamos que cada conexão é uma oportunidade de transformar vidas. Junte-se a nós e faça parte de uma comunidade que valoriza a transparência, o empoderamento, o respeito, a inovação, a colaboração e a transcendência de fronteiras. Vamos juntos explorar novas possibilidades e alcançar novos horizontes.</p>
                </Row>

                <Row className={styles.rowInfo}>
                    <p className={styles.Atext}>Goinguru - Conectando Negócios, Transformando Vidas.</p>
                </Row>
            </Container>   
        </section>
    </HelmetProvider>
  );
}

